import React, { useState } from 'react'
import { Row, Col, Select } from 'antd'
//import CreateGroupModal from '../CreateGroup/CreateGroupModal'
import { Group, LocalizedString } from '@maphubs/mhtypes'

type Props = {
  groups: Array<Group>
  defaultValue?: string
  value?: string
  onChange?: (group_id: string) => void
  canChangeGroup: boolean
  editing?: boolean
  t: (val: LocalizedString | string) => string
}

const SelectGroup = ({
  value,
  defaultValue,
  onChange,
  groups,
  canChangeGroup,
  t
}: Props): JSX.Element => {
  const [createdGroup, setCreatedGroup] = useState<Group>()

  const getSelectedGroup = (group_id: string): Group => {
    let selected
    for (const group of groups) {
      if (group.group_id === group_id) {
        selected = group
      }
    }
    return selected
  }

  let startEmpty = true
  let selectedGroup

  if (defaultValue) {
    startEmpty = false
    selectedGroup = getSelectedGroup(defaultValue)
  }

  const groupOptions = groups.map((group) => {
    return {
      value: group.group_id,
      label: t(group.name)
    }
  })
  return (
    <>
      {canChangeGroup && !createdGroup && (
        <div>
          <Select
            id='group-select'
            value={value}
            defaultValue={defaultValue}
            placeholder={t('Choose a Group')}
            options={groupOptions}
            allowClear
            onChange={onChange}
            style={{ width: '100%' }}
          />
          <Row
            style={{
              padding: '5px',
              marginTop: '10px'
            }}
          >
            {/*
            <Col
              span={8}
              style={{
                padding: '5px'
              }}
            >
              <CreateGroupModal
                t={t}
                onCreate={(createdGroup) => {
                  setCreatedGroup(createdGroup)
                  groupChange(createdGroup.group_id)
                }}
              />
            </Col>
              */}
          </Row>
        </div>
      )}
      {canChangeGroup && createdGroup && (
        <p>
          <b>{t('Created Group:')} </b>
          {t(createdGroup.name)}
        </p>
      )}
      {!canChangeGroup && selectedGroup && (
        <p>
          <b>{t('Group')} </b>
          {t(selectedGroup.name)}
        </p>
      )}
    </>
  )
}
SelectGroup.defaultProps = {
  canChangeGroup: true
}
export default SelectGroup
