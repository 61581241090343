import Map from './Map/Map'
import MiniLegend from './Map/MiniLegend'
import InteractiveMap from './Map/InteractiveMap'
import Styles from './Map/Styles'
import Sources from './Map/Sources'
import MapToolButton from './Map/MapToolButton'
import MapMaker from './MapMaker/MapMakerWrapper'
import GetNameField from './Map/Styles/get-name-field'
import DataCollectionForm from './DataCollection/DataCollectionForm'
import { MapContext, MapProvider } from './MapContext'
import OpacityChooser from './LayerDesigner/OpacityChooser'
import LayerDesigner from './LayerDesigner/LayerDesigner'
import DataGrid from './DataGrid/DataGridWrapper'
import ScalePositionControl from './Map/DualScaleControl'
import getScreenshot from './MapMaker/util/get-map-screenshot'

import mapSlice from './redux/reducers/mapSlice'
import baseMapSlice from './redux/reducers/baseMapSlice'
import dataEditorSlice from './redux/reducers/dataEditorSlice'
import mapMakerSlice from './redux/reducers/mapMakerSlice'
import localeSlice from './redux/reducers/localeSlice'

export {
  Map,
  InteractiveMap,
  MiniLegend,
  MapContext,
  MapProvider,
  Styles,
  Sources,
  MapToolButton,
  MapMaker,
  DataCollectionForm,
  GetNameField,
  OpacityChooser,
  LayerDesigner,
  DataGrid,
  ScalePositionControl,
  getScreenshot,
  mapSlice,
  baseMapSlice,
  dataEditorSlice,
  mapMakerSlice,
  localeSlice
}
export default Map

import type mapboxgl from 'mapbox-gl'
export type { mapboxgl }
