import {
  TypedUseSelectorHook,
  useDispatch as defaultUseDispatch,
  useSelector as defaultUseSelector
} from 'react-redux'
import type { AppDispatch, RootState } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch
export const useDispatch: DispatchFunc = defaultUseDispatch

export const useSelector: TypedUseSelectorHook<RootState> = defaultUseSelector
