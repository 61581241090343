import React from 'react'
import { Row, Input, InputNumber, Form, Select, Checkbox } from 'antd'
import { LocalizedString, MapHubsField } from '@maphubs/mhtypes'
type Props = {
  preset: MapHubsField
  t: (v: string | LocalizedString) => string
}
const FormField = ({ preset, t }: Props): JSX.Element => {
  let field = (
    <Form.Item
      name={preset.tag}
      label={t(preset.label)}
      rules={[{ required: preset.isRequired }]}
      style={{ width: '100%' }}
    >
      <Input />
    </Form.Item>
  )

  switch (preset.type) {
    case 'number': {
      field = (
        <Form.Item
          name={preset.tag}
          label={t(preset.label)}
          rules={[{ required: preset.isRequired }]}
        >
          <InputNumber />
        </Form.Item>
      )

      break
    }
    case 'radio':
    case 'combo': {
      const options = []

      if (preset.options) {
        for (let option of preset.options.split(',')) {
          option = option.trim()
          options.push({
            value: option,
            label: option
          })
        }
      }

      field = (
        <Form.Item
          name={preset.tag}
          label={t(preset.label)}
          rules={[{ required: preset.isRequired }]}
        >
          <Select options={options} />
        </Form.Item>
      )

      break
    }
    case 'check': {
      field = (
        <Form.Item
          name={preset.tag}
          label={t(preset.label)}
          rules={[{ required: preset.isRequired }]}
        >
          <Checkbox />
        </Form.Item>
      )

      break
    }
    // No default
  }

  return field
}
export default FormField
