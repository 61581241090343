import React from 'react'
import { Tabs } from 'antd'
import { SketchPicker, SwatchesPicker } from 'react-color'
import { LocalizedString } from '@maphubs/mhtypes'

type ColorValue = {
  hex: string
  rgb: {
    r: number
    g: number
    b: number
    a: number
  }
}

const getRgbaFromHex = (color: string): string => {
  // convert hex to rgb
  const hex = color.replace('#', '')
  const r = Number.parseInt(hex.slice(0, 2), 16)
  const g = Number.parseInt(hex.slice(2, 4), 16)
  const b = Number.parseInt(hex.slice(4, 6), 16)
  const a = 0.8
  return `rgba(${r},${g},${b},${a})`
}

export default function ColorTabs({
  color,
  colorChange,
  t
}: {
  color: string
  colorChange: (color: string) => void
  t: (str: LocalizedString | string) => string
}): JSX.Element {
  const onColorPickerChange = (colorValue: ColorValue): void => {
    const color = `rgba(${colorValue.rgb.r},${colorValue.rgb.g},${colorValue.rgb.b},${colorValue.rgb.a})`
    colorChange(color)
  }

  return (
    <Tabs
      defaultActiveKey='palettes'
      type='card'
      animated={false}
      style={{
        height: '100%'
      }}
      items={[
        {
          key: 'palettes',
          label: t('Palettes'),
          children: (
            <div
              style={{
                height: 'calc(100% - 50px)',
                overflowY: 'auto'
              }}
            >
              <SwatchesPicker
                width='100%'
                height='100%'
                onChange={onColorPickerChange}
                colors={[
                  [
                    getRgbaFromHex('#ffbe0b'),
                    getRgbaFromHex('#fb5607'),
                    getRgbaFromHex('#ff006e'),
                    getRgbaFromHex('#8338ec'),
                    getRgbaFromHex('#3a86ff')
                  ],
                  [
                    getRgbaFromHex('#606c38'),
                    getRgbaFromHex('#283618'),
                    getRgbaFromHex('#fefae0'),
                    getRgbaFromHex('#dda15e'),
                    getRgbaFromHex('#bc6c25')
                  ],
                  [
                    getRgbaFromHex('#132a13'),
                    getRgbaFromHex('#31572c'),
                    getRgbaFromHex('#4f772d'),
                    getRgbaFromHex('#90a955'),
                    getRgbaFromHex('#ecf39e')
                  ],
                  [
                    getRgbaFromHex('#70d6ff'),
                    getRgbaFromHex('#ff70a6'),
                    getRgbaFromHex('#ff9770'),
                    getRgbaFromHex('#ffd670'),
                    getRgbaFromHex('#e9ff70')
                  ],
                  [
                    getRgbaFromHex('#a41623'),
                    getRgbaFromHex('#f85e00'),
                    getRgbaFromHex('#ffb563'),
                    getRgbaFromHex('#ffd29d'),
                    getRgbaFromHex('#918450')
                  ],
                  [
                    getRgbaFromHex('#fb6107'),
                    getRgbaFromHex('#f3de2c'),
                    getRgbaFromHex('#7cb518'),
                    getRgbaFromHex('#5c8001'),
                    getRgbaFromHex('#fbb02d')
                  ],
                  [
                    getRgbaFromHex('#6f2dbd'),
                    getRgbaFromHex('#a663cc'),
                    getRgbaFromHex('#b298dc'),
                    getRgbaFromHex('#b8d0eb'),
                    getRgbaFromHex('#b9faf8')
                  ],
                  [
                    getRgbaFromHex('#2d3142'),
                    getRgbaFromHex('#bfc0c0'),
                    getRgbaFromHex('#ffffff'),
                    getRgbaFromHex('#ef8354'),
                    getRgbaFromHex('#4f5d75')
                  ],
                  [
                    getRgbaFromHex('#f6511d'),
                    getRgbaFromHex('#ffb400'),
                    getRgbaFromHex('#00a6ed'),
                    getRgbaFromHex('#7fb800'),
                    getRgbaFromHex('#0d2c54')
                  ],
                  [
                    getRgbaFromHex('#af4d98'),
                    getRgbaFromHex('#d66ba0'),
                    getRgbaFromHex('#e5a9a9'),
                    getRgbaFromHex('#f4e4ba'),
                    getRgbaFromHex('#9df7e5')
                  ],
                  [
                    getRgbaFromHex('#fd0363'),
                    getRgbaFromHex('#cc095d'),
                    getRgbaFromHex('#9c1057'),
                    getRgbaFromHex('#6b1650'),
                    getRgbaFromHex('#3b1d4a')
                  ],
                  [
                    getRgbaFromHex('#ede0d4'),
                    getRgbaFromHex('#e6ccb2'),
                    getRgbaFromHex('#ddb892'),
                    getRgbaFromHex('#b08968'),
                    getRgbaFromHex('#7f5539'),
                    getRgbaFromHex('#9c6644')
                  ],
                  //green ramp
                  [
                    getRgbaFromHex('#004b23'),
                    getRgbaFromHex('#006400'),
                    getRgbaFromHex('#007200'),
                    getRgbaFromHex('#008000'),
                    getRgbaFromHex('#38b000'),
                    getRgbaFromHex('#70e000'),
                    getRgbaFromHex('#9ef01a'),
                    getRgbaFromHex('#ccff33')
                  ],
                  //red ramp
                  [
                    getRgbaFromHex('#641220'),
                    getRgbaFromHex('#6e1423'),
                    getRgbaFromHex('#85182a'),
                    getRgbaFromHex('#a11d33'),
                    getRgbaFromHex('#a71e34'),
                    getRgbaFromHex('#b21e35'),
                    getRgbaFromHex('#bd1f36'),
                    getRgbaFromHex('#c71f37'),
                    getRgbaFromHex('#da1e37'),
                    getRgbaFromHex('#e01e37')
                  ],
                  // yellow ramp
                  [
                    getRgbaFromHex('#ff7b00'),
                    getRgbaFromHex('#ff8800'),
                    getRgbaFromHex('#ff9500'),
                    getRgbaFromHex('#ffa200'),
                    getRgbaFromHex('#ffaa00'),
                    getRgbaFromHex('#ffb700'),
                    getRgbaFromHex('#ffc300'),
                    getRgbaFromHex('#ffd000'),
                    getRgbaFromHex('#ffdd00'),
                    getRgbaFromHex('#ffea00')
                  ],
                  // bright rainbow
                  [
                    getRgbaFromHex('#ff0000'),
                    getRgbaFromHex('#ff8700'),
                    getRgbaFromHex('#ffd300'),
                    getRgbaFromHex('#deff0a'),
                    getRgbaFromHex('#a1ff0a'),
                    getRgbaFromHex('#0aff99'),
                    getRgbaFromHex('#0aefff'),
                    getRgbaFromHex('#147df5'),
                    getRgbaFromHex('#580aff'),
                    getRgbaFromHex('#be0aff')
                  ],
                  [
                    getRgbaFromHex('#7400b8'),
                    getRgbaFromHex('#6930c3'),
                    getRgbaFromHex('#5e60ce'),
                    getRgbaFromHex('#5390d9'),
                    getRgbaFromHex('#4ea8de'),
                    getRgbaFromHex('#48bfe3'),
                    getRgbaFromHex('#56cfe1'),
                    getRgbaFromHex('#64dfdf'),
                    getRgbaFromHex('#72efdd'),
                    getRgbaFromHex('#80ffdb')
                  ],
                  [
                    getRgbaFromHex('#ff61ab'),
                    getRgbaFromHex('#ff6176'),
                    getRgbaFromHex('#ff8161'),
                    getRgbaFromHex('#ffb561'),
                    getRgbaFromHex('#ffea62'),
                    getRgbaFromHex('#dfff61'),
                    getRgbaFromHex('#abff61'),
                    getRgbaFromHex('#76ff61'),
                    getRgbaFromHex('#61ff81'),
                    getRgbaFromHex('#61ffb5')
                  ],
                  [
                    getRgbaFromHex('#310055'),
                    getRgbaFromHex('#3c0663'),
                    getRgbaFromHex('#4a0a77'),
                    getRgbaFromHex('#5a108f'),
                    getRgbaFromHex('#6818a5'),
                    getRgbaFromHex('#8b2fc9'),
                    getRgbaFromHex('#ab51e3'),
                    getRgbaFromHex('#bd68ee'),
                    getRgbaFromHex('#d283ff'),
                    getRgbaFromHex('#dc97ff')
                  ],
                  [
                    getRgbaFromHex('#fec5bb'),
                    getRgbaFromHex('#fcd5ce'),
                    getRgbaFromHex('#fae1dd'),
                    getRgbaFromHex('#f8edeb'),
                    getRgbaFromHex('#e8e8e4'),
                    getRgbaFromHex('#ece4db'),
                    getRgbaFromHex('#ffe5d9'),
                    getRgbaFromHex('#ffd7ba'),
                    getRgbaFromHex('#fec89a')
                  ]
                ]}
              />
            </div>
          )
        },
        {
          key: 'morecolors',
          label: t('More Colors'),
          children: (
            <SketchPicker
              width='calc(100% - 20px)'
              color={color}
              onChangeComplete={onColorPickerChange}
            />
          )
        }
      ]}
    />
  )
}
