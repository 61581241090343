import React, { useState, useEffect, useRef, useContext } from 'react'
// eslint-disable-next-line unicorn/import-index
import Map from './Map'
import LayerList from './LayerList'
import MiniLegend from './MiniLegend'
import { Drawer, Row, ConfigProvider, theme, Popover } from 'antd'
import _isEqual from 'lodash.isequal'
import MapToolButton from './MapToolButton'
import { MapContext, MapContextType } from '../MapContext'
import { ShareButtons } from '@maphubs/ui'
import MapStyles from './Styles'
import { Layer, LocalizedString } from '@maphubs/mhtypes'
import mapboxgl, {
  CircleLayer,
  FillLayer,
  LineLayer,
  RasterLayer
} from 'mapbox-gl'
import AutoSizer from 'react-virtualized-auto-sizer'
import useMapT from '../hooks/useMapT'
import { BaseMapOption } from '../redux/reducers/baseMapSlice'
import { klona } from 'klona/json'
import InfoOutlined from '@ant-design/icons/InfoOutlined'

type Props = {
  map_id: number | string
  title: LocalizedString
  style: mapboxgl.Style
  position?: Record<string, any>
  layers?: Array<Layer>
  height: string
  border?: boolean
  showLogo?: boolean
  disableScrollZoom?: boolean
  showTitle?: boolean
  categories?: Array<Record<string, any>>
  fitBounds?: Array<number>
  fitBoundsOptions?: Record<string, any>
  interactive?: boolean
  mapConfig: Record<string, any>
  insetConfig?: Record<string, any>
  showShareButtons?: boolean
  hideInactive?: boolean
  showScale?: boolean
  showLegendLayersButton?: boolean
  showMapTools?: boolean
  showFullScreen?: boolean
  insetMap?: boolean
  children?: any
  basemap?: string
  baseMapOptions?: BaseMapOption[]
  gpxLink?: string
  hash?: boolean
  preserveDrawingBuffer?: boolean
  primaryColor: string
  locale: string
  mapboxAccessToken: string
  showLayerVisibility?: boolean
  showLayerInfo?: boolean
  showSearch?: boolean
  onLoad?: (...args: Array<any>) => void
  darkMode?: boolean
  bunnyNetKey?: string
}

const InteractiveMap = (props: Props): JSX.Element => {
  const { t } = useMapT()
  const mapLayersList = useRef()
  const [style, setStyle] = useState(props.style)

  const [layers, setLayers] = useState<Layer[]>(props.layers || [])
  const [mapLayersListOpen, setMapLayersListOpen] = useState(false)

  //? do we still need to support changing the intital layers from props?
  const prevLayers = useRef<Layer[]>()
  useEffect(() => {
    if (
      !prevLayers.current || // first time we've seen layer props
      !_isEqual(props.layers, prevLayers.current) // or layers from props have changed
    ) {
      setLayers(props.layers)
      prevLayers.current = props.layers
    }
  }, [props.layers])

  useEffect(() => {
    setStyle(props.style)
  }, [props.style])

  const { toggleVisibility, setToggleVisibility } =
    useContext<MapContextType>(MapContext)

  useEffect(() => {
    const toggleVis = (
      layer_id: number | string,
      disableStyleUpdate?: boolean
    ) => {
      console.log('toggleVis', layer_id)
      // treat as immutable and clone
      const layersUpdateClone = klona(layers)
      const layer = layersUpdateClone.find(
        (layer) => layer.layer_id === layer_id
      )
      if (layer) {
        let active = MapStyles.settings.get(layer.style, 'active')

        if (active) {
          layer.style = MapStyles.settings.set(layer.style, 'active', false)
          active = false
        } else {
          layer.style = MapStyles.settings.set(layer.style, 'active', true)
          active = true
        }

        if (layer.style?.layers) {
          for (const styleLayer of layer.style.layers as Array<
            LineLayer | FillLayer | CircleLayer | RasterLayer
          >) {
            if (!styleLayer.layout) {
              styleLayer.layout = {}
            }

            const markerSettings = MapStyles.settings.get(styleLayer, 'markers')

            styleLayer.layout.visibility =
              active && !markerSettings?.enabled ? 'visible' : 'none'
          }
        }

        setLayers(layersUpdateClone)
        if (!disableStyleUpdate) {
          setStyle(MapStyles.style.buildMapStyle(layersUpdateClone))
        }
      } else {
        console.error('Could not find layer with id', layer_id)
      }
    }
    console.log('setting toggleVisibility')
    if (setToggleVisibility) setToggleVisibility(() => toggleVis)
  }, [setToggleVisibility, layers])

  const updateLayers = (layersUpdate: Array<Layer>, update = true) => {
    setLayers(layersUpdate)

    if (update) {
      const layersUpdateClone = klona(layersUpdate)
      setLayers(layersUpdateClone)
      setStyle(MapStyles.style.buildMapStyle(layersUpdateClone))
    }
  }

  const {
    fitBounds,
    showShareButtons,
    hash,
    showLayerVisibility,
    showLayerInfo,
    showMapTools,
    showSearch,
    showFullScreen,
    showTitle,
    categories,
    insetMap,
    hideInactive,
    showLegendLayersButton,
    interactive,
    map_id,
    fitBoundsOptions,
    showLogo,
    insetConfig,
    mapConfig,
    showScale,
    disableScrollZoom,
    gpxLink,
    preserveDrawingBuffer,
    locale,
    mapboxAccessToken,
    onLoad,
    darkMode,
    basemap,
    baseMapOptions,
    primaryColor,
    position,
    height,
    bunnyNetKey
  } = props

  const border = props.border ? '1px solid #323333' : 'none'

  let bounds

  if (fitBounds) {
    bounds = fitBounds
  } else if (
    position &&
    (typeof window === 'undefined' || !window.location.hash) && // only update position if there isn't absolute hash in the URL
    position.bbox
  ) {
    const bbox = position.bbox
    bounds = [bbox[0][0], bbox[0][1], bbox[1][0], bbox[1][1]]
  }

  const children = props.children || ''
  const title = showTitle ? props.title : undefined
  let topOffset = 0

  if (categories) {
    topOffset = 35
  }

  const mobileLegendButtonTop = `${10 + topOffset}px`
  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.compactAlgorithm],
        token: {
          colorPrimary: primaryColor || '#323333'
        }
      }}
    >
      <div
        style={{
          width: '100%',
          height: `calc(${height || '100%'} - 0px)`,
          overflow: 'hidden',
          border,
          position: 'relative'
        }}
      >
        <AutoSizer>
          {({ height, width }) => {
            let legend = <></>

            if (!width || width >= 600) {
              let insetOffset = 185

              if (!insetMap) {
                insetOffset = 30
              }

              const legendMaxHeight = topOffset + insetOffset
              legend = (
                <MiniLegend
                  style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    minWidth: '200px',
                    width: '20%',
                    zIndex: 2
                  }}
                  maxHeight={`${height - legendMaxHeight}px`}
                  hideInactive={hideInactive}
                  showLayersButton={showLegendLayersButton}
                  layers={layers}
                  title={title}
                  collapsible={interactive}
                  openLayersPanel={() => {
                    setMapLayersListOpen(true)
                  }}
                  darkMode={darkMode}
                />
              )
            }
            return (
              <div style={{ height, width }}>
                {width && width < 600 && (
                  <Popover
                    placement='right'
                    getPopupContainer={(trigger) => trigger.parentNode}
                    arrow={false}
                    overlayInnerStyle={{
                      background: 'transparent',
                      padding: 0
                    }}
                    content={
                      <div style={{ minWidth: '200px' }}>
                        <MiniLegend
                          title={title}
                          collapsible={false}
                          hideInactive={hideInactive}
                          showLayersButton={false}
                          layers={layers}
                        />
                      </div>
                    }
                    trigger='click'
                  >
                    <MapToolButton
                      top={mobileLegendButtonTop}
                      left='10px'
                      icon={<InfoOutlined />}
                    />
                  </Popover>
                )}
                <Map
                  id={'map-' + map_id}
                  fitBounds={bounds}
                  initialBaseMap={basemap}
                  baseMapOptions={baseMapOptions}
                  fitBoundsOptions={fitBoundsOptions}
                  interactive={interactive}
                  initialGLStyle={style}
                  showLogo={showLogo}
                  mapConfig={mapConfig}
                  insetConfig={insetConfig}
                  insetMap={insetMap}
                  showScale={showScale}
                  disableScrollZoom={disableScrollZoom}
                  gpxLink={gpxLink}
                  preserveDrawingBuffer={preserveDrawingBuffer}
                  hash={hash}
                  locale={locale} //pass through props locale so Map component can set it in redux
                  mapboxAccessToken={mapboxAccessToken}
                  categories={categories}
                  mapLayers={layers}
                  toggleVisibility={toggleVisibility}
                  onLoad={onLoad}
                  showMapTools={showMapTools}
                  showSearch={showSearch}
                  showFullScreen={showFullScreen}
                  darkMode={darkMode}
                  bunnyNetKey={bunnyNetKey}
                >
                  {legend}

                  <div ref={mapLayersList} />
                  <Drawer
                    getContainer={() => mapLayersList.current}
                    title={t('Map Layers')}
                    open={mapLayersListOpen}
                    onClose={() => {
                      setMapLayersListOpen(false)
                    }}
                    placement='right'
                    bodyStyle={{
                      padding: 0
                    }}
                    width='240px'
                  >
                    <Row>
                      <LayerList
                        layers={layers}
                        showDesign={false}
                        showRemove={false}
                        showVisibility={showLayerVisibility}
                        showInfo={showLayerInfo}
                        toggleVisibility={toggleVisibility}
                        updateLayers={updateLayers}
                      />
                    </Row>
                  </Drawer>
                  {children}
                  {showShareButtons && (
                    <ShareButtons
                      title={props.title}
                      iconSize={20}
                      style={{
                        position: 'absolute',
                        bottom: '40px',
                        right: '4px',
                        zIndex: '1'
                      }}
                      t={t}
                    />
                  )}
                </Map>
              </div>
            )
          }}
        </AutoSizer>
      </div>
    </ConfigProvider>
  )
}
InteractiveMap.defaultProps = {
  height: '300px',
  basemap: 'default',
  border: false,
  disableScrollZoom: true,
  showLogo: true,
  showTitle: true,
  interactive: true,
  showShareButtons: true,
  hideInactive: true,
  showScale: true,
  insetMap: true,
  showLegendLayersButton: true,
  preserveDrawingBuffer: false,
  primaryColor: 'black',
  showLayerVisibility: true,
  // show toggles in layer menu
  showLayerInfo: true,
  // show info links in layer menu
  showMapTools: true,
  showSearch: true,
  showFullScreen: true
}
export default InteractiveMap
