import React from 'react'

import { ConfigProvider, App, theme } from 'antd'
import DataGrid from './DataGrid'

type DataGridProps = React.ComponentProps<typeof DataGrid>

const DataGridWrapper = (
  props: DataGridProps & { themePrimaryColor?: string }
) => {
  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.compactAlgorithm],
        token: {
          colorPrimary: props.themePrimaryColor || '#323333'
        }
      }}
    >
      <App
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <DataGrid {...props} />
      </App>
    </ConfigProvider>
  )
}
export default DataGridWrapper
