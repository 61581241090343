import React, { useState, useEffect } from 'react'
import { Modal, Button, Switch, Row } from 'antd'
import CodeMirror from '@uiw/react-codemirror'
import { githubLight, githubDark } from '@uiw/codemirror-theme-github'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { html } from '@codemirror/lang-html'
import { linter, lintGutter } from '@codemirror/lint'
import AutoSizer from 'react-virtualized-auto-sizer'
import { LocalizedString } from '@maphubs/mhtypes'

import DarkMode from '@mui/icons-material/DarkMode'
import LightMode from '@mui/icons-material/LightMode'

type Props = {
  id: string
  onSave?: (code: string) => void
  onChange?: (code: string) => void
  onCancel?: () => void
  title?: string
  initialCode: string
  mode: 'json' | 'html'
  modal?: boolean
  visible?: boolean
  hideSave?: boolean // when using onChange instead of onSave
  t: (val: LocalizedString | string) => string
  defaultTheme: 'light' | 'dark'
}

const jsonExtensions = [json(), linter(jsonParseLinter()), lintGutter()]
const htmlExtensions = [html()]

const CodeEditor = ({
  title,
  modal,
  mode,
  id,
  onCancel,
  visible,
  initialCode,
  onSave,
  onChange,
  hideSave,
  t,
  defaultTheme
}: Props): JSX.Element => {
  const [code, setCode] = useState(initialCode)
  const [theme, setTheme] = useState(defaultTheme)

  useEffect(() => {
    setCode(initialCode)
  }, [initialCode])

  const save = (): void => {
    if (onSave) onSave(code)
  }

  const onCodeChange = React.useCallback(
    (value, viewUpdate) => {
      //console.log('value:', value)
      setCode(value)
      if (onChange) onChange(value)
    },
    [onChange]
  )

  let editor = (
    <>
      <span>Editor Failed to Load</span>
    </>
  )

  if (visible || !modal) {
    editor = (
      <AutoSizer>
        {({ height, width }) => (
          <CodeMirror
            id={id}
            value={code}
            height={`${height}px`}
            width={`${width}px`}
            extensions={mode === 'json' ? jsonExtensions : htmlExtensions}
            onChange={onCodeChange}
            theme={theme === 'light' ? githubLight : githubDark}
          />
        )}
      </AutoSizer>
    )
  }

  return modal ? (
    <>
      <style jsx global>
        {`
          .ant-modal-content {
            height: 100%;
          }
        `}
      </style>
      <Modal
        title={title || 'Code Editor'}
        open={visible}
        centered
        width='60vw'
        bodyStyle={{
          height: 'calc(80vh - 110px)',
          padding: '0px'
        }}
        onCancel={() => {
          if (onCancel) onCancel()
        }}
        footer={[
          <span key='theme' style={{ marginRight: '20px' }}>
            <Switch
              checkedChildren={<DarkMode style={{ fontSize: '16px' }} />}
              unCheckedChildren={<LightMode style={{ fontSize: '16px' }} />}
              checked={theme === 'dark'}
              onChange={(checked) => {
                setTheme(checked ? 'dark' : 'light')
              }}
            />
          </span>,
          <span key='mode' style={{ marginRight: '20px' }}>
            Mode: {mode}
          </span>,
          <Button
            key='back'
            onClick={() => {
              if (onCancel) onCancel()
            }}
          >
            {t('Cancel')}
          </Button>,
          <Button key='submit' type='primary' onClick={save}>
            {t('Save')}
          </Button>
        ]}
      >
        <div
          style={{
            height: '100%'
          }}
        >
          {editor}
        </div>
      </Modal>
    </>
  ) : (
    <div
      style={{
        height: '100%',
        width: '100%',
        borderRadius: '8px',
        overflow: 'hidden'
      }}
    >
      <Row style={{ width: '100%', height: 'calc(100% - 50px)' }}>{editor}</Row>
      <Row>
        <span key='theme' style={{ marginTop: '10px' }}>
          <Switch
            checkedChildren={<DarkMode style={{ fontSize: '16px' }} />}
            unCheckedChildren={<LightMode style={{ fontSize: '16px' }} />}
            checked={theme === 'dark'}
            onChange={(checked) => {
              setTheme(checked ? 'dark' : 'light')
            }}
          />
        </span>
      </Row>
      {!hideSave && (
        <div
          style={{
            position: 'absolute',
            bottom: '8px',
            right: '8px'
          }}
        >
          <Button
            type='primary'
            style={{
              float: 'none',
              marginTop: '15px'
            }}
            onClick={save}
          >
            {t('Save')}
          </Button>
        </div>
      )}
    </div>
  )
}
CodeEditor.defaultProps = {
  modal: true,
  defaultTheme: 'light'
}
export default CodeEditor
