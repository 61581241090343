import React, { useCallback, useState, useEffect, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import FeaturePopup from '../FeaturePopup'
import type { MapLayerMouseEvent } from 'mapbox-gl'
import { useDispatch, useSelector } from '../../redux/hooks'
import { Feature, Point } from 'geojson'
import addPresetsToFeature from '../../utils/addPresetsToFeature'
import useSelectionFilter from './useSelectionFilter'
import { setClickedFeature } from '../../redux/reducers/dataEditorSlice'
import _debounce from 'lodash.debounce'
import turfCentroid from '@turf/centroid'
import useMapT from '../../hooks/useMapT'
import mapboxgl from 'mapbox-gl'
import getInteractiveLayers from '../../utils/getInteractiveLayers'

function useSelectionHandlers(
  mapRef: React.RefObject<mapboxgl.Map>,
  glStyleRef: React.RefObject<mapboxgl.Style>,
  options: {
    interactionBufferSize?: number
  }
) {
  const dispatch = useDispatch()
  const { t, locale } = useMapT()
  const [selected, setSelected] = useState(false)
  const [selectedFeature, setSelectedFeature] = useState<Feature>(null)

  const { setSelectionFilter, clearSelectionFilter } = useSelectionFilter(
    mapRef,
    glStyleRef.current
  )

  const interactionBufferSize = options.interactionBufferSize || 10

  const enableMeasurementTools = useSelector(
    (state) => state.map.enableMeasurementTools
  )
  const editingLayer = useSelector((state) => state.dataEditor.editingLayer)
  const editing = useSelector((state) => state.dataEditor.editing)

  const clearSelection = useCallback(() => {
    clearSelectionFilter()
    setSelected(false)
    setSelectedFeature(null)
  }, [])

  const clickHandler = useCallback(
    async (e: MapLayerMouseEvent) => {
      const mapRefEl = mapRef.current._container as HTMLElement
      const mapContainer = mapRefEl.querySelector(
        '.mapboxgl-canvas-container'
      ) as HTMLElement

      if (!enableMeasurementTools) {
        // feature selection
        if (!selected && selectedFeature) {
          setSelected(true)
        } else {
          mapContainer.style.cursor = 'crosshair'
          const layersToSearch = getInteractiveLayers(glStyleRef.current)
          const features = mapRef.current.queryRenderedFeatures(
            [
              [
                e.point.x - interactionBufferSize / 2,
                e.point.y - interactionBufferSize / 2
              ],
              [
                e.point.x + interactionBufferSize / 2,
                e.point.y + interactionBufferSize / 2
              ]
            ],
            {
              layers: layersToSearch
            }
          )

          if (features && features.length > 0) {
            if (selected) {
              clearSelection()
            }

            const feature = features[0]
            const layer_id = feature.layer.metadata['maphubs:layer_id']

            // find presets and add to props
            addPresetsToFeature(feature, glStyleRef.current)
            feature.properties.layer_id = layer_id

            if (editing && editingLayer) {
              if (
                feature.properties.layer_id &&
                editingLayer.layer_id === feature.properties.layer_id
              ) {
                // don't include all the data returned from the API, just the feature, avoids sending non-seralizable data to the redux store
                const clickedFeature = {
                  id: feature.id,
                  type: feature.type,
                  properties: feature.properties,
                  geometry: feature.geometry
                }
                dispatch(setClickedFeature({ feature: clickedFeature }))
              }

              return // return here to disable interactation with other layers when editing
            }

            setSelectionFilter([feature])
            setSelected(true)
            setSelectedFeature(feature)
          } else if (selectedFeature) {
            clearSelection()
            mapContainer.style.cursor = ''
          }
        }
      }
    },
    [
      enableMeasurementTools,
      selected,
      selectedFeature,
      interactionBufferSize,
      editing,
      editingLayer,
      clearSelection
    ]
  )

  const prevClickHandlerRef = useRef()

  const addClickHandler = useCallback(
    (map) => {
      prevClickHandlerRef.current = clickHandler // store the click handler so we can remove it later
      map.on('click', clickHandler)
    },
    [clickHandler]
  )

  useEffect(() => {
    console.log('click handler changed')
    if (mapRef.current) {
      mapRef.current.off('click', prevClickHandlerRef.current)
      mapRef.current.on('click', clickHandler)
      prevClickHandlerRef.current = clickHandler
    }
  }, [clickHandler])

  // fires whenever mouse is moving across the map... use for cursor interaction... hover etc.
  const mousemoveHandler = useCallback(
    (e: mapboxgl.MapMouseEvent) => {
      if (!mapRef.current) return

      if (!enableMeasurementTools) {
        const debounced = _debounce(() => {
          try {
            const layersToSearch = getInteractiveLayers(glStyleRef.current)
            const features = mapRef.current.queryRenderedFeatures(
              [
                [
                  e.point.x - interactionBufferSize / 2,
                  e.point.y - interactionBufferSize / 2
                ],
                [
                  e.point.x + interactionBufferSize / 2,
                  e.point.y + interactionBufferSize / 2
                ]
              ],
              {
                layers: layersToSearch
              }
            )

            const mapRefEl = mapRef.current._container as HTMLElement
            const mapContainer = mapRefEl.querySelector(
              '.mapboxgl-canvas-container'
            ) as HTMLElement

            if (features && features.length > 0) {
              mapContainer.style.cursor = selected ? 'crosshair' : 'pointer'
            } else if (!selected && selectedFeature) {
              clearSelection()
              mapContainer.style.cursor = ''
            } else {
              mapContainer.style.cursor = ''
            }
          } catch (err) {
            console.log(err)
          }
        }, 300)

        debounced()
      }
    },
    [
      clearSelection,
      enableMeasurementTools,
      interactionBufferSize,
      selected,
      selectedFeature
    ]
  )
  const prevMouseMoveRef = useRef()

  const addMouseMoveHandler = useCallback(
    (map) => {
      prevMouseMoveRef.current = mousemoveHandler // store the click handler so we can remove it later
      map.on('mousemove', mousemoveHandler)
    },
    [mousemoveHandler]
  )

  useEffect(() => {
    console.log('mousemove handler changed')
    if (mapRef.current) {
      mapRef.current.off('mousemove', prevMouseMoveRef.current)
      mapRef.current.on('mousemove', mousemoveHandler)
      prevMouseMoveRef.current = mousemoveHandler
    }
  }, [mousemoveHandler])

  const mapboxPopupRef = useRef<mapboxgl.Popup>()
  useEffect(() => {
    if (selectedFeature) {
      // close any existing popups
      if (mapboxPopupRef.current?.isOpen()) {
        mapboxPopupRef.current.remove()
        mapboxPopupRef.current = undefined
      }

      const popupFeature =
        selectedFeature.geometry.type !== 'Point'
          ? (turfCentroid(selectedFeature) as Feature<Point>)
          : (selectedFeature as Feature<Point>)

      const el = document.createElement('div')
      el.className = 'maphubs-feature-popup'
      const root = createRoot(el)
      root.render(
        <FeaturePopup features={[selectedFeature]} t={t} locale={locale} />
      )
      mapboxPopupRef.current = new mapboxgl.Popup()
        .setLngLat(popupFeature.geometry.coordinates as mapboxgl.LngLatLike)
        .setDOMContent(el)
        .addTo(mapRef.current)
      mapboxPopupRef.current.on('close', clearSelection)
    } else if (mapboxPopupRef.current) {
      mapboxPopupRef.current.remove()
    }
  }, [selectedFeature, t, clearSelection, locale])

  return {
    addClickHandler,
    addMouseMoveHandler
  }
}

export default useSelectionHandlers
