import React from 'react'
export default function FarmerWife() {
  return (
    <symbol
      id='maphubs-icon-farmer-wife'
      viewBox='0 0 124.836 308.19'
      preserveAspectRatio='xMidYMid meet'
    >
      <g>
        <path d='M40.3,38.587c0.089-0.605,0.197-1.211,0.338-1.817C43.01,26.586,51.958,19.475,62.4,19.475   c1.705,0,3.417,0.197,5.089,0.586c9.255,2.155,15.823,9.805,17.071,18.696c21.771-1.595,37.216-5.59,37.216-10.269   c0-5.103-18.362-15.919-43.273-19.271C78.265,4.103,71.659,0,63.526,0c-8.133,0-14.739,4.103-14.978,9.217   C23.639,12.569,5.276,23.385,5.276,28.488C5.276,33.008,19.691,36.891,40.3,38.587z' />
        <ellipse
          transform='matrix(0.2268 -0.9739 0.9739 0.2268 7.511 93.1425)'
          cx='62.418'
          cy='41.841'
          rx='20.362'
          ry='20.362'
        />
        <path d='M5.86,158.108c3.813,0.631,7.417-1.95,8.049-5.765c4.038-24.668,9.4-43.387,17.028-55.036   c2.391-3.671,4.949-6.65,7.754-9.043C20.199,128.981,20.087,213.5,20.087,213.5c0,4.608,8.522,7.752,20.155,9.436V237.5   c0,5,4.052,9.052,9.052,9.052c4.998,0,9.052-4.052,9.052-9.052v-13.151c2.708,0.06,5.438,0.06,8.147,0V237.5   c0,5,4.052,9.052,9.052,9.052c4.998,0,9.052-4.052,9.052-9.052v-14.564c11.63-1.685,20.152-4.828,20.152-9.436   c0,0-0.109-84.518-18.601-125.234c5.05,4.308,9.327,10.537,13.061,19.087c4.939,11.326,8.671,26.497,11.72,44.991   c0.567,3.428,3.534,5.859,6.897,5.86c0.38,0,0.764-0.031,1.151-0.095c3.815-0.631,6.396-4.233,5.765-8.048   c-4.242-25.417-9.59-45.622-19.101-60.374c-4.762-7.351-10.706-13.324-18.007-17.339c-7.292-4.029-15.777-5.999-25.216-5.989   c-9.437-0.01-17.924,1.96-25.216,5.989c-10.99,6.056-18.748,16.368-24.409,29.421c-5.69,13.107-9.535,29.221-12.697,48.292   C-0.536,153.875,2.045,157.478,5.86,158.108z' />
      </g>
    </symbol>
  )
}