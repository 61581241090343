import Head from 'next/head'
import React from 'react'
import type { AppProps } from 'next/app'

// Next Auth
import { SessionProvider } from '@maphubs/auth'

// Ant Design
import { ConfigProvider, App } from 'antd'

// redux
import { Provider } from 'react-redux'
import store from '@/redux/store'

//SWR
import SWRConfig from '@/components/SWRConfig'

import { QueryParamProvider } from '@/components/QueryParamProvider'

// Global CSS

import '../theme.css'

//layout helper classes
import '../src/css/_layout.css'

//load OLD application-wide css
import '../src/css/app.css'

//print css
import '../src/css/_print.css'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

const MapHubs = ({
  Component,
  pageProps: { session, ...pageProps }
}: AppProps): JSX.Element => {
  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>{'MapHubs'}</title>
      </Head>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: process.env.NEXT_PUBLIC_PRIMARY_COLOR || '#3698EC'
          }
        }}
      >
        <App>
          <Provider store={store}>
            <SessionProvider session={session} basePath='/api/auth'>
              <SWRConfig>
                <QueryParamProvider>
                  <Component {...pageProps} />
                </QueryParamProvider>
              </SWRConfig>
            </SessionProvider>
          </Provider>
        </App>
      </ConfigProvider>
    </>
  )
}
export default MapHubs
