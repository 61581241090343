import { getToken, GetTokenParams } from 'next-auth/jwt'

const secret = process.env.NEXTAUTH_SECRET

const getAPIUser = async (req: GetTokenParams['req']) => {
  if (!secret) throw new Error('NEXTAUTH_SECRET env var is not set')
  return getToken({
    req,
    secret
  })
}

export default getAPIUser
