import { toPng } from 'html-to-image'
import Pica from 'pica'
const pica = Pica({ features: ['js', 'wasm', 'ww'] })

const getScreenshot = async (
  id: string,
  options?: { download?: boolean; fileName }
) => {
  // hide elements we don't want to include in the image

  try {
    document.querySelector<HTMLElement>(
      '#map-social-share-buttons'
    ).style.display = 'none'
  } catch {
    // do nothing
  }
  try {
    document.querySelector<HTMLElement>('#inset-toggle-button').style.display =
      'none'
  } catch {
    // do nothing
  }

  try {
    const insetToggleOpenButton = document.querySelector<HTMLElement>(
      'inset-toggle-open-button'
    )
    if (insetToggleOpenButton) insetToggleOpenButton.style.display = 'none'
  } catch {
    // do nothing
  }

  try {
    document.querySelector<HTMLElement>('#map-tools').style.display = 'none'
  } catch {
    // do nothing
  }

  try {
    document.querySelector<HTMLElement>('#legend-tool-buttons').style.display =
      'none'
  } catch {
    // do nothing
  }

  try {
    document.querySelector<HTMLElement>(
      '#exit-measurement-button'
    ).style.display = 'none'
  } catch {
    // do nothing
  }

  try {
    const mapboxglCtrlTopRightElements = document.querySelectorAll<HTMLElement>(
      '.mapboxgl-ctrl-top-right'
    )

    // eslint-disable-next-line unicorn/no-for-loop
    for (let i = 0; i < mapboxglCtrlTopRightElements.length; i++) {
      const element = mapboxglCtrlTopRightElements[i]
      element.style.display = 'none'
    }
  } catch {
    // do nothing
  }

  const mapEl = document.querySelector<HTMLElement>(`#map-container-${id}`)

  const screenshotFullSize = await toPng(mapEl, {
    quality: 0.95,
    skipFonts: true
  })

  let screenshot

  if (options?.download) {
    const link = document.createElement('a')
    link.download = `${options?.fileName}.png`
    link.href = screenshotFullSize
    link.click()
  } else {
    // use pica to resize the image

    const img = new Image()
    img.src = screenshotFullSize
    await new Promise((resolve) => {
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      img.onload = resolve
    })

    const canvas = document.createElement('canvas')
    canvas.width = 1200
    // set height proportional to img.height
    canvas.height = (img.height * canvas.width) / img.width

    await pica.resize(img, canvas, {
      unsharpAmount: 160,
      unsharpRadius: 0.6,
      unsharpThreshold: 1
    })

    screenshot = canvas.toDataURL('image/png')

    // cleanup
    canvas.remove()
  }

  // show elements we hid earlier
  try {
    document.querySelector<HTMLElement>(
      '#map-social-share-buttons'
    ).style.display = 'block'
  } catch {
    // do nothing
  }
  try {
    document.querySelector<HTMLElement>('#inset-toggle-button').style.display =
      'block'
  } catch {
    // do nothing
  }
  try {
    const insetToggleOpenButton = document.querySelector<HTMLElement>(
      'inset-toggle-open-button'
    )
    if (insetToggleOpenButton) insetToggleOpenButton.style.display = 'block'
  } catch {
    // do nothing
  }
  try {
    document.querySelector<HTMLElement>('#map-tools').style.display = 'block'
  } catch {
    // do nothing
  }
  try {
    document.querySelector<HTMLElement>('#legend-tool-buttons').style.display =
      'block'
  } catch {
    // do nothing
  }

  try {
    document.querySelector<HTMLElement>(
      '#exit-measurement-button'
    ).style.display = 'block'
  } catch {
    // do nothing
  }

  try {
    const mapboxglCtrlTopRightElements = document.querySelectorAll<HTMLElement>(
      '.mapboxgl-ctrl-top-right'
    )
    // eslint-disable-next-line unicorn/no-for-loop
    for (let i = 0; i < mapboxglCtrlTopRightElements.length; i++) {
      const element = mapboxglCtrlTopRightElements[i] as HTMLElement
      element.style.display = 'block'
    }
  } catch {
    // do nothing
  }

  return screenshot
}

export default getScreenshot
