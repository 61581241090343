import React from 'react'
export default function Factory() {
  return (
    <symbol
      id='maphubs-icon-factory'
      viewBox='0 0 100 125'
      preserveAspectRatio='xMidYMid meet'
    >
      <path d='M38.3,1.08A10.76,10.76,0,0,1,48.88,6.31,6.34,6.34,0,0,1,59,9.1h1.1A4.91,4.91,0,0,1,65,14h1.29A10.84,10.84,0,0,1,77.17,24.85v1.58a.59.59,0,0,1-.59.59H69.09a.59.59,0,0,1-.59-.59V24.85a2.17,2.17,0,0,0-2.17-2.17h-11a7.05,7.05,0,0,1-13.53-.26,10.54,10.54,0,0,1-2.23.26A10.84,10.84,0,0,1,38.3,1.08Zm56.6,80.29V96.06A2.94,2.94,0,0,1,92,99H8a2.94,2.94,0,0,1-2.94-2.94V81.37A2.94,2.94,0,0,1,8,78.43h4v-8.8a2.24,2.24,0,0,1,2.24-2.24H18V48.73A2.09,2.09,0,0,1,21.33,47l14.06,9.87V48.73A2.09,2.09,0,0,1,38.69,47l14.06,9.87V48.73A2.09,2.09,0,0,1,56,47l9.12,6.4,1.54-20.1A2.3,2.3,0,0,1,69,31.2h7.44a2.3,2.3,0,0,1,2.3,2.15l2.29,34h4.65a2.24,2.24,0,0,1,2.24,2.24v8.8h4A2.94,2.94,0,0,1,94.91,81.37ZM52.15,79.25a1.41,1.41,0,0,0,1.41,1.41H63a1.41,1.41,0,0,0,1.41-1.41V75.92A1.41,1.41,0,0,0,63,74.51H53.56a1.41,1.41,0,0,0-1.41,1.41Zm-18.44,0a1.41,1.41,0,0,0,1.41,1.41H44.6A1.41,1.41,0,0,0,46,79.25V75.92a1.41,1.41,0,0,0-1.41-1.41H35.12a1.41,1.41,0,0,0-1.41,1.41Zm3.07,8.19A1.41,1.41,0,0,0,35.38,86H25.9a1.41,1.41,0,0,0-1.41,1.41v3.33a1.41,1.41,0,0,0,1.41,1.41h9.47a1.41,1.41,0,0,0,1.41-1.41Zm18.44,0A1.41,1.41,0,0,0,53.82,86H44.34a1.41,1.41,0,0,0-1.41,1.41v3.33a1.41,1.41,0,0,0,1.41,1.41h9.47a1.41,1.41,0,0,0,1.41-1.41Zm18.44,0A1.41,1.41,0,0,0,72.26,86H62.78a1.41,1.41,0,0,0-1.41,1.41v3.33a1.41,1.41,0,0,0,1.41,1.41h9.47a1.41,1.41,0,0,0,1.41-1.41Z' />
    </symbol>
  )
}