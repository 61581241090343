import React from 'react'
export default function Wolf() {
  return (
    <symbol
      id='maphubs-icon-wolf'
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid meet'
    >
      <path
        d='M98.984,51.463c0.269-1.746,0.786-1.177,0.487-2.744c-0.082-0.43-0.298-0.845-0.491-1.602
    c-0.198-0.777-0.796-2.059-0.986-2.826c-0.233-0.956,0.005-1.373-0.207-2.343c-0.214-0.978-0.732-1.394-0.908-2.382
    c-0.11-0.622-1.562-1.575-1.7-2.211c-0.231-1.065-1.338-1.941-2.193-2.709c-0.914-0.825-2.053-3.304-2.702-4.463
    c-0.492-0.877-2.316-2.059-3.434-2.875c-1.888-2.316-3.02-3.974-6.505-5.328c-3.612-1.405-6.908-0.986-10.79-1.079
    c-2.366-0.058-4.646,0-7.012,0c-2.092,0-3.247-0.924-5.313-0.612c-2.018,0.304-3.112-0.613-5.057-1.226
    c-1.112-0.351-2.512-0.407-3.727-0.412c-0.666-0.004-1.533,0.164-2.085-0.197c0.135-0.329,0.499,0.05,0.81-0.369
    c0.311-0.419-3.866-0.45-5.803-0.689c-1.938-0.239-2.867-1.742-4.25-2.036c-1.383-0.295-5.569-2.987-6.727-3.559
    c-0.662-0.327-2.934-1.412-5.121-2.327c-0.862-0.36-0.29-0.47-1.127-1.069c-0.837-0.598-3.17-1.091-3.769-1.33
    c-0.599-0.24-2.369-0.572-3.928-2.643c0,0-2.032-2.151-2.272-1.552C14,3.314,14.018,4.344,13.935,4.719
    c-0.033,0.144-0.08,0.19-0.158,0.07c-0.537-0.4-3.911-2.172-4.156-1.751c-0.244,0.421,2.99,4.833,2.731,5.031
    c-0.257,0.198-0.542,0.114-0.736,0.389c0,0-1.378,1.246-1.6,1.513c-0.363,0.44-1.545,1.663-1.545,1.663s-0.662,1.658-0.765,2.659
    L7.585,15.02l-0.517,0.866c-1.861,1.567-3.192,3.021-3.654,3.225c-0.461,0.205-2.729,1.396-3.215,1.762
    c-0.693,0.519,0.039,1.661,0.626,1.942c0.204,0.229,0.38,0.516,0.595,0.66c1.454,0.981,1.768,0.426,2.335,0.507l1.047-0.135
    c0.387-0.102,0.477-0.211,0.643-0.271c0.476-0.173,1.205-0.553,1.726-0.813c0.519-0.259,2.24-1.698,2.587-1.914
    c0.346-0.215,1.299-0.711,1.776-0.842c0.476-0.129,0.864-0.043,0.864-0.043s-0.736,0.562-0.952,0.692s-0.969,0.782-1.358,1.214
    c-0.389,0.432-1.558,1.982-1.558,1.982s-0.727,0.349-0.927,0.539c-0.304,0.287-0.674,0.674-0.674,0.674s0.161-0.003-0.186-0.219
    c-0.347-0.215-0.402-0.168-0.272,0.265c0.131,0.433,0.086,0.257,0.086,0.257s-0.405,0.542-0.691,0.997
    c-0.448,0.709-0.652,1.528-0.659,1.54c-0.101,0.608,0.306,0.643,0.879-0.135c0.033-0.083,0.387-0.563,0.387-0.563
    s0.102-0.161,0.188,0.056c0.087,0.217-0.087,0.282,0.114,0.433c0.718,0.369,1.303,0.613,1.754,0.506
    c0.994-0.236,1.558-1.053,2.354-1.663c1.527-1.171,1.875-1.552,2.442-1.914c0.668-0.427,0.882-0.44,1.083,1.455
    c0.101,0.959-0.212,1.482-0.096,2.1c0.073,0.395,0.185-0.826,0.288-0.443c0.247,0.931,0.854,1.923,0.854,1.923l0.082-0.494
    c0,0,0.384,0.409,0.637,0.76c0.701,0.976,0.486,0.004,0.603-0.39c0,0-0.168,0.694,0.638,1.579c0.186-0.442,0.765,0.514,1.464,1.385
    c1.167,1.452,3.289,4.078,3.33,3.601c0.123-1.4,0.302-0.069,0.302-0.069c1.373,1.146,3.417,4.412,4.846,6.1
    c0.168,0.2,0.421,0.197,0.581,0.076c0.161-0.125,0.537-0.042,1.402,0.782c0.107,0.168,0.466,1.062,0.571,1.229
    c0.269,0.428,1.157,0.226,1.357,0.687c0.168,0.384,0.304,0.757,0.467,1.095c0.508,1.058,0.899,1.24,1.399,2.375
    c0.482,1.094,0.709,2.767,0.785,3.986c0.232,3.772-0.143,6.432-0.407,8.37c-0.229,1.685-0.812,3.174-0.955,4.891
    c-0.104,1.252-0.193,2.354-0.589,3.544c-0.271,0.818-0.547,1.411-0.793,2.226c-0.206,0.686-0.962,1.858-1.487,2.243
    c-0.815,0.601-1.693,0.175-2.659,0.377c-0.221,0.048-0.186,0.134-0.636,0.383l-0.702,0.136c-0.511,0.281-0.865,0.834-0.973,1.137
    c-0.21,0.586,0,0.681,0.467,0.805c0.355,0.095,1.279,0.15,1.716,0.17c0.942,0.041,1.892-0.22,2.757,0.021
    c0.158,0.045,0.388,0.323,0.848,0.359c0.669,0.053,1.663-0.021,2.056-0.021c0.798,0,1.216-0.064,2.015-0.064
    c1.247,0,1.293,0.204,2.451-0.085c1.423-0.356,3.366,0.01,3.889-0.403c0.16-0.126,0.104-0.845,0.403-1.271
    c0.499-0.71,0.309-1.016,0.561-1.194c0.251-0.179,0.483,0.636,0.636,0.181c0.152-0.455,1.193-2.911,1.697-4.062
    c0.504-1.149,0.666-1.32,1.153-2.485c0.27-0.646,0.592-1.221,0.606-1.91c-0.292,1.566,0.345-2.08,0.243-1.91
    c0.511-1.191,0.165-0.089,0.575-1.333c0.778-2.362,0.479-4.726,0.954-7.196c0.193-1.011,0.788-1.878,0.988-2.895
    c0.098-0.499-0.063-1.342,0.157-1.773c0.253-0.497,1.381-0.97,1.835-1.384c0.741-0.675,1.464-1.142,2.32-1.654
    c0.849-0.509,1.44-1.326,2.334-1.75c0.911-0.432,1.135-0.194,2.121-0.057c1.626,0.228,2.963-0.316,4.458-0.446
    c1.671-0.146,1.311,0.291,1.715,0.256l0.973-0.074c0.811,0.088,1.889-0.471,2.711-0.188c1.25,0.431,1.247,2.688,1.849,3.739
    c1.511,2.643,4.66,5.696,5.783,6.205c1.212,0.547,1.734,2.033,2.843,2.871c1.572,1.194,2.477,2.672,3.302,4.583
    c1.12,2.585,0.673,4.939-0.324,7.363c-0.524,1.272-0.832,1.979-1.875,2.673c-0.493,0.33-1.734-0.079-2.273,0.227
    c-0.281,0.158-0.826,0.551-1.058,0.676c-0.637,0.345-1.805,1.008-1.303,1.772c0.626,0.955,2.118,0.104,3.092,0.382
    c1.003,0.286,1.836-0.158,2.933-0.158c0.822,0.002,2.381,0.1,2.935-0.519c0.341-0.382,0.417-0.609,0.557-1.06
    c0.152-0.5,0.207-1.135,0.52-1.597c0.239-0.351,0.732-0.985,0.834-1.303c0.173-0.544-0.023-1.26,0.203-1.751
    c0.354-0.77,1.392-1.949,1.744-2.855c0.249-0.637-0.021-2.544,0.855-2.687c-0.07-1.007-0.217-1.44,0.228-2.092
    c0.418-0.615-0.819-1.308-1.145-1.973c-0.423-0.873-0.833-1.552-1.618-2.23c-0.892-0.771-1.803-1.771-3.007-2.674
    c2.31,0.899,4.749,2.532,6.265,4.504c1.354,1.763,2.673,3.804,2.943,6.021c0.23,1.865,1.225,4.907-0.012,5.894
    c-0.528,0.422-1.086,0.486-1.477,0.885c-0.277,0.284-0.538,0.95-0.87,1.5c-0.131,0.217-0.425,0.444-0.483,0.636
    c-0.104,0.334-0.264,0.72-0.12,0.957c0.124,0.207,0.596,0.409,0.937,0.519c0.159,0.052,0.623-0.026,0.833,0
    c1.018,0.132,2.162,0.106,3.132-0.22c0.565-0.191,1.277-0.469,1.823-0.704c0.838-0.364,0.729-0.325,1.289-1.018
    c-0.32,0.589-0.256-1.101-0.297-1.094c0.055-0.904-0.247-1.118-0.184-2.01c0.044-0.62,0.358-1.254,0.395-1.933
    c0.09-1.687,0.534-3.886,0.307-5.551c-0.158-1.161-1.038-1.532-1.582-2.596c-0.346-0.679-0.71-1.299-1.175-1.926
    c-0.194-0.261-0.766-2.257-2.212-4.46c-1.618-2.463-3.232-4.132-3.401-4.781c-0.126-0.175-1.374-1.34-1.812-2.548
    c-0.086-0.237-0.298-1.395-0.086-1.457c0.218-0.065,0.293,0.572,0.583,0.881c0.523,0.562,1.333,1.597,1.688,1.775
    c-0.004,0.03,0.571,1.563,0.586,1.613c0.285,0.391,1.5,1.07,1.973,2.145c0.944,2.146,2.451,3.239,2.917,4.636
    c0.093,0.275,0.922,0.545,1.588,1.329c1.093,1.287,2.434,2.406,2.533,1.847c0.104-0.591-0.052-2.006,0.171-2.79
    c0.169-0.599,0.708-1.592,0.635-2.061C99.087,53.45,98.766,52.887,98.984,51.463z M39.62,64.505
    c-0.325,0.544-0.445,3.646-0.614,4.431c-0.092,0.424-1.09,2.043-1.167,2.796c-0.086,0.848-1.784,2.213-1.784,2.213
    s-0.449-0.32-0.531-0.554c-0.081-0.234,0.254-0.619,0.449-1.062c0.176-0.4,0.281-1.26,0.488-1.676
    c0.435-0.869,0.122-0.632,0.231-1.283s0.601,0.16,1.104-0.879c0.123-0.249,0.115-0.56,0.213-0.892
    c0.05-0.164,0.018-0.581,0.148-0.678c0.202-0.149,0.569,0.042,0.509-0.211c-0.054-0.223,0.132-1.674,0.057-1.95
    c-0.077-0.279,0.038-1.482,0.243-2.152c0.673-2.203,0.752-4.832,1.061-4.336C40.178,58.516,39.946,63.961,39.62,64.505z'
      />
    </symbol>
  )
}