import React from 'react'
import { Avatar, Tooltip } from 'antd'
type Props = {
  group: string
  size?: number
}

const GroupTag = ({ group, size }: Props): JSX.Element => {
  if (!group) {
    return <></>
  }

  return (
    <div>
      <Tooltip title={group} placement='top'>
        <a
          target='_blank'
          className='no-padding'
          rel='noopener noreferrer'
          href={`/group/${group}`}
          style={{
            height: 'initial'
          }}
        >
          <Avatar
            size={size || 24}
            style={{
              color: '#FFF'
            }}
          >
            {group.charAt(0).toUpperCase()}
          </Avatar>
        </a>
      </Tooltip>
    </div>
  )
}
export default GroupTag
