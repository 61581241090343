import React from 'react'
export default function TugBoat() {
  return (
    <symbol
      id='maphubs-icon-tug-boat'
      viewBox='0 0 90 112.5'
      preserveAspectRatio='xMidYMid meet'
    >
      <g>
        <path d='M66.8,18.7l0,25l20.1-5.8L74.4,75.5l-63.9,3.7L5.7,66.9l54.6-12.5l-0.4-3L14.8,62.3l-1.3-3.5l24.6-5.4l-0.2-28.7l-3.1,0.6   l-0.3-3.7l6-1.5v-8.2h-2.6v-3h9.5v2.8h-2.6v7.4l25.7-5l0.3,3.8L66.8,18.7z M56.1,39c4,0,7.3-3.3,7.3-7.3s-3.3-7.3-7.3-7.3   s-7.3,3.3-7.3,7.3S52.1,39,56.1,39z' />
        <polygon points='2.1,58.7 6.1,67.9 65,57.3 62,47.9  ' />
      </g>
    </symbol>
  )
}