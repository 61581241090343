import React from 'react'
import { Modal, App, FloatButton } from 'antd'

import UndoOutlined from '@ant-design/icons/UndoOutlined'
import RedoOutlined from '@ant-design/icons/RedoOutlined'
import SaveOutlined from '@ant-design/icons/SaveOutlined'
import CloseOutlined from '@ant-design/icons/CloseOutlined'

import MapToolButton from '../Map/MapToolButton'
import useMapT from '../hooks/useMapT'
import { useSelector, useDispatch } from '../redux/hooks'
import {
  undoEdit,
  redoEdit,
  stopEditing,
  saveEdits
} from '../redux/reducers/dataEditorSlice'

const { confirm } = Modal
type Props = {
  stopEditingLayer: () => void
  onFeatureUpdate: (...args: Array<any>) => void
}

const EditorToolButtons = ({
  stopEditingLayer,
  onFeatureUpdate
}: Props): JSX.Element => {
  const { notification, message } = App.useApp()
  const { t } = useMapT()
  const dispatch = useDispatch()

  const edits = useSelector((state) => state.dataEditor.edits)

  const redo = useSelector((state) => state.dataEditor.redo)

  const onSaveEdits = async () => {
    const closeMessage = message.loading(t('Saving'), 0)
    try {
      await dispatch(saveEdits(true)).unwrap()
      message.success(t('Edits Saved'))
    } catch (err) {
      notification.error({
        message: t('Error'),
        description: err.message || err.toString() || err,
        duration: 0
      })
    } finally {
      closeMessage()
    }
  }

  const onStopEditing = (): void => {
    if (edits.length > 0) {
      confirm({
        title: t('Unsaved Edits'),
        content: t('Do you want to save your edits before exiting?'),
        okText: t('Save Edits'),
        okType: 'primary',
        cancelText: t('Discard Edits'),

        async onOk() {
          await onSaveEdits()
          dispatch(stopEditing())
          stopEditingLayer()
        },

        onCancel() {
          dispatch(stopEditing())
          stopEditingLayer()
        }
      })
    } else {
      dispatch(stopEditing())
      stopEditingLayer()
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <FloatButton.Group
        shape='square'
        style={{
          right: 300,
          top: 10,
          position: 'absolute',
          transform: 'rotate(-90deg)',
          pointerEvents: 'all'
        }}
      >
        <FloatButton
          icon={
            <UndoOutlined
              style={{
                transform: 'rotate(90deg)'
              }}
            />
          }
          onClick={() => {
            if (edits.length === 0) return
            dispatch(undoEdit({ onFeatureUpdate }))
          }}
          tooltip={t('Undo')}
        />
        <FloatButton
          icon={
            <RedoOutlined
              style={{
                transform: 'rotate(90deg)'
              }}
            />
          }
          onClick={() => {
            if (redo.length === 0) return
            dispatch(redoEdit({ onFeatureUpdate }))
          }}
          tooltip={t('Redo')}
        />
        <FloatButton
          icon={
            <SaveOutlined
              style={{
                transform: 'rotate(90deg)'
              }}
            />
          }
          onClick={() => {
            if (edits.length === 0) return
            onSaveEdits()
          }}
          tooltip={t('Save Edits')}
        />
        <FloatButton
          icon={
            <CloseOutlined
              style={{
                transform: 'rotate(90deg)'
              }}
            />
          }
          onClick={onStopEditing}
          tooltip={t('Stop Editing')}
        />
      </FloatButton.Group>
    </div>
  )
}
export default EditorToolButtons
