import React from 'react'
export default function Magnifier() {
  return (
    <symbol
      id='maphubs-icon-magnifier'
      viewBox='0 0 100 125'
      preserveAspectRatio='xMidYMid meet'
    >
      <g>
        <path d='M100,80.665L76.874,57.537c2.457-5.213,3.834-11.037,3.834-17.185C80.708,18.065,62.641,0,40.354,0   C18.067,0,0,18.065,0,40.353c0,22.289,18.067,40.355,40.354,40.355c6.147,0,11.971-1.375,17.186-3.835L80.665,100L100,80.665z    M19.526,40.353c0-11.5,9.325-20.826,20.827-20.826c11.503,0,20.828,9.326,20.828,20.826c0,11.503-9.325,20.828-20.828,20.828   C28.852,61.181,19.526,51.855,19.526,40.353z' />
      </g>
    </symbol>
  )
}