import sha256 from 'js-sha256'

const signUrl = (url: string, securityKey: string, expirationTime = 3600) => {
  const expires = Math.floor(Date.now() / 1000) + expirationTime
  const parsedUrl = new URL(url)
  const signaturePath = decodeURIComponent(parsedUrl.pathname)
  const hashableBase = securityKey + signaturePath + expires

  const hashed = sha256.sha256.digest(hashableBase)

  let token = Buffer.from(hashed).toString('base64')
  token = token
    .replace(/\n/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
  return url + '?token=' + token + '&expires=' + expires
}

export { signUrl }
