import React from 'react'
import { Input, InputProps, Tabs } from 'antd'
import { LocalizedString } from '@maphubs/mhtypes'
import { localeUtil } from '@maphubs/locales'

const supportedLangs = localeUtil.getSupported()
let languagesFromConfig
const langs = []

if (process.env.NEXT_PUBLIC_LANGUAGES) {
  languagesFromConfig = process.env.NEXT_PUBLIC_LANGUAGES.split(',')
  languagesFromConfig = languagesFromConfig.map((lang) => lang.trim())
  supportedLangs.map((lang) => {
    if (languagesFromConfig.includes(lang.value)) {
      langs.push(lang.value)
    }
  })
}

type Props = Omit<InputProps, 'value' | 'defaultValue' | 'onChange'> & {
  value?: LocalizedString | string
  defaultValue?: LocalizedString
  onChange?: (val: LocalizedString) => void
}

const LocalizedInput = (props: Props): JSX.Element => {
  const { value, defaultValue, onChange, ...rest } = props

  const fixedValue = (
    typeof value === 'string' ? { en: value } : value
  ) as LocalizedString
  const fixedDefaultValue = (
    typeof defaultValue === 'string' ? { en: defaultValue } : defaultValue
  ) as LocalizedString
  return (
    <>
      <style jsx>
        {`
          .localized-input {
            padding-bottom: 0px;
            width: 100%;
          }
        `}
      </style>
      <div className='localized-input'>
        <Tabs
          animated={false}
          size='small'
          type='card'
          items={langs.map((lang) => {
            return {
              key: lang,
              label: lang,
              children: (
                <Input
                  {...rest}
                  value={fixedValue?.[lang]}
                  defaultValue={fixedDefaultValue?.[lang]}
                  onChange={(e) => {
                    const val = e.target.value
                    const changedValue = {}
                    changedValue[lang] = val
                    const valueUpdate = Object.assign(
                      {},
                      fixedValue,
                      changedValue
                    )

                    if (onChange) {
                      props.onChange(valueUpdate)
                    }
                  }}
                />
              )
            }
          })}
        />
      </div>
    </>
  )
}
export default LocalizedInput
