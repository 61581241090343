import React from 'react'

import { ConfigProvider, App, theme } from 'antd'
import MapMaker from './MapMaker'

type DataGridProps = React.ComponentProps<typeof MapMaker>

const MapMakerWrapper = (
  props: DataGridProps & { themePrimaryColor?: string }
) => {
  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.compactAlgorithm],
        token: {
          colorPrimary: props.themePrimaryColor || '#323333'
        }
      }}
    >
      <App
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <MapMaker {...props} />
      </App>
    </ConfigProvider>
  )
}
export default MapMakerWrapper
