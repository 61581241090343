import React from 'react'
export default function Clipboard() {
  return (
    <symbol
      id='maphubs-icon-clipboard'
      viewBox='0 0 100 125'
      preserveAspectRatio='xMidYMid meet'
    >
      <g>
        <path d='M37.532,24.217h24.473c1.423,0,2.577-1.142,2.577-2.584v-3.849c0-1.427-1.152-2.584-2.583-2.584h-3.858   c0,0,0-9.017-8.373-9.017c-8.373,0-8.403,9.017-8.403,9.017h-3.84c-1.42,0-2.571,1.142-2.571,2.584v3.849   C34.954,23.06,36.116,24.217,37.532,24.217z' />
        <path d='M76.221,17.428h-4.687v4.202h4.687c0,0,4.202,0,4.202,4.201v59.582c0,4.635-4.202,4.202-4.202,4.202H23.779   c0,0-4.202,0-4.202-4.202V25.831c0-4.201,4.202-4.201,4.202-4.201h4.684v-4.202h-4.684c0,0-8.404,0-8.404,8.403   c0,24.329,0,59.582,0,59.582c0,8.404,8.404,8.404,8.404,8.404h52.441c0,0,8.404,0,8.404-8.404V25.831   C84.625,17.428,76.221,17.428,76.221,17.428z' />
        <rect x='27.553' y='55.001' width='44.894' height='4.588' />
        <rect x='27.553' y='71.729' width='44.894' height='4.588' />
        <rect x='27.553' y='38.273' width='44.894' height='4.588' />
      </g>
    </symbol>
  )
}