const getBaseUrl = (): string => {
  if (typeof window === 'undefined') {
    throw new Error('getBaseUrl() must be called in the browser')
  }
  const host = window.location.host
  const port = Number.parseInt(window.location.port)
  const proto = window.location.protocol
  const url = proto + '//' + host

  /*
  if (port !== 80) {
    url += ':' + port
  }
  */

  return url
}

export default getBaseUrl
