import React from 'react'
export default function Money() {
  return (
    <symbol
      id='maphubs-icon-money'
      viewBox='0 0 512 640'
      preserveAspectRatio='xMidYMid meet'
    >
      <path d='M512.188,320V0h-512v320H66.75c1.75,5.594,4.313,10.969,7.828,16c-6.719,9.625-10.391,20.375-10.391,32  s3.672,22.375,10.391,32c-6.719,9.625-10.391,20.375-10.391,32c0,47.125,59.219,80,144,80s144-32.875,144-80  c0-11.625-3.656-22.375-10.375-32c0,0-0.031,0-0.031,0.031c6.719-9.625,10.406-20.406,10.406-32.031s-3.656-22.375-10.375-32  c0,0-0.031,0-0.031,0.031c3.5-5.031,6.094-10.406,7.844-16.031H512.188z M66.75,288H32.188V32h448v256H349.625  c-1.75-5.625-4.313-11-7.844-16.031c0,0.031,0.031,0.031,0.031,0.031h74.375c0-26.531,21.5-48,48-48V96c-26.5,0-48-21.469-48-48  H294.156c24.938,21.688,42.031,63.438,42.031,112c0,34.188-8.594,64.813-22.313,87.625C288.375,232.813,251.766,224,208.188,224  c-7.188,0-13.922,0.563-20.734,1c0.031,0,0.047,0,0.063,0c-7.156-18.969-11.328-41.125-11.328-65c0-48.563,17.125-90.313,42-112  h-122c0,26.531-21.5,48-48,48v128c20.25,0,37.5,12.563,44.547,30.313C79.906,263.844,70.75,275.125,66.75,288z M368.188,160  c0-13.25,10.75-24,24-24s24,10.75,24,24s-10.75,24-24,24S368.188,173.25,368.188,160z M331.063,260.25  c-0.469-0.438-0.75-0.938-1.25-1.375C330.313,259.313,330.594,259.813,331.063,260.25z M96.188,160c0-13.25,10.75-24,24-24  s24,10.75,24,24s-10.75,24-24,24S96.188,173.25,96.188,160z M208.188,480c-61.859,0-112-21.5-112-48c0-3.5,0.969-6.875,2.625-10.156  C124.391,438.125,162.313,448,208.188,448s83.813-9.875,109.375-26.156c1.656,3.281,2.625,6.656,2.625,10.156  C320.188,458.5,270.063,480,208.188,480z M208.188,416c-61.859,0-112-21.5-112-48c0-3.5,0.969-6.875,2.625-10.156  C124.391,374.125,162.313,384,208.188,384s83.813-9.875,109.375-26.156c1.656,3.281,2.625,6.656,2.625,10.156  C320.188,394.5,270.063,416,208.188,416z M208.188,352c-61.859,0-112-21.5-112-48s50.141-48,112-48c61.875,0,112,21.5,112,48  S270.063,352,208.188,352z' />
    </symbol>
  )
}