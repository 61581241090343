import React from 'react'
export default function Wifi() {
  return (
    <symbol
      id='maphubs-icon-wifi'
      viewBox='0 0 100 125'
      preserveAspectRatio='xMidYMid meet'
    >
      <g>
        <path d='M8.055,46.753L0,40.33c10.822-16.073,29.188-26.647,50.022-26.647c20.806,0,39.147,10.543,49.978,26.575l-8.078,6.388   C82.995,32.977,67.566,23.94,50.022,23.94C32.436,23.94,16.97,33.023,8.055,46.753z M50.056,59.838   c6.134,0,11.364,3.936,13.304,9.412l8.248-6.521c-4.053-7.771-12.182-13.077-21.552-13.077c-9.458,0-17.649,5.402-21.665,13.29   l8.264,6.588C38.512,63.906,43.816,59.838,50.056,59.838z M50.124,41.355c11.993,0,22.478,6.45,28.181,16.067l7.979-6.307   C78.697,39.1,65.303,31.115,50.042,31.115c-15.319,0-28.756,8.043-36.326,20.136l8.073,6.438   C27.455,47.925,38.023,41.355,50.124,41.355z M49.999,68.639c-4.882,0-8.839,3.959-8.839,8.841c0,4.881,3.957,8.838,8.839,8.838   c4.883,0,8.841-3.957,8.841-8.838C58.84,72.598,54.882,68.639,49.999,68.639z' />
      </g>
    </symbol>
  )
}