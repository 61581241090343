import React, { useCallback } from 'react'
import type mapboxgl from 'mapbox-gl'
import { Feature } from 'geojson'

function useSelectionFilter(
  mapRef: React.RefObject<mapboxgl.Map>,
  glStyle: mapboxgl.Style
) {
  const setSelectionFilter = useCallback(
    (features: Feature[]) => {
      if (glStyle?.layers) {
        for (const layer of glStyle.layers) {
          const filter = ['in', 'mhid']
          for (const feature of features) {
            filter.push(feature.properties.mhid)
          }

          if (
            mapRef.current &&
            mapRef.current.getLayer(layer.id) &&
            filter[2] // found a mhid
          ) {
            if (layer.id.startsWith('omh-hover-point')) {
              mapRef.current.setFilter(layer.id, [
                'all',
                ['in', '$type', 'Point'],
                filter
              ])
            } else if (layer.id.startsWith('omh-hover-line')) {
              mapRef.current.setFilter(layer.id, [
                'all',
                ['in', '$type', 'LineString'],
                filter
              ])
            } else if (layer.id.startsWith('omh-hover-polygon')) {
              mapRef.current.setFilter(layer.id, [
                'all',
                ['in', '$type', 'Polygon'],
                filter
              ])
            }
          }
        }
      }
    },
    [mapRef, glStyle]
  )

  const clearSelectionFilter = useCallback(async (): Promise<void> => {
    if (mapRef.current && glStyle) {
      for (const layer of glStyle.layers) {
        if (
          layer.id.startsWith('omh-hover') &&
          mapRef.current.getLayer(layer.id)
        ) {
          mapRef.current.setFilter(layer.id, ['==', 'mhid', ''])
        }
      }
    }
  }, [mapRef, glStyle])
  return { setSelectionFilter, clearSelectionFilter }
}
export default useSelectionFilter
