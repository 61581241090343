import { createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../../store'
import _map from 'lodash.map'
import _remove from 'lodash.remove'
import { klona } from 'klona/json'
/**
 * Called when the base map style is changed
 * goal is to effeciently apply the overlay styles to the new base map
 */

const setBaseMapStyleThunk = createAsyncThunk(
  'map/setBaseMapStyle',
  async (
    args: {
      style: mapboxgl.Style
    },
    { getState }
  ): Promise<{
    glStyle: mapboxgl.Style
  }> => {
    const appState = getState() as RootState
    const overlayMapStyle = appState.map.overlayMapStyle
    const prevBaseMapStyle = appState.baseMap.prevBaseMapStyle
    let glStyle = klona(appState.map.glStyle)

    const style = args.style

    if (overlayMapStyle) {
      // need to update in place to avoid redrawing overlay layers
      const sourcesToAdd = Object.keys(style.sources)
      const layersToAdd = style.layers

      if (prevBaseMapStyle) {
        // need to clear previous base map
        for (const element of Object.keys(prevBaseMapStyle.sources)) {
          delete glStyle.sources[element]
        }

        for (const element of _map(prevBaseMapStyle.layers, 'id')) {
          _remove(glStyle.layers, {
            id: element
          })
        }
      }

      for (const key of sourcesToAdd) {
        const source = style.sources[key]
        glStyle.sources[key] = source
      }
      // FIXME: this will reset layers showing below base map labels
      const updatedLayers = [...layersToAdd, ...glStyle.layers]
      glStyle.layers = updatedLayers
    } else {
      // we can just overwrite the base map and let mapbox-gl do the diff
      glStyle = klona(style)
    }

    // ? base map style is updated in baseMap reducer?
    //baseMapStyle = style

    //* don't clear glyphs/sprite, prevents full redraw when switching to raster base maps
    glStyle.glyphs = style.glyphs || glStyle.glyphs
    glStyle.sprite = style.sprite || glStyle.sprite
    glStyle.metadata = style.metadata
    glStyle.id = style.id || glStyle.id
    glStyle.name = style.name || glStyle.name

    return {
      glStyle
    }
  }
)
export { setBaseMapStyleThunk }
