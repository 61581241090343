/**
 * Note: SSR not supported, needs to be loaded dynamically in NextJS
 */
import React, { useRef, useEffect, useCallback, useContext } from 'react'
import { MapContext } from '../MapContext'
import MapToolButton from './MapToolButton'
import MapToolPanel from './MapToolPanel'
import InsetMap from './InsetMap'
import MapLayerMenu from './MapLayerMenu'
import { Layer } from '@maphubs/mhtypes'
import { FeatureCollection } from 'geojson'
import mapboxgl from 'mapbox-gl'
import ScalePositionControl from './DualScaleControl'
import {
  initMap,
  setEnableMeasurementTools,
  setAllowLayersToMoveMap,
  setInteractiveLayers
} from '../redux/reducers/mapSlice'
import { ConfigProvider, theme } from 'antd'
import {
  BaseMapOption,
  setBaseMapThunk,
  setAPIKeys,
  setBaseMapOptions,
  updateMapPosition
} from '../redux/reducers/baseMapSlice'
import { setBaseMapStyleThunk } from '../redux/reducers/map/setBaseMapStyleThunk'
import { setOverlayStyleThunk } from '../redux/reducers/map/setOverlayStyleThunk'
import { useDispatch, useSelector } from '../redux/hooks'
import useMapT from '../hooks/useMapT'
import _isequal from 'lodash.isequal'
import CloseOutlined from '@ant-design/icons/CloseOutlined'
import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import debounce from 'lodash/debounce'
import useSelectionHandlers from './hooks/useSelectionHandlers'
import getInteractiveLayers from '../utils/getInteractiveLayers'
import useGeoJSONLayer from './hooks/useGeoJSONLayer'
import useInteraction from './hooks/useInteraction'
import useLanguage from './hooks/useLanguage'
import tokml from '@maphubs/tokml'
import { signUrl } from '../utils/bunnyNetSigning'

type Props = {
  className?: string
  id?: string
  maxBounds?: Record<string, any>
  maxZoom?: number
  minZoom?: number
  zoom?: number
  initialBaseMap?: string
  baseMapOptions?: BaseMapOption[]
  initialGLStyle?: mapboxgl.Style
  features?: Array<Record<string, any>>
  tileJSONType?: string
  tileJSONUrl?: string
  data?: FeatureCollection
  interactive?: boolean
  showLogo?: boolean
  showScale?: boolean
  fitBounds?:
    | [[number, number], [number, number]]
    | [number, number, number, number]
  fitBoundsOptions?: Record<string, any>
  disableScrollZoom?: boolean
  enableRotation?: boolean
  navPosition?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'
  onChangeBaseMap?: (...args: Array<any>) => any
  insetMap?: boolean
  interactionBufferSize?: number
  hash?: boolean
  gpxLink?: string
  attributionControl?: boolean
  allowLayerOrderOptimization?: boolean
  preserveDrawingBuffer?: boolean
  mapConfig: Record<string, any>
  insetConfig?: Record<string, any>
  children?: JSX.Element | JSX.Element[]
  onLoad?: (...args: Array<any>) => void
  locale: string
  mapboxAccessToken: string
  categories?: Array<Record<string, any>>
  mapLayers?: Layer[]
  toggleVisibility?: (...args: Array<any>) => void
  showMapTools?: boolean
  showSearch?: boolean
  showFullScreen?: boolean
  darkMode?: boolean
  useAntConfigProvider?: boolean
  primaryColor?: string
  bunnyNetKey?: string
}

const MapHubsMap = ({
  allowLayerOrderOptimization,
  disableScrollZoom,
  showScale,
  showFullScreen,
  navPosition,
  id,
  locale,
  fitBounds,
  fitBoundsOptions,
  hash,
  data,
  zoom,
  minZoom,
  maxZoom,
  preserveDrawingBuffer,
  enableRotation,
  attributionControl,
  onLoad,
  initialGLStyle,
  initialBaseMap,
  interactive,
  insetMap,
  showLogo,
  mapLayers,
  toggleVisibility,
  showMapTools,
  showSearch,
  categories,
  gpxLink,
  mapboxAccessToken,
  insetConfig,
  interactionBufferSize,
  onChangeBaseMap,
  darkMode,
  baseMapOptions,
  useAntConfigProvider,
  primaryColor,
  children,
  bunnyNetKey
}: Props): JSX.Element => {
  const { t } = useMapT()
  const dispatch = useDispatch()
  const mapRef = useRef<mapboxgl.Map>()
  const { setMapboxGLMap } = useContext(MapContext)

  // local state
  const mapLoadedRef = useRef(false)
  const mapLoadingRef = useRef(false)

  const mapWrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    //automatically resize map when container resizes
    const resizer = new ResizeObserver(
      debounce(() => {
        if (mapRef.current) {
          mapRef.current.resize()
        }
      }, 100)
    )
    resizer.observe(mapWrapperRef.current)

    return () => {
      resizer.disconnect()
      // restore the baseMap from props when unmounting
      dispatch(setBaseMapThunk(initialBaseMap))
      if (mapRef.current) {
        try {
          setMapboxGLMap(null)
          mapRef.current.remove()
          mapRef.current = null
        } catch (err) {
          console.error('Failed to unmount MAPBOX GL')
          console.log(err)
        }
      }
      console.log('*******************MAPBOX GL UNMOUNTED')
    }
  }, [setMapboxGLMap, dispatch, initialBaseMap])

  // set base map options from props
  useEffect(() => {
    if (baseMapOptions) {
      dispatch(setBaseMapOptions(baseMapOptions))
    }
  }, [baseMapOptions, dispatch])

  const glStyle = useSelector((state) => state.map.glStyle)
  const glStyleRef = useRef<mapboxgl.Style>()

  useEffect(() => {
    if (glStyle) glStyleRef.current = glStyle
  }, [glStyle, glStyleRef])

  const enableMeasurementTools = useSelector(
    (state) => state.map.enableMeasurementTools
  )
  const measurementMessage = useSelector(
    (state) => state.map.measurementMessage
  )

  const measurementFeatures = useSelector(
    (state) => state.map.measurementFeatures
  )

  const { addClickHandler, addMouseMoveHandler } = useSelectionHandlers(
    mapRef,
    glStyleRef,
    {
      interactionBufferSize
    }
  )

  useEffect(() => {
    dispatch(setAPIKeys({ mapboxAccessToken }))
  }, [mapboxAccessToken, dispatch])

  // do not allow layers to position the map if user is providing the location
  useEffect(() => {
    dispatch(setAllowLayersToMoveMap(!fitBounds))
  }, [fitBounds, dispatch])

  const { initGeoJSON } = useGeoJSONLayer(mapRef, mapLoadedRef, data, id)

  const { interactionActive, addInteraction } = useInteraction(mapRef, {
    showFullScreen,
    navPosition,
    id,
    interactive
  })

  const { addLanguageControl } = useLanguage(mapRef, locale, { id })

  const moveendHandler = useCallback(
    async (e: mapboxgl.MapboxEvent) => {
      console.log(`(${id}) mouse up fired`)
      const center = mapRef.current.getCenter()
      const zoom = mapRef.current.getZoom()
      const bounds = mapRef.current.getBounds().toArray()
      const position = {
        zoom,
        lng: center.lng,
        lat: center.lat,
        bbox: bounds
      }
      dispatch(updateMapPosition(position))
    },
    [dispatch, id]
  )

  const createMap = useCallback(
    async (interactiveLayersUpdate) => {
      // create the map
      console.log(`(${id}) Creating MapboxGL Map`)
      mapboxgl.accessToken = mapboxAccessToken

      // initialize the base map
      const result = await dispatch(setBaseMapThunk(initialBaseMap)).unwrap()
      await dispatch(setBaseMapStyleThunk({ style: result.baseMapStyle }))

      if (!mapboxgl || !mapboxgl.supported || !mapboxgl.supported()) {
        alert(
          t(
            'Your browser does not support Mapbox GL please see: https://help.maphubs.com/getting-started/troubleshooting-common-issues'
          )
        )
        return
      }

      const map = new mapboxgl.Map({
        container: id,
        style: result.baseMapStyle,
        zoom: zoom || 0,
        minZoom: minZoom || 0,
        maxZoom: maxZoom || 22,
        interactive: interactionActive,
        dragRotate: !!enableRotation,
        touchZoomRotate: true,
        touchPitch: false,
        preserveDrawingBuffer,
        center: [0, 0],
        hash,
        attributionControl: false,
        transformRequest: (url: string, resourceType) => {
          if (
            resourceType === 'Tile' &&
            url.includes('https://pmtiles.maphubs.com') &&
            bunnyNetKey
          ) {
            const signedUrl = signUrl(url, bunnyNetKey, 60 * 60 * 24 * 7)
            return {
              url: signedUrl
            }
          }
        }
      })
      // catch generic errors so 404 tile errors etc don't cause unexpected issues
      map.on('error', (err) => {
        console.log(err.error)
      })
      map.on('load', () => {
        console.log(`(${id}) MAP LOADED`)
      })
      map.on('style.load', () => {
        console.log(`(${id}) style.load`)

        // restore map bounds (except for geoJSON maps)
        if (
          !data && // use bbox for GeoJSON data
          !mapLoadedRef.current && // only set map position on first style load (not after changing base map etc)
          fitBounds // bounds are provided in Props
        ) {
          let bounds = fitBounds as
            | [[number, number], [number, number]]
            | [number, number, number, number]

          if (bounds.length > 2) {
            // convert from GeoJSON bbox to Mapbox bounds format
            bounds = [
              [bounds[0] as number, bounds[1] as number],
              [bounds[2], bounds[3]]
            ]
          }

          console.log(`(${id}) fitting map to bounds: ${bounds.toString()}`)
          map.fitBounds(fitBounds, fitBoundsOptions)
        }

        // add the omh data

        if (data && !mapLoadedRef.current) {
          initGeoJSON(data)
        }

        // now that the map is loaded we can add our overlay layer style
        if (!mapLoadedRef.current) {
          // only set the overlay style on first load, after that changes are handled in redux
          dispatch(
            setOverlayStyleThunk({
              overlayStyle: initialGLStyle,
              optimizeLayers: allowLayerOrderOptimization,
              mapboxMap: mapRef.current
            })
          )
          if (onLoad) onLoad()
        }

        mapLoadedRef.current = true // must set this true, before disablng loading otherwise createMap is called twice
        mapLoadingRef.current = false
      })

      // end style.load

      addClickHandler(map)
      addMouseMoveHandler(map)
      map.on('moveend', moveendHandler)

      if (interactive) {
        addInteraction(map)
      }

      if (attributionControl) {
        map.addControl(new mapboxgl.AttributionControl(), 'bottom-left')
      }

      if (showScale) {
        map.addControl(
          new ScalePositionControl({
            maxWidth: 175
          }),
          'bottom-right'
        )
      }

      addLanguageControl(map)

      if (disableScrollZoom) {
        map.scrollZoom.disable()
      }

      mapRef.current = map
      setMapboxGLMap(map)
      dispatch(initMap({ interactiveLayers: interactiveLayersUpdate }))
    },
    [
      addClickHandler,
      addInteraction,
      addLanguageControl,
      addMouseMoveHandler,
      allowLayerOrderOptimization,
      attributionControl,
      data,
      disableScrollZoom,
      dispatch,
      enableRotation,
      fitBounds,
      fitBoundsOptions,
      hash,
      id,
      initGeoJSON,
      initialBaseMap,
      initialGLStyle,
      interactionActive,
      mapboxAccessToken,
      maxZoom,
      minZoom,
      moveendHandler,
      onLoad,
      preserveDrawingBuffer,
      setMapboxGLMap,
      showScale,
      t,
      zoom,
      interactive
    ]
  )

  // create the map if on first load or if glStyle props has changed
  useEffect(() => {
    let interactiveLayersUpdate: string[] = []
    if (initialGLStyle) {
      interactiveLayersUpdate = getInteractiveLayers(initialGLStyle)
    }

    if (!mapLoadedRef.current && !mapLoadingRef.current) {
      console.log('CREATE MAP')
      mapLoadingRef.current = true

      dispatch(setInteractiveLayers(interactiveLayersUpdate))
      createMap(interactiveLayersUpdate)
    }
  }, [createMap, dispatch, initialGLStyle])

  const prevGLStyle = useRef<mapboxgl.Style>()
  useEffect(() => {
    const update = () => {
      // style has changed
      console.log(`(${id}) glstyle changing from props`)
      if (!prevGLStyle.current) console.log('prevGLStyle not set')

      dispatch(
        setOverlayStyleThunk({
          overlayStyle: initialGLStyle,
          optimizeLayers: allowLayerOrderOptimization,
          mapboxMap: mapRef.current
        })
      )
      prevGLStyle.current = initialGLStyle
      const interactiveLayerUpdate = getInteractiveLayers(initialGLStyle)
      dispatch(setInteractiveLayers(interactiveLayerUpdate))
    }
    if (!prevGLStyle.current || !_isequal(prevGLStyle, initialGLStyle)) {
      if (mapLoadingRef.current && mapLoadedRef.current) {
        // map must be loaded before we can change the style again)
        console.log('glstyle change before map loaded')
        setTimeout(update, 500)
      } else {
        update()
      }
    }
  }, [
    allowLayerOrderOptimization,
    initialGLStyle,
    dispatch,
    id,
    mapLoadingRef,
    mapLoadedRef
  ])

  // handle fitBounds prop change
  useEffect(() => {
    if (fitBounds && mapRef.current) {
      //TODO: previously we did a deep compare _isEqual here with prev prop
      console.log(`(${id}) FIT BOUNDS CHANGING`)
      let bounds = fitBounds

      if (bounds.length > 2) {
        bounds = [
          [bounds[0] as number, bounds[1] as number],
          [bounds[2], bounds[3]]
        ]
      }
      try {
        console.log(`(${id}) bounds: ${bounds.toString()}`)
        mapRef.current.fitBounds(bounds, fitBoundsOptions)
      } catch (err) {
        console.error(`Failed to fit Bounds: ${err.message}`)
      }

      // disable map postion from layers if user is now providing bounds
      dispatch(setAllowLayersToMoveMap(false))
    }
  }, [id, fitBounds, fitBoundsOptions, dispatch])

  const changeBaseMap = useCallback(
    async (mapName: string) => {
      console.log(`(${id}) changing basemap to: ${mapName}`)
      const result = await dispatch(setBaseMapThunk(mapName)).unwrap()
      const styleResult = await dispatch(
        setBaseMapStyleThunk({ style: result.baseMapStyle })
      ).unwrap()
      mapRef.current.setStyle(styleResult.glStyle)

      dispatch(setAllowLayersToMoveMap(false))

      if (onChangeBaseMap) {
        onChangeBaseMap(mapName)
      }
    },
    [dispatch, id, onChangeBaseMap]
  )

  const className = 'mode map active'

  return (
    <ConfigProvider
      theme={
        useAntConfigProvider
          ? {
              algorithm: [theme.compactAlgorithm],
              token: {
                colorPrimary: primaryColor || '#323333'
              }
            }
          : undefined
      }
    >
      <div
        id={`${id}-fullscreen-wrapper`}
        className={className}
        style={{ height: '100%', width: '100%' }}
      >
        <style jsx global>
          {`
            .mapboxgl-canvas {
              left: 0 !important;
            }

            .mapboxgl-ctrl-maphubs {
              -moz-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
              -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
              box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
            }

            .mapboxgl-popup {
              z-index: 200 !important;
              height: 200px;
              width: 196px;
            }

            .mapboxgl-popup-content {
              padding: 0 !important;
              background: transparent !important;
              border-radius: 8px !important;
            }

            .mapboxgl-popup-close-button {
              top: -7px !important;
              right: -7px !important;
              z-index: 201 !important;
              background-color: rgba(255, 255, 255, 0.75) !important;
              color: black !important;
              border-radius: 25px !important;
              border: 1px solid black !important;
              width: 14px !important;
              height: 14px !important;
              line-height: 5px !important;
              padding-bottom: 1px !important;
              padding-top: 0px !important;
              padding-left: 0.5px !important;
              padding-right: 0px !important;
            }

            .maphubs-feature-popup {
              padding: 0;
            }

            .mapbox-gl-draw_point,
            .mapbox-gl-draw_line,
            .mapbox-gl-draw_polygon {
              border-bottom: none !important;
              border-right: 1px #ddd solid !important;
            }

            .mapboxgl-ctrl-logo {
              position: absolute !important;
              bottom: -5px !important;
              left: 80px !important;
            }

            .maphubs-inset .mapboxgl-ctrl-logo {
              display: none;
            }
            .mapboxgl-ctrl-top-right {
              top: 45px;
            }

            .mapboxgl-ctrl {
              width: 35px !important;
            }

            .mapboxgl-ctrl-icon {
              width: 35px !important;
              height: 35px !important;
            }

            .mapboxgl-ctrl-group button {
              width: 35px !important;
              height: 35px !important;
            }

            .mapboxgl-ctrl-group {
              border-radius: 8px !important;
            }
            .mapboxgl-ctrl-group button:first-child {
              border-top-right-radius: 8px !important;
            }

            .mapboxgl-ctrl-group:not(:empty) {
              box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12);
            }

            .maphubs-ctrl-scale {
              border: none !important;
              padding: 0 !important;
              background-color: inherit !important;
              position: relative;
              height: 22px;
              position: absolute;
              bottom: 5px;
              right: 5px;
              height: 36px;
              margin: 0px !important;
            }

            .map-position {
              height: 10px;
              max-width: 125px;
              width: 100vw;
              position: absolute;
              top: 0;
              right: 0;
              background-color: rgba(255, 255, 255, 0.55);
              font-size: 10px;
              line-height: 10px;
              text-align: center;
              box-shadow: none !important;
              color: #333;
            }

            .metric-scale {
              height: 10px;
              font-size: 10px;
              line-height: 10px;
              text-align: center;
              box-shadow: none !important;
              background-color: rgba(255, 255, 255, 0.55);
              border-width: medium 2px 2px;
              border-style: none solid solid;
              border-color: #333;
              padding: 0 5px;
              color: #333;
              position: absolute;
              top: 12px;
              right: 0;
            }

            .imperial-scale {
              height: 10px;
              font-size: 10px;
              line-height: 10px;
              text-align: center;
              box-shadow: none !important;
              background-color: rgba(255, 255, 255, 0.55);
              border-width: medium 2px 2px;
              border-style: solid solid none;
              border-color: #333;
              padding: 0 5px;
              color: #333;
              position: absolute;
              bottom: 0;
              right: 0;
            }

            @media (max-width: 350px) {
              .map-position {
                display: none;
              }
              .metric-scale {
                max-width: 100px;
              }
              .imperial-scale {
                max-width: 100px;
              }
            }

            @media (max-width: 280px) {
              .maphubs-inset {
                display: none;
              }
              .map-position {
                display: none;
              }
              .metric-scale {
                max-width: 75px;
              }
              .imperial-scale {
                max-width: 75px;
              }
            }
          `}
        </style>
        {categories && (
          <MapLayerMenu
            categories={categories}
            toggleVisibility={toggleVisibility}
            layers={mapLayers}
          />
        )}
        <div
          id={`map-container-${id}`}
          ref={mapWrapperRef}
          style={{
            width: '100%',
            height: '100%',
            position: 'relative'
          }}
        >
          <div
            id={id}
            className={className}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0
            }}
          />
          <div
            id={`map-overlay-${id}`}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 100,
              pointerEvents: 'none'
            }}
          >
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%'
              }}
            >
              {insetMap && (
                <InsetMap
                  id={id}
                  bottom={showLogo ? '30px' : '25px'}
                  mapboxAccessToken={mapboxAccessToken}
                  preserveDrawingBuffer={preserveDrawingBuffer}
                  {...insetConfig}
                  collapsible={interactionActive}
                />
              )}
              {showMapTools && (
                <MapToolPanel
                  id={id}
                  show={interactionActive && mapLoadedRef.current}
                  gpxLink={gpxLink}
                  onChangeBaseMap={changeBaseMap}
                  darkMode={darkMode}
                  mapboxAccessToken={mapboxAccessToken}
                />
              )}
              {enableMeasurementTools && (
                <div>
                  <div
                    style={{
                      position: 'absolute',
                      top: '55px',
                      right: '55px',
                      backgroundColor: 'rgba(0,0,0,0.6)',
                      color: '#FFF',
                      height: '30px',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      borderRadius: '8px',
                      zIndex: 100,
                      lineHeight: '30px'
                    }}
                  >
                    <span>{measurementMessage}</span>
                  </div>
                  <div id='exit-measurement-button'>
                    <MapToolButton
                      top='300px'
                      right='10px'
                      disabled={
                        !measurementFeatures || measurementFeatures.length === 0
                      }
                      icon={<DownloadOutlined />}
                      onClick={() => {
                        console.log(measurementFeatures)
                        const kml = tokml(
                          {
                            type: 'FeatureCollection',
                            features: measurementFeatures
                          },
                          {
                            name: 'name',
                            description: 'description',
                            simplestyle: false
                          }
                        )
                        const element = document.createElement('a')
                        const file = new Blob([kml], { type: 'text/xml' })
                        element.href = URL.createObjectURL(file)
                        element.download = 'feature.kml'
                        document.body.appendChild(element) // Required for this to work in FireFox
                        element.click()
                      }}
                      tooltipText={t('Download KML')}
                    />
                    <MapToolButton
                      top='340px'
                      right='10px'
                      icon={<CloseOutlined />}
                      onClick={() => {
                        dispatch(setEnableMeasurementTools(false))
                      }}
                      tooltipText={t('Exit Measurement')}
                    />
                  </div>
                </div>
              )}
              {mapLoadedRef.current && children}
              {mapLoadedRef.current && showLogo && (
                <img
                  style={{
                    position: 'absolute',
                    left: '5px',
                    bottom: '2px',
                    zIndex: 1
                  }}
                  width={70}
                  height={19}
                  src='https://cdn-maphubs.b-cdn.net/maphubs/assets/maphubs-logo-small.png'
                  alt='MapHubs Logo'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}

MapHubsMap.defaultProps = {
  id: 'map',
  initialBaseMap: 'default',
  locale: 'en',
  className: '',
  interactive: true,
  showMapTools: true,
  showSearch: true,
  navPosition: 'top-right' as Props['navPosition'],
  showLogo: true,
  insetMap: true,
  showScale: true,
  showFullScreen: true,
  interactionBufferSize: 10,
  hash: true,
  attributionControl: false,
  preserveDrawingBuffer: false,
  allowLayerOrderOptimization: true,
  fitBoundsOptions: {
    animate: false
  },
  mapConfig: {},
  insetConfig: {}
}

export default MapHubsMap
