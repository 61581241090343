import React, { useCallback, useEffect } from 'react'
import { useDispatch } from '../../redux/hooks'
import { FeatureCollection } from 'geojson'
import MapStyles from '../Styles'
import getInteractiveLayers from '../../utils/getInteractiveLayers'
import { setInteractiveLayers } from '../../redux/reducers/mapSlice'
import _bbox from '@turf/bbox'

function useGeoJSONLayer(
  mapRef: React.RefObject<mapboxgl.Map>,
  mapLoadedRef: React.RefObject<boolean>,
  data?: FeatureCollection,
  id?: string
) {
  const dispatch = useDispatch()

  const zoomToData = useCallback(
    (data: FeatureCollection) => {
      const bbox =
        data.bbox && Array.isArray(data.bbox) && data.bbox.length > 0
          ? (data.bbox as Array<number>)
          : _bbox(data)

      if (bbox) {
        let s = bbox[0]
        if (s < -175) s = -175
        let w = bbox[1]
        if (w < -85) w = -85
        let n = bbox[2]
        if (n > 175) n = 175
        let e = bbox[3]
        if (e > 85) e = 85
        const bounds = [
          [s, w],
          [n, e]
        ] as mapboxgl.LngLatBoundsLike
        mapRef.current.fitBounds(bounds, {
          padding: 25,
          curve: 3,
          speed: 0.6,
          maxZoom: 12
        })
      }
    },
    [mapRef]
  )

  const initGeoJSON = useCallback(
    async (data: FeatureCollection): Promise<void> => {
      if (mapRef.current) {
        if (
          data &&
          data.features &&
          Array.isArray(data.features) &&
          data.features.length > 0
        ) {
          mapRef.current.addSource('omh-geojson', {
            type: 'geojson',
            data
          })
          const glStyle = MapStyles.style.defaultStyle(
            9_999_999,
            'geojson',
            null,
            null
          )
          // glStyle.sources["omh-geojson"] = {"type": "geojson", data};
          glStyle.layers.map((layer) => {
            mapRef.current.addLayer(layer)
          })
          const interactiveLayersUpdate = getInteractiveLayers(glStyle)
          dispatch(setInteractiveLayers(interactiveLayersUpdate))

          zoomToData(data)
        } else {
          // empty data
          console.log(`(${id}) Empty/Missing GeoJSON Data`)
        }
      } else {
        console.log(`(${id}) Map not initialized`)
      }
    },
    [dispatch, id, mapRef, zoomToData]
  )

  // update GeoJSON data from props
  useEffect(() => {
    if (data && mapRef.current) {
      const geoJSONData = mapRef.current.getSource(
        'omh-geojson'
      ) as mapboxgl.GeoJSONSource

      if (geoJSONData) {
        console.log(`(${id}) update geoJSON data`)
        // update existing data
        geoJSONData.setData(data)
        zoomToData(data)
      } else if (geoJSONData === undefined && data) {
        // do nothing, still updating from the last prop change...
      } else {
        console.log(`(${id}) init geoJSON data`)

        if (mapLoadedRef.current) {
          initGeoJSON(data)
        } else {
          console.log(`(${id}) Skipping GeoJSON init, map not ready yet`)
        }
      }
    }
  }, [data, id, mapLoadedRef, initGeoJSON, mapRef, zoomToData])

  return { initGeoJSON }
}

export default useGeoJSONLayer
