import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import locale from './reducers/localeSlice'
import layer from './reducers/layerSlice'
import {
  mapSlice,
  baseMapSlice,
  dataEditorSlice,
  mapMakerSlice
} from '@maphubs/map'

export function makeStore() {
  return configureStore({
    reducer: {
      locale,
      layer,
      map: mapSlice,
      baseMap: baseMapSlice,
      mapMaker: mapMakerSlice,
      dataEditor: dataEditorSlice
    }
  })
}

const store = makeStore()

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>

export default store
