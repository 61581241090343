import React from 'react'
export default function LogPile() {
  return (
    <symbol
      id='maphubs-icon-log-pile'
      viewBox='0 0 100 125'
      preserveAspectRatio='xMidYMid meet'
    >
      <path d='M21.2,59.2c-9,0-16.2,7.3-16.2,16.2s7.3,16.2,16.2,16.2s16.2-7.3,16.2-16.2S30.1,59.2,21.2,59.2z M21.2,87.8  c-6.9,0-12.4-5.6-12.4-12.4c0-6.9,5.6-12.4,12.4-12.4s12.4,5.6,12.4,12.4C33.6,82.3,28,87.8,21.2,87.8z' />
      <path d='M50.1,59.2c-9,0-16.2,7.3-16.2,16.2s7.3,16.2,16.2,16.2s16.2-7.3,16.2-16.2S59,59.2,50.1,59.2z M50.1,87.8  c-6.9,0-12.4-5.6-12.4-12.4c0-6.9,5.6-12.4,12.4-12.4s12.4,5.6,12.4,12.4C62.5,82.3,56.9,87.8,50.1,87.8z' />
      <path d='M78.7,59.2c-9,0-16.2,7.3-16.2,16.2s7.3,16.2,16.2,16.2S95,84.4,95,75.4S87.7,59.2,78.7,59.2z M78.7,87.8  c-6.9,0-12.4-5.6-12.4-12.4c0-6.9,5.6-12.4,12.4-12.4c6.9,0,12.4,5.6,12.4,12.4C91.2,82.3,85.6,87.8,78.7,87.8z' />
      <path d='M64.5,34.5c-9,0-16.2,7.3-16.2,16.2s7.3,16.2,16.2,16.2s16.2-7.3,16.2-16.2S73.5,34.5,64.5,34.5z M64.5,63.1  c-6.9,0-12.4-5.6-12.4-12.4s5.6-12.4,12.4-12.4S77,43.8,77,50.7S71.4,63.1,64.5,63.1z' />
      <path d='M50.1,9.4c-9,0-16.2,7.3-16.2,16.2s7.3,16.2,16.2,16.2s16.2-7.3,16.2-16.2S59,9.4,50.1,9.4z M50.1,38  c-6.9,0-12.4-5.6-12.4-12.4s5.6-12.4,12.4-12.4s12.4,5.6,12.4,12.4S56.9,38,50.1,38z' />
      <path d='M35.8,34.5c-9,0-16.2,7.3-16.2,16.2s7.3,16.2,16.2,16.2S52,59.6,52,50.7S44.7,34.5,35.8,34.5z M35.8,63.1  c-6.9,0-12.4-5.6-12.4-12.4s5.6-12.4,12.4-12.4s12.4,5.6,12.4,12.4S42.6,63.1,35.8,63.1z' />
    </symbol>
  )
}