import React from 'react'
import type { BaseMapOption } from '../../redux/reducers/baseMapSlice'
import { Row, Col, Avatar } from 'antd'
import useMapT from '../../hooks/useMapT'
import { useSelector } from '../../redux/hooks'

// import icons individually for better dev build performance
import GlobalOutlined from '@ant-design/icons/GlobalOutlined'

type Props = {
  onChange: (baseMap: string) => void
  darkMode?: boolean
}
const BaseMapSelection = ({ onChange, darkMode }: Props): JSX.Element => {
  const { t } = useMapT()

  const baseMap = useSelector((state) => state.baseMap.baseMap)
  const baseMapOptions = useSelector((state) => state.baseMap.baseMapOptions)

  const selectedColor = darkMode ? '#fff' : '#000'
  const unselectedColor = darkMode ? '#fff' : '#000'
  const selectedBackgroundColor = darkMode ? '#000' : '#fff'
  const unselectedBackgroundColor = darkMode ? '#444' : '#eee'

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        textAlign: 'left',
        padding: '0px 10px'
      }}
    >
      {baseMapOptions.map((baseMapOption: BaseMapOption) => {
        const selected = baseMap === baseMapOption.value
        return (
          <div
            key={baseMapOption.value}
            style={{
              border: selected ? '1px solid #323333' : '1px solid #d9d9d9',
              borderRadius: '8px',
              color: selected ? selectedColor : unselectedColor,
              backgroundColor: selected
                ? selectedBackgroundColor
                : unselectedBackgroundColor,
              marginBottom: '10px',
              padding: '2px',
              cursor: !selected ? 'pointer' : 'inherit',
              width: '250px'
            }}
            onClick={() => {
              if (!selected) onChange(baseMapOption.value)
            }}
          >
            <Row align='middle'>
              <div style={{ float: 'left', width: '64px' }}>
                {baseMapOption.icon && (
                  <Avatar shape='square' size={64} src={baseMapOption.icon} />
                )}
                {!baseMapOption.icon && (
                  <Avatar shape='square' size={64} icon={<GlobalOutlined />} />
                )}
              </div>
              <div
                style={{
                  paddingLeft: '5px',
                  width: 'calc(100% - 70px)'
                }}
              >
                <p
                  style={{
                    marginBottom: '0.5em'
                  }}
                >
                  <b>{t(baseMapOption.label)}</b>
                </p>
                <p
                  style={{
                    fontSize: '12px'
                  }}
                >
                  <span
                    className='no-margin no-padding'
                    dangerouslySetInnerHTML={{
                      __html: baseMapOption.attribution
                    }}
                  />
                </p>
              </div>
            </Row>
          </div>
        )
      })}
    </div>
  )
}
export default BaseMapSelection
