import React from 'react'
import { Input, Tabs } from 'antd'
import { LocalizedString } from '@maphubs/mhtypes'
import { InputProps, TextAreaProps } from 'antd/lib/input'
import { localeUtil } from '@maphubs/locales'

const supportedLangs = localeUtil.getSupported()
let languagesFromConfig
const langs = []

if (process.env.NEXT_PUBLIC_LANGUAGES) {
  languagesFromConfig = process.env.NEXT_PUBLIC_LANGUAGES.split(',')
  languagesFromConfig = languagesFromConfig.map((lang) => lang.trim())
  supportedLangs.map((lang) => {
    if (languagesFromConfig.includes(lang.value)) {
      langs.push(lang.value)
    }
  })
}

type Props = Omit<InputProps, 'value' | 'defaultValue' | 'onChange'> &
  Omit<TextAreaProps, 'value' | 'defaultValue' | 'onChange'> & {
    value?: LocalizedString
    defaultValue?: LocalizedString
    onChange?: (val: LocalizedString) => void
  }

const { TextArea } = Input

const LocalizedInput = (props: Props): JSX.Element => {
  const { value, defaultValue, onChange, ...rest } = props
  return (
    <>
      <style jsx>
        {`
          .localized-input {
            padding-bottom: 0px;
            width: 100%;
          }
        `}
      </style>
      <div className='localized-input'>
        <Tabs
          animated={false}
          size='small'
          type='card'
          items={langs.map((lang) => {
            return {
              key: lang,
              label: lang,
              children: (
                <TextArea
                  {...rest}
                  value={value?.[lang]}
                  defaultValue={defaultValue?.[lang]}
                  onChange={(e) => {
                    const val = e.target.value
                    const changedValue = {}
                    changedValue[lang] = val
                    const valueUpdate = Object.assign({}, value, changedValue)

                    if (onChange) {
                      props.onChange(valueUpdate)
                    }
                  }}
                />
              )
            }
          })}
        />
      </div>
    </>
  )
}
export default LocalizedInput
