import React from 'react'
import type { Layer, LocalizedString } from '@maphubs/mhtypes'
import flow from 'lodash.flow'
import MapStyles from './Styles'
import { Popconfirm, Switch, Row, Col, Tooltip } from 'antd'
import { DragSource, DropTarget } from 'react-dnd'
import DraggleIndicator from './UI/DraggableIndicator'
import DragItemConfig from './UI/DragItemConfig'

import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import BgColorsOutlined from '@ant-design/icons/BgColorsOutlined'
import InfoOutlined from '@ant-design/icons/InfoOutlined'
import EditOutlined from '@ant-design/icons/EditOutlined'

type Props = {
  item: Layer
  showVisibility?: boolean
  showRemove: boolean
  showDesign: boolean
  showEdit: boolean
  showInfo: boolean
  toggleVisibility: (layer_id: number) => void
  removeFromMap: (layer: Layer) => void
  showLayerDesigner: (layer_id: number) => void
  editLayer: (layer: Layer) => void
  isDragging: boolean
  connectDragSource: (source: JSX.Element) => JSX.Element
  connectDropTarget: (target: JSX.Element) => JSX.Element
  t: (v: string | LocalizedString) => string
}

class LayerListItem extends React.Component<Props> {
  props: Props

  editLayer = () => {
    this.props.editLayer(this.props.item)
  }
  removeFromMap = () => {
    this.props.removeFromMap(this.props.item)
  }
  showLayerDesigner = () => {
    this.props.showLayerDesigner(this.props.item.layer_id)
  }
  toggleVisibility = () => {
    this.props.toggleVisibility(this.props.item.layer_id)
  }

  render() {
    const {
      t,
      isDragging,
      connectDragSource,
      connectDropTarget,
      showVisibility,
      showRemove,
      showDesign,
      showInfo,
      item,
      showEdit
    } = this.props
    const layer: Layer = item
    const layer_id = layer.layer_id ? layer.layer_id : 0
    const canEdit =
      showEdit && layer.canEdit && !layer.remote && !layer.is_external
    const active = MapStyles.settings.get(layer.style, 'active')
    return connectDragSource(
      connectDropTarget(
        <div
          style={{
            opacity: isDragging ? 0.75 : 1,
            border: '1px solid #ddd',
            borderRadius: '8px',
            height: '50px',
            width: '100%',
            padding: '5px 5px',
            position: 'relative'
          }}
        >
          <Row>
            <b
              className='truncate'
              style={{
                fontSize: '12px'
              }}
            >
              {t(layer.name)}
            </b>
          </Row>
          <Row>
            <p
              className='truncate no-margin'
              style={{
                fontSize: '8px',
                lineHeight: '10px',
                padding: '0px 0px 2px 0px'
              }}
            >
              {t(layer.source)}
            </p>
          </Row>
          <Row
            style={{
              textAlign: 'center'
            }}
            justify='end'
          >
            {showInfo && (
              <Col span={4}>
                <Tooltip title={t('Layer Info')} placement='right'>
                  <a
                    href={'/lyr/' + layer_id}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <InfoOutlined
                      style={{
                        fontSize: '16px'
                      }}
                    />
                  </a>
                </Tooltip>
              </Col>
            )}
            {showRemove && (
              <Col span={4}>
                <Tooltip title={t('Remove from Map')} placement='top'>
                  <Popconfirm
                    title={t('Remove Layer') + ' ' + t(layer.name)}
                    onConfirm={this.removeFromMap}
                    onCancel={() => {
                      // do nothing
                    }}
                    okText={t('Remove')}
                    cancelText={t('Cancel')}
                  >
                    <a href='#'>
                      <DeleteOutlined
                        style={{
                          fontSize: '16px'
                        }}
                      />
                    </a>
                  </Popconfirm>
                </Tooltip>
              </Col>
            )}
            {showDesign && (
              <Col span={4}>
                <Tooltip title={t('Edit Layer Style')} placement='top'>
                  <a onClick={this.showLayerDesigner}>
                    <BgColorsOutlined
                      style={{
                        fontSize: '16px'
                      }}
                    />
                  </a>
                </Tooltip>
              </Col>
            )}
            {canEdit && (
              <Col span={4}>
                <Tooltip title={t('Edit Layer Data')} placement='top'>
                  <a onClick={this.editLayer}>
                    <EditOutlined
                      style={{
                        fontSize: '16px'
                      }}
                    />
                  </a>
                </Tooltip>
              </Col>
            )}
            {showVisibility && (
              <Col span={4}>
                <Tooltip title={t('Show/Hide Layer')} placement='right'>
                  <Switch
                    size='small'
                    style={{
                      marginBottom: '5px'
                    }}
                    checked={active}
                    onChange={this.toggleVisibility}
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
          <div
            className='draggable-indicator'
            style={{
              width: '8px',
              height: '50px',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto'
            }}
          >
            <DraggleIndicator numX={2} numY={16} width={12} height={64} />
          </div>
        </div>
      )
    )
  }
}

export default flow(
  DropTarget('layer', DragItemConfig.dropTargetConfig, DragItemConfig.connect),
  DragSource('layer', DragItemConfig.dragSourceConfig, DragItemConfig.collect)
)(LayerListItem)
