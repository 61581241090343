import React, { useState } from 'react'
import BaseMapSelection from './ToolPanels/BaseMapSelection'
import MeasurementToolPanel from './ToolPanels/MeasurementToolPanel'
import CoordinatePanel from './ToolPanels/CoordinatePanel'
import { FloatButton, Popover } from 'antd'
import useMapT from '../hooks/useMapT'
import AimOutlined from '@ant-design/icons/AimOutlined'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import GatewayOutlined from '@ant-design/icons/GatewayOutlined'
import GlobalOutlined from '@ant-design/icons/GlobalOutlined'
import CameraOutlined from '@ant-design/icons/CameraOutlined'
import MapSearchPanel from './Search/MapSearchPanel'
import getScreenshot from '../MapMaker/util/get-map-screenshot'

type Props = {
  id?: string
  show?: boolean
  gpxLink?: string
  onChangeBaseMap: (...args: Array<any>) => any
  darkMode?: boolean
  mapboxAccessToken: string
}

const MapToolPanel = ({
  id,
  mapName,
  onChangeBaseMap,
  darkMode,
  mapboxAccessToken
}: Props): JSX.Element => {
  const { t } = useMapT()
  const [openSearch, setOpenSearch] = useState(false)

  const iconStyle = {
    transform: 'rotate(270deg)'
  }

  const wrapperRef = React.createRef<HTMLDivElement>()

  return (
    <>
      <div ref={wrapperRef} id='map-tools' style={{ position: 'relative' }}>
        <FloatButton.Group
          shape='square'
          style={{
            right: 10,
            top: 10,
            position: 'absolute',
            transform: 'rotate(90deg)',
            pointerEvents: 'all'
          }}
        >
          <FloatButton
            icon={<SearchOutlined style={iconStyle} />}
            onClick={() => {
              setOpenSearch(true)
            }}
            tooltip={t('Search')}
          />

          <FloatButton
            icon={<CameraOutlined style={iconStyle} />}
            onClick={() => {
              getScreenshot(id, { download: true, fileName: 'map' })
            }}
            tooltip={t('Download Map Screenshot')}
          />

          <Popover
            placement='bottom'
            getPopupContainer={() => wrapperRef.current}
            overlayInnerStyle={{
              pointerEvents: 'all'
            }}
            content={<CoordinatePanel />}
            trigger='click'
          >
            <FloatButton
              icon={<AimOutlined style={iconStyle} />}
              tooltip={t('Find Coordinates')}
            />
          </Popover>

          <Popover
            placement='bottom'
            getPopupContainer={() => wrapperRef.current}
            overlayInnerStyle={{
              pointerEvents: 'all'
            }}
            content={<MeasurementToolPanel />}
            trigger='click'
          >
            <FloatButton
              icon={<GatewayOutlined style={iconStyle} />}
              tooltip={t('Measurement Tools')}
            />
          </Popover>
          <Popover
            placement='bottom'
            getPopupContainer={() => wrapperRef.current}
            overlayInnerStyle={{
              backgroundColor: darkMode ? '#212121' : '#fff',
              pointerEvents: 'all'
            }}
            content={
              <div style={{ height: '400px', width: '300px' }}>
                <BaseMapSelection
                  onChange={(val: string) => {
                    onChangeBaseMap(val)
                  }}
                  darkMode={darkMode}
                />
              </div>
            }
            trigger='click'
          >
            <FloatButton
              icon={<GlobalOutlined style={iconStyle} />}
              tooltip={t('Change Base Map')}
            />
          </Popover>
        </FloatButton.Group>
      </div>
      <MapSearchPanel
        open={openSearch}
        onClose={() => {
          setOpenSearch(false)
        }}
        mapboxAccessToken={mapboxAccessToken}
      />
    </>
  )
}
export default MapToolPanel
