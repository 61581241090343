import React from 'react'
export default function Farmer() {
  return (
    <symbol
      id='maphubs-icon-farmer'
      viewBox='0 0 169.917 321.99375000000003'
      preserveAspectRatio='xMidYMid meet'
    >
      <g>
        <path d='M87.571,38.513c2.079-11.416,12.09-20.101,24.097-20.101c12.006,0,22.017,8.685,24.096,20.101   c20.142-1.733,34.153-5.568,34.153-10.025c0-5.103-18.362-15.919-43.273-19.271C126.406,4.103,119.8,0,111.667,0   c-8.133,0-14.739,4.103-14.978,9.217c-17.916,2.411-32.443,8.682-39.19,13.913v9.407C63.053,35.19,73.893,37.336,87.571,38.513z' />
        <path d='M19.128,101.597c-3.996,1.885-5.707,6.653-3.822,10.649c1.364,2.894,4.24,4.59,7.241,4.59c0.067,0,0.135-0.009,0.203-0.011   V99.898C21.546,100.46,20.339,101.025,19.128,101.597z' />
        <path d='M73.076,95.227c4.461-1.858,8.356-3.451,11.546-4.741v154.746c0,6.83,5.537,12.363,12.363,12.363   c6.828,0,12.365-5.533,12.365-12.363V154.96h4.635v90.271c0,6.83,5.537,12.363,12.363,12.363c6.828,0,12.365-5.533,12.365-12.363   V95.482c0.593,1.216,1.191,2.611,1.781,4.238c2.819,7.813,5.189,20.63,5.18,40.638c0,5.516-0.177,11.574-0.565,18.224   c-0.257,4.411,3.109,8.195,7.52,8.452c0.159,0.009,0.318,0.014,0.475,0.014c4.205,0,7.729-3.283,7.979-7.535   c0.404-6.933,0.591-13.299,0.591-19.156c-0.04-30.182-4.861-46.887-11.434-56.932c-3.285-4.986-7.128-8.183-10.68-9.955   c-2.319-1.169-4.445-1.72-6.14-1.964c-0.766-0.254-1.583-0.398-2.436-0.398H92.351c-0.681,0-1.338,0.097-1.967,0.262   c-0.774,0.056-1.554,0.225-2.316,0.521v-0.002c-0.09,0.036-25.279,9.792-55.317,23.409v17.597   C47.813,105.939,62.108,99.798,73.076,95.227z' />
        <circle cx='111.668' cy='42.913' r='22.5' />
        <path d='M24.75,255.5h6v-184H48.5c3.867-0.002,6.997-3.133,6.999-7.001V18.834H49.5v45.665c-0.002,0.55-0.45,1-1,1H30.75V18.833h-6   v46.666H6.999c-0.55,0-1-0.45-1-1V18.834H0v45.665c0.002,3.868,3.132,6.999,6.999,7.001h17.75V255.5z' />
      </g>
    </symbol>
  )
}