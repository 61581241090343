import React, { useState, useEffect, useCallback } from 'react'
import LegendItem from './LegendItem'
import MapStyles from './Styles'
import { Row, Col } from 'antd'
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined'
import CaretUpOutlined from '@ant-design/icons/CaretUpOutlined'
import { Layer, LocalizedString } from '@maphubs/mhtypes'
import useMapT from '../hooks/useMapT'
import { useSelector } from '../redux/hooks'
import { klona } from 'klona/json'

type Props = {
  title?: LocalizedString
  layers: Layer[]
  hideInactive: boolean
  collapsible: boolean
  showLayersButton: boolean
  openLayersPanel?: () => void
  maxHeight: string
  style: React.CSSProperties
  darkMode?: boolean
}

const MiniLegend = ({
  title,
  layers,
  showLayersButton,
  hideInactive,
  collapsible,
  maxHeight,
  style,
  openLayersPanel,
  darkMode
}: Props): JSX.Element => {
  const { t } = useMapT()
  const [collapsed, setCollapsed] = useState(false)
  const [localLayers, setLocalLayers] = useState<Layer[]>([])

  useEffect(() => {
    setLocalLayers(klona(layers))
  }, [layers])

  const baseMapAttribution = useSelector((state) => state.baseMap.attribution)

  const [height, setHeight] = useState({
    contentHeight: `calc(${maxHeight} - 38px)`,
    legendHeight: maxHeight
  })

  useEffect(() => {
    if (collapsed) {
      console.log('toggle enabled')
      setHeight({
        contentHeight: '0px',
        legendHeight: '0px'
      })
    } else {
      console.log('toggle disabled')
      setHeight({
        contentHeight: `calc(${maxHeight} - 38px)`,
        legendHeight: maxHeight
      })
    }
  }, [collapsed, maxHeight])

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  const { legendHeight, contentHeight } = height

  let layersButton = <></>

  if (showLayersButton) {
    layersButton = (
      <a
        href='#'
        onMouseDown={() => {
          if (openLayersPanel) openLayersPanel()
        }}
        style={{
          position: 'absolute',
          right: '24px',
          display: 'table-cell',
          height: '38px',
          zIndex: 100,
          lineHeight: '38px'
        }}
      >
        <SettingOutlined
          style={{
            color: darkMode ? '#fff' : '#000',
            textAlign: 'center',
            fontSize: '14px',
            verticalAlign: 'middle'
          }}
        />
      </a>
    )
  }

  let titleText = ''
  let titleFontSize = '15px'

  if (title) {
    titleText = t(title)

    if (titleText) {
      if (titleText.length > 80) {
        titleFontSize = '8px'
      } else if (titleText.length > 60) {
        titleFontSize = '11px'
      } else if (titleText.length > 40) {
        titleFontSize = '13px'
      }
    } else {
      // if localized text is empty
      titleText = t('Legend')
    }
  } else {
    titleText = t('Legend')
  }

  let titleDisplay

  if (collapsible) {
    const iconStyle = {
      marginRight: '6px',
      height: '100%',
      lineHeight: '38px',
      verticalAlign: 'middle'
    }
    titleDisplay = (
      <Row
        style={{
          height: '38px',
          width: '100%'
        }}
      >
        <Col
          span={20}
          style={{
            height: '38px'
          }}
        >
          <h6
            className='word-wrap'
            style={{
              padding: '0.2rem',
              marginLeft: '2px',
              marginTop: '5px',
              marginBottom: '2px',
              fontWeight: 500,
              fontSize: titleFontSize
            }}
          >
            {titleText}
          </h6>
        </Col>
        <Col span={4} id='legend-tool-buttons'>
          {layersButton}
          <span
            style={{
              float: 'right',
              display: 'table-cell',
              height: '38px',
              zIndex: 100,
              lineHeight: '38px'
            }}
          >
            {collapsed && <CaretDownOutlined style={iconStyle} />}
            {!collapsed && <CaretUpOutlined style={iconStyle} />}
          </span>
        </Col>
      </Row>
    )
  } else {
    titleDisplay = (
      <Row
        style={{
          height: '38px',
          width: '100%'
        }}
      >
        <h6
          style={{
            padding: '0.2rem',
            marginLeft: '2px',
            marginBottom: '2px',
            marginTop: '5px',
            fontWeight: 500,
            fontSize: titleFontSize
          }}
        >
          {titleText}
        </h6>
        <Col span={4} className='valign'>
          {layersButton}
        </Col>
      </Row>
    )
  }

  let allowScroll = true

  if (collapsed || layers.length === 1) {
    allowScroll = false
  }

  // var style = this.props.style;
  // style.height = '9999px'; //needed for the flex box to work correctly
  return (
    <div style={style}>
      <style jsx global>
        {`
          .omh-legend {
            padding-left: 2px;
            padding-right: 2px;
            padding-top: 2px;
            padding-bottom: 4px;
            min-height: 20px;
          }

          .omh-legend h3 {
            font-size: 10px;
            margin: 0px;
          }

          .omh-legend .block {
            height: 15px;
            width: 20px;
            float: left;
            margin-right: 5px;
            border: 1px solid #888;
          }

          .omh-legend .point {
            height: 15px;
            width: 15px;
            float: left;
            margin-right: 5px;
            border-radius: 50%;
            border: 1px solid #888;
          }

          .omh-legend .double-stroke {
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
          }

          .word-wrap {
            overflow-wrap: break-word;
            -ms-word-break: break-all;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
          }

          .collapsible-header {
            display: flex;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            line-height: 1.5;
            padding: 1rem;
            border-bottom: 1px solid #ddd;
          }
        `}
      </style>
      <div
        style={{
          zIndex: 1,
          textAlign: 'left',
          margin: 0,
          position: 'absolute',
          height: legendHeight,
          width: '100%',
          boxShadow: 'none',
          pointerEvents: 'none',
          border: 'none',
          paddingLeft: '0px',
          listStyleType: 'none',
          borderRadius: '8px'
        }}
      >
        <div
          className='z-depth-1 active'
          style={{
            color: darkMode ? '#FFF' : '#000',
            backgroundColor: darkMode ? 'rgba(0,0,0,0.75)' : '#FFF',
            height: 'auto',
            pointerEvents: 'auto',
            borderTop: darkMode ? 'none' : '1px solid #ddd',
            borderRight: darkMode ? 'none' : '1px solid #ddd',
            borderLeft: darkMode ? 'none' : '1px solid #ddd',
            borderRadius: '8px'
          }}
        >
          <div
            className='collapsible-header no-padding'
            style={{
              height: '38px',
              minHeight: '38px',
              borderRadius: '8px'
            }}
            onClick={toggleCollapsed}
          >
            {titleDisplay}
          </div>
          <div
            style={{
              display: collapsed ? 'none' : 'flex',
              maxHeight: contentHeight,
              flexDirection: 'column',
              borderBottom: 'none'
            }}
          >
            <div
              className='no-margin'
              style={{
                overflowX: 'hidden',
                overflowY: allowScroll ? 'auto' : 'hidden',
                height: '100%',
                minHeight: '50px',
                zIndex: 999,
                padding: '5px'
              }}
            >
              {localLayers.map((layer) => {
                let active = MapStyles.settings.get(layer.style, 'active')

                if (typeof active === 'undefined') {
                  layer.style = MapStyles.settings.set(
                    layer.style,
                    'active',
                    true
                  )
                  active = true
                }

                if (hideInactive && !active) {
                  return null
                }

                return <LegendItem key={layer.layer_id} layer={layer} t={t} />
              })}
              <div>
                <span
                  style={{
                    fontSize: '8px',
                    lineHeight: '10px'
                  }}
                >
                  {t('Base Map')} -{' '}
                  <span
                    className='no-margin no-padding'
                    dangerouslySetInnerHTML={{
                      __html: baseMapAttribution
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
MiniLegend.defaultProps = {
  layers: [],
  hideInactive: true,
  collapsible: true,
  showLayersButton: true,
  maxHeight: '100%',
  style: {}
}
export default MiniLegend
