export { default as getAPIUser } from './get-api-user'

// pass through needed next-auth imports so we don't have to install it in the apps

export {
  SessionProvider,
  signIn,
  signOut,
  useSession,
  getSession
} from 'next-auth/react'

// types
export type { JWT } from 'next-auth/jwt'
export type { Session } from 'next-auth'
