import React from 'react'
import { FloatButton } from 'antd'

type Props = {
  id?: string
  icon: React.Node
  top: string
  right: string
  bottom: string
  left: string
  tooltipText?: string
  color: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLElement>) => void
  show: boolean
  disabled?: boolean
}
const MapToolButton = ({
  id,
  show,
  top,
  right,
  bottom,
  left,
  icon,
  disabled,
  tooltipText,
  onClick,
  onMouseDown
}: Props): JSX.Element => {
  return show ? (
    <FloatButton
      shape='square'
      icon={icon}
      tooltip={tooltipText}
      onClick={(e) => {
        if (disabled) return
        if (onClick) onClick(e)
        if (onMouseDown) onMouseDown(e)
      }}
      style={{
        position: 'absolute',
        top,
        right,
        bottom,
        left,
        zIndex: 1,
        pointerEvents: 'all'
      }}
    />
  ) : null
}
MapToolButton.defaultProps = {
  top: '10px',
  color: '#323333',
  right: '10px',
  bottom: 'auto',
  left: 'auto',
  show: true,
  tooltipPosition: 'bottom'
}
export default MapToolButton
