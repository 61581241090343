import React from 'react'
export default function HandOne() {
  return (
    <symbol
      id='maphubs-icon-hand-one'
      viewBox='0 0 5000000 6250000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g>
        <path
          fill='black'
          d='M2123689 843452c-58112,0 -110909,23859 -149296,62128 -38269,38387 -62128,91184 -62128,149296l0 1854979 0 0c-118,11457 -4843,23033 -14056,31301 -17362,15354 -43820,13819 -59175,-3426 -11221,-12638 -22205,-24331 -32953,-35080l-2127 -2126c-97798,-97798 -212014,-165359 -329065,-183194 -112681,-17009 -229495,12520 -338042,107011l1019087 1019087c199376,199376 459581,307451 719786,312884 251347,5315 502812,-85278 699353,-281702 288553,-288670 288316,-644311 287962,-1025111l0 -34371 -118 0 0 -565530c0,-58112 -23859,-110909 -62128,-149296 -38387,-38269 -91184,-62128 -149296,-62128 -58112,0 -111027,23859 -149296,62128 -38387,38387 -62128,91184 -62128,149296l0 151659c0,23150 -18780,41930 -42049,41930l-2480 0c-23150,0 -41930,-18780 -41930,-41930l0 -312766c0,-58112 -23741,-110909 -62128,-149296 -38269,-38387 -91184,-62128 -149296,-62128 -58112,0 -110909,23741 -149296,62128 -38269,38387 -62128,91184 -62128,149296l0 312766c0,23150 -18781,41930 -41931,41930l-2480 0c-23151,0 -41931,-18780 -41931,-41930l0 -453440c0,-58112 -23859,-111027 -62128,-149296 -38387,-38387 -91184,-62128 -149296,-62128 -58112,0 -111027,23741 -149296,62128 -38387,38269 -62128,91184 -62128,149296l0 453440c0,23150 -18780,41930 -42048,41930l-2481 0c-23150,0 -41930,-18780 -41930,-41930l0 -1346381c0,-58112 -23741,-110909 -62128,-149296 -38269,-38269 -91184,-62128 -149296,-62128z'
        />
      </g>
    </symbol>
  )
}