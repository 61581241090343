import React, { useState } from 'react'
import { Row, Select, Form } from 'antd'
import { SwitchWithLabels } from '@maphubs/forms'
import MapStyles from '../Map/Styles'
import { Layer, Labels, LocalizedString } from '@maphubs/mhtypes'
import { useEffect } from 'react'

type Props = {
  onChange: (style: mapboxgl.Style, values: Labels) => void
  layer: Layer
  initialStyle: mapboxgl.Style
  labels: Labels
  t: (val: string | LocalizedString) => string
}

const LabelSettings = ({
  labels,
  initialStyle,
  layer,
  onChange,
  t
}: Props): JSX.Element => {
  const [style, setStyle] = useState(initialStyle)

  // allow external style change, like from a different layer setting like Markers
  useEffect(() => {
    setStyle(initialStyle)
  }, [initialStyle])

  const onFormChange = (changes: Labels): void => {
    let styleUpdate

    const values = Object.assign({}, labels, changes)

    if (values.enabled && values.field) {
      // add labels to style
      styleUpdate = MapStyles.labels.addStyleLabels(
        style,
        values.field,
        layer.layer_id,
        layer.shortid,
        layer.data_type
      )
      setStyle(styleUpdate)
      onChange(styleUpdate, values)
    } else {
      // remove labels from style
      styleUpdate = MapStyles.labels.removeStyleLabels(style)
      setStyle(styleUpdate)
      onChange(styleUpdate, values)
    }
  }

  const fieldOptions = []
  let presets

  if (layer.style && layer.style.sources) {
    const sourceKeys = Object.keys(layer.style.sources)

    if (sourceKeys.length > 0) {
      const firstSource = Object.keys(layer.style.sources)[0]
      presets = MapStyles.settings.getSourceSetting(
        style,
        firstSource,
        'presets'
      )
    }
  }

  if (presets) {
    for (const preset of presets) {
      fieldOptions.push({
        value: preset.tag,
        label: t(preset.label)
      })
    }
  } else {
    return (
      <Row
        style={{
          marginBottom: '20px'
        }}
      >
        <p>{t('Not available for this layer')}</p>
      </Row>
    )
  }

  return (
    <Row
      style={{
        marginBottom: '20px'
      }}
    >
      <Form
        onValuesChange={onFormChange}
        initialValues={labels}
        layout='vertical'
      >
        <Form.Item name='enabled' label={t('Enable Labels')}>
          <SwitchWithLabels labelOff={t('Off')} labelOn={t('On')} />
        </Form.Item>

        <Form.Item
          name='field'
          rules={[{ required: true }]}
          label={t('Label Field')}
          tooltip={t('Data field to use in map labels.')}
        >
          <Select options={fieldOptions} style={{ width: '200px' }} />
        </Form.Item>
      </Form>
    </Row>
  )
}
export default LabelSettings
