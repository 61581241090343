import React, { useEffect, useState } from 'react'
import { Form, Alert } from 'antd'
import FormField from './FormField'
import { LocalizedString, MapHubsField } from '@maphubs/mhtypes'
import _debounce from 'lodash.debounce'

type Props = {
  id: string
  presets: MapHubsField[]
  values?: Record<string, unknown>
  showSubmit?: boolean
  onChange: (model: Record<string, unknown>) => void
  style?: React.CSSProperties
  t: (v: string | LocalizedString) => string //* used by both Maps MapHubs UI so needs to be passed t()
}

const setAlert = async (setHasError, value) => {
  setHasError(value)
}

// debounced update
const debouncedSetAlert = _debounce(setAlert, 1500)

const DataCollectionForm = ({
  id,
  style,
  presets,
  values,
  onChange,
  t
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [hasError, setHasError] = useState(false)
  useEffect(() => {
    form.resetFields()
  }, [id, form])
  return (
    <div style={style}>
      {hasError && (
        <Alert
          message='Please fix errors to save the data '
          type='error'
          showIcon
        />
      )}

      <Form
        form={form}
        layout='vertical'
        onValuesChange={async () => {
          await form.validateFields().catch(() => {
            // do nothing
          })
          const fieldErrors = form.getFieldsError(presets.map((p) => p.tag))
          let errors = []
          for (const field of fieldErrors) {
            if (field.errors.length > 0) {
              errors = [...errors, ...field.errors]
            }
          }
          if (errors.length > 0) {
            debouncedSetAlert(setHasError, true)

            return
          } else {
            debouncedSetAlert(setHasError, false)
            const model = form.getFieldsValue(true)
            const data = Object.assign({}, values, model)
            onChange(data)
          }
        }}
        initialValues={values}
      >
        {presets.map((preset) => {
          return <FormField key={preset.tag} preset={preset} t={t} />
        })}
      </Form>
      <span style={{ fontSize: '10px' }}>ID: {id}</span>
    </div>
  )
}

export default DataCollectionForm
