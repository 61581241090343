import React from 'react'
export default function Star() {
  return (
    <symbol
      id='maphubs-icon-star'
      viewBox='0 0 100 125'
      preserveAspectRatio='xMidYMid meet'
    >
      <g>
        <polygon points='50,77.4 23.7,91.2 28.8,62 7.5,41.3 36.9,37 50,10.4 63.1,37 92.5,41.3 71.2,62 76.3,91.2  ' />
      </g>
    </symbol>
  )
}