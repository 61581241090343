import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext
} from 'react'
import _find from 'lodash.find'
import { Drawer, Button, Row, Col, Tabs, Modal, Tooltip, App } from 'antd'

// import icons individually for better dev build performance
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import DownloadOutlined from '@ant-design/icons/DownloadOutlined'

import Map from '../Map/Map'
import MiniLegend from '../Map/MiniLegend'
import LayerList from '../Map/LayerList'
import SaveMapModal from './SaveMapModal'
import MapSettingsPanel from './MapSettingsPanel'
import EditLayerPanel from './EditLayerPanel'
import MapLayerDesigner from '../LayerDesigner/MapLayerDesigner'
import EditorToolButtons from './EditorToolButtons'

import type {
  Layer,
  LocalizedString,
  MapPosition,
  Group
} from '@maphubs/mhtypes'
import BaseMapSelection from '../Map/ToolPanels/BaseMapSelection'
import slugify from 'slugify'
import useMapT from '../hooks/useMapT'

import { useDispatch, useSelector } from '../redux/hooks'
import {
  initMapMaker,
  removeFromMap,
  setMapLayers,
  createMap,
  saveMap,
  toggleVisibility,
  updateLayerStyle,
  addToMap,
  setMapPosition
} from '../redux/reducers/mapMakerSlice'
import { setEnableMeasurementTools } from '../redux/reducers/mapSlice'
import { MapContext } from '../MapContext'
import {
  startEditing,
  stopEditing,
  updateFeatures,
  createFeature,
  deleteFeature,
  selectFeatureThunk,
  clearSelectedFeature
} from '../redux/reducers/dataEditorSlice'
import { setBaseMapThunk } from '../redux/reducers/baseMapSlice'
import { reloadStyleThunk } from '../redux/reducers/map/reloadStyleThunk'
import { setBaseMapStyleThunk } from '../redux/reducers/map/setBaseMapStyleThunk'

import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { klona } from 'klona/json'
import { setOverlayStyleThunk } from '../redux/reducers/map/setOverlayStyleThunk'
import { Feature, FeatureCollection } from 'geojson'

import getScreenshot from './util/get-map-screenshot'
import PlusOutlined from '@ant-design/icons/PlusOutlined'

const { confirm } = Modal

type Props = {
  edit?: boolean
  mapLayers: Array<Layer>
  showVisibility?: boolean
  onCreate?: ({
    group_id,
    layers,
    style,
    settings,
    title,
    position,
    basemap,
    screenshot
  }: {
    group_id: string
    layers: Layer[]
    style: mapboxgl.Style
    settings: Record<string, unknown>
    title: LocalizedString
    position: MapPosition
    basemap: string
    screenshot: string
  }) => Promise<number>
  onSave: ({
    map_id,
    layers,
    style,
    settings,
    title,
    position,
    basemap,
    screenshot
  }: {
    map_id: number
    layers: Layer[]
    style: mapboxgl.Style
    settings: Record<string, unknown>
    title: LocalizedString
    position: MapPosition
    basemap: string
    screenshot: string
  }) => Promise<boolean>
  onDelete: (map_id: number) => Promise<boolean>
  title?: LocalizedString
  position?: Record<string, any>
  basemap?: string
  map_id?: number
  owned_by_group_id?: string
  editLayer?: Layer
  mapConfig: Record<string, any>
  settings: Record<string, any>
  groups: Group[]
  locale: string
  darkMode?: boolean
  loggedIn?: boolean
  themePrimaryColor?: string
  getFeatureCallback?: (mhid: string) => Promise<FeatureCollection>
  addLayers: (cb: (layer: []) => void, currentLayers: Layer[]) => void
  bunnyNetKey: string
}

const MapMaker = (props: Props): JSX.Element => {
  console.log('MapMaker')
  const { t } = useMapT()
  const dispatch = useDispatch()
  const [layerDesignerLayer, setLayerDesignerLayer] = useState<Layer>(null)
  const [canSave, setCanSave] = useState(false)
  const [activeTab, setActiveTab] = useState('overlays')
  const { message, notification } = App.useApp()

  const {
    getFeatureCallback,
    mapConfig,
    locale,
    darkMode,
    groups,
    showVisibility,
    loggedIn,
    edit,
    addLayers,
    bunnyNetKey
  } = props

  useEffect(() => {
    console.log('MapMaker:', 'mount')
    return () => {
      console.log('MapMaker:', 'unmount')
      // dispatch(
      //   initMapMaker({
      //     map_id: -1,
      //     title: null,
      //     mapLayers: [],
      //     position: null,
      //     settings: null,
      //     owned_by_group_id: null
      //   })
      // )
    }
  }, [])

  //editing
  const drawRef = useRef<MapboxDraw>()

  useEffect(() => {
    //* init redux from props
    dispatch(
      initMapMaker({
        map_id: props.map_id,
        title: props.title,
        mapLayers: props.mapLayers,
        position: props.position,
        settings: props.settings,
        owned_by_group_id: props.owned_by_group_id
      })
    )
  }, [
    dispatch,
    props.map_id,
    props.title,
    props.mapLayers,
    props.position,
    props.settings,
    props.owned_by_group_id,
    props.basemap
  ])

  //* map maker state
  const map_id = useSelector((state) => state.mapMaker.map_id)
  useEffect(() => {
    console.log('MapMaker:', 'map_id changed', map_id)
  }, [map_id])

  const owned_by_group_id = useSelector(
    (state) => state.mapMaker.owned_by_group_id
  )
  useEffect(() => {
    console.log('MapMaker:', 'owned_by_group_id changed', owned_by_group_id)
  }, [owned_by_group_id])

  const mapStyle = useSelector((state) => state.mapMaker.mapStyle)
  useEffect(() => {
    console.log('MapMaker:', 'mapStyle changed')
  }, [mapStyle])

  const mapLayers = useSelector((state) => state.mapMaker.mapLayers)
  useEffect(() => {
    console.log('MapMaker:', 'mapLayers changed')
  }, [mapLayers])

  const title = useSelector((state) => state.mapMaker.title)
  useEffect(() => {
    console.log('MapMaker:', 'title changed')
  }, [title])

  const position = useSelector((state) => state.mapMaker.position)
  useEffect(() => {
    console.log('MapMaker:', 'position changed')
  }, [position])

  const settings = useSelector((state) => state.mapMaker.settings)
  useEffect(() => {
    console.log('MapMaker:', 'settings changed')
  }, [settings])

  //* map state
  const { mapboxglMap } = useContext(MapContext)

  const basemap = useSelector((state) => state.baseMap.baseMap)
  useEffect(() => {
    console.log('MapMaker:', 'basemap changed')
  }, [basemap])

  const enableMeasurementTools = useSelector(
    (state) => state.map.enableMeasurementTools
  )
  useEffect(() => {
    console.log('MapMaker:', 'enableMeasurementTools changed')
  }, [enableMeasurementTools])

  const overlayMapStyle = useSelector((state) => state.map.overlayMapStyle)
  useEffect(() => {
    console.log('MapMaker:', 'overlayMapStyle changed')
  }, [overlayMapStyle])

  const glStyle = useSelector((state) => state.map.glStyle)
  useEffect(() => {
    console.log('MapMaker:', 'glStyle changed')
  }, [glStyle])

  const editingLayer = useSelector((state) => state.dataEditor.editingLayer)
  useEffect(() => {
    console.log('MapMaker:', 'editingLayer changed')
  }, [editingLayer])

  const edits = useSelector((state) => state.dataEditor.edits)
  useEffect(() => {
    console.log('MapMaker:', 'edits changed')
  }, [edits])

  const originals = useSelector((state) => state.dataEditor.originals)
  useEffect(() => {
    console.log('MapMaker:', 'originals changed')
  }, [originals])

  const clickedFeature = useSelector((state) => state.dataEditor.clickedFeature)
  useEffect(() => {
    console.log('MapMaker:', 'clickedFeature changed')
  }, [clickedFeature])

  //* Enable editing for a feature */

  const updateMapLayerFilters = useCallback(() => {
    const layerId = editingLayer.layer_id
    const shortid = editingLayer.shortid
    // build a new filter
    const uniqueIds = []

    if (edits) {
      for (const edit of edits) {
        const mhid = edit.geojson.id

        if (mhid && !uniqueIds.includes(mhid)) {
          uniqueIds.push(mhid)
        }
      }
    }

    if (originals) {
      for (const orig of originals) {
        const mhid = orig.geojson.id

        if (mhid && !uniqueIds.includes(mhid)) {
          uniqueIds.push(mhid)
        }
      }
    }

    const hideEditingFilter = ['!in', 'mhid', ...uniqueIds]

    if (overlayMapStyle) {
      const layers = overlayMapStyle.layers as Array<
        mapboxgl.Layer & { metadata: Record<string, unknown> }
      >
      for (const layer of layers) {
        // check if the layer_id matches
        let foundMatch

        if (layer.metadata && layer.metadata['maphubs:layer_id']) {
          if (layer.metadata['maphubs:layer_id'] === layerId) {
            foundMatch = true
          }
        } else if (layer.id.endsWith(shortid)) {
          foundMatch = true
        }

        if (foundMatch) {
          // get current filter
          let filter = layer.filter

          if (!filter || !Array.isArray(filter) || filter.length === 0) {
            // create a new filter
            filter = hideEditingFilter
          } else if (filter[0] === 'all') {
            // add our condition to the end
            filter = [...layer.filter, ...hideEditingFilter]
          } else {
            filter = ['all', filter, hideEditingFilter]
          }

          mapboxglMap.setFilter(layer.id, filter)
        }
      }
    }
  }, [editingLayer, edits, originals, overlayMapStyle])

  const startEditingFeature = useCallback(
    async (feature: Feature) => {
      const clonedFeature = klona(feature)
      if (getFeatureCallback) {
        const closeMessage = message.loading(t('Downloading...'), 0)
        // get the feature from the API so it isn't cropped to a tile boundary or simplified
        try {
          const geoJSONFromAPI = await getFeatureCallback(
            clonedFeature.properties.mhid
          )
          const featureFromAPI = geoJSONFromAPI.features[0]
          clonedFeature.geometry = featureFromAPI.geometry
        } catch (err) {
          console.log(err)
          notification.error({
            message: t('Server Error'),
            description: err.message || err.toString(),
            duration: 0
          })
        } finally {
          closeMessage()
        }
      }

      console.log('adding feature to mapbox-gl-draw')
      drawRef.current.add(clonedFeature)
      updateMapLayerFilters()
    },
    [getFeatureCallback, updateMapLayerFilters, message, t, notification]
  )

  useEffect(() => {
    /**
     * Add filter to hide vector tile versions of features active in the drawing tool
     *
     */

    if (clickedFeature && drawRef.current) {
      const currentFeature = drawRef.current.get(clickedFeature.id)
      if (!currentFeature) {
        // if not already editing this feature
        startEditingFeature(clickedFeature)
      }
    }
  }, [
    clickedFeature,
    editingLayer,
    edits,
    originals,
    overlayMapStyle,
    startEditingFeature
  ])

  const onSave = useCallback(
    async (
      model: { title: LocalizedString; group: string },
      cb: () => void
    ) => {
      // get position from the map
      const center = mapboxglMap.getCenter()
      const position = {
        zoom: mapboxglMap.getZoom(),
        lng: center.lng,
        lat: center.lat,
        bbox: mapboxglMap.getBounds().toArray()
      }

      // take a screenshot of the map
      const screenshot = await getScreenshot('create-map-map')

      if (!map_id || map_id === -1) {
        // callback to the page so it can save to the db
        const map_id = await props.onCreate({
          group_id: model.group,
          layers: mapLayers,
          style: mapStyle,
          settings,
          title: model.title,
          position,
          basemap,
          screenshot
        })
        // dispatch the create action
        dispatch(
          createMap({
            map_id,
            title: model.title,
            position,
            group_id: model.group
          })
        )
      } else {
        await props.onSave({
          map_id,
          layers: mapLayers,
          style: mapStyle,
          settings,
          title: model.title,
          position,
          basemap,
          screenshot
        })

        dispatch(
          saveMap({
            title: model.title,
            position
          })
        )
      }
      cb() // closes the saving message
    },
    [
      basemap,
      dispatch,
      mapLayers,
      mapStyle,
      map_id,
      props,
      settings,
      mapboxglMap
    ]
  )

  const showLayerDesigner = (layerId: number) => {
    const layer = _find(mapLayers, {
      layer_id: layerId
    })
    setLayerDesignerLayer(layer)
  }

  const onLayerStyleChange = useCallback(
    (
      layer_id: number,
      style: mapboxgl.Style,
      labels: Record<string, any>,
      legend: string
    ) => {
      dispatch(updateLayerStyle({ layer_id, style, labels, legend }))
      // TODO: move layer designer layer to redux to avoid a side-effect here
      //setLayerDesignerLayer(updatedLayer)
    },
    [dispatch]
  )

  const closeLayerDesigner = (): void => {
    setLayerDesignerLayer(null)
  }

  const addLayer = useCallback(
    (layer: Layer): void => {
      console.log('ADD LAYER')
      if (!layer) return
      // clone the layer object so we don't mutate the data in the search results
      layer = klona(layer)

      if (mapboxglMap) {
        if (mapLayers && mapLayers.length === 0 && layer.extent_bbox) {
          mapboxglMap.fitBounds(layer.extent_bbox, {
            padding: 25,
            curve: 1,
            speed: 0.6,
            maxZoom: 16,
            animate: false
          })
        }

        const center = mapboxglMap.getCenter()
        const position = {
          zoom: mapboxglMap.getZoom(),
          lng: center.lng,
          lat: center.lat,
          bbox: mapboxglMap.getBounds().toArray()
        }

        dispatch(setMapPosition({ position }))
      }

      if (
        _find(mapLayers, {
          layer_id: layer.layer_id
        })
      ) {
        message.warning(t('Map already contains this layer'), 3)
      } else {
        dispatch(addToMap({ layer }))
      }
    },
    [mapboxglMap, mapLayers, dispatch, message, t]
  )

  // useEffect(() => {
  //   console.log('useEffect addLayer')
  //   setAddLayer((layer) => {
  //     console.log(layer)
  //     addLayer(layer)
  //   })
  // }, [addLayer, setAddLayer])

  const editLayer = useCallback(
    (layer: Layer) => {
      dispatch(startEditing({ layer }))
      setActiveTab('editing')

      if (enableMeasurementTools) {
        dispatch(setEnableMeasurementTools(false)) // close measurement tool if open
      }

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          point: layer.data_type === 'point',
          polygon: layer.data_type === 'polygon',
          line_string: layer.data_type === 'line',
          trash: true
        }
      })
      drawRef.current = draw
      mapboxglMap.addControl(draw, 'top-right')
      mapboxglMap.on('draw.create', (e) => {
        const features = e.features

        if (features && features.length > 0) {
          for (const feature of features) {
            dispatch(createFeature(feature))
          }
        }
      })
      mapboxglMap.on('draw.update', (e) => {
        if (e.features.length > 0) {
          dispatch(updateFeatures(e.features))
        }
      })
      mapboxglMap.on('draw.delete', (e) => {
        const features = e.features

        if (features && features.length > 0) {
          for (const feature of features) {
            dispatch(deleteFeature(feature))
          }
        }
      })
      mapboxglMap.on('draw.selectionchange', async (e) => {
        // if in simple mode (e.g. not selecting vertices) then check if selected feature changed
        const mode = drawRef.current.getMode()

        if (mode === 'simple_select') {
          const features = e.features

          if (features && features.length > 0) {
            await Promise.all(
              features.map((feature) => {
                dispatch(selectFeatureThunk({ mhid: feature.id, feature }))
              })
            )
          } else {
            dispatch(clearSelectedFeature())
          }
        }
      })
    },
    [dispatch, mapboxglMap, enableMeasurementTools]
  )
  const editLayerLoadedRef = useRef(false)
  useEffect(() => {
    if (!editLayerLoadedRef.current && props.editLayer && mapboxglMap) {
      addLayer(props.editLayer)
      editLayer(props.editLayer)
      editLayerLoadedRef.current = true
    }
  }, [mapboxglMap, addLayer, editLayer, props.editLayer, editLayerLoadedRef])

  const removeMapLayerFilters = useCallback(() => {
    if (!editingLayer || !editingLayer.layer_id) {
      console.error('unable to find editing layer')
      return
    }

    const layerId = editingLayer.layer_id

    if (glStyle?.layers) {
      const layers = glStyle.layers as Array<
        mapboxgl.Layer & { metadata: Record<string, unknown> }
      >
      for (const layer of layers) {
        // check if the layer_id matches
        let foundMatch

        if (layer.metadata && layer.metadata['maphubs:layer_id']) {
          if (layer.metadata['maphubs:layer_id'] === layerId) {
            foundMatch = true
          }
        } else if (layer.id.endsWith(layerId.toString())) {
          foundMatch = true
        }

        if (foundMatch) {
          // get current filter
          let filter = layer.filter

          if (!filter || !Array.isArray(filter) || filter.length === 0) {
            // do nothing
          } else if (filter[0] === 'all') {
            // remove our filter from the end
            filter = layer.filter.pop()
          } else {
            filter = undefined
          }

          mapboxglMap.setFilter(layer.id, filter)
        }
      }
    }
  }, [editingLayer, glStyle?.layers, mapboxglMap])

  const stopEditingLayer = useCallback(() => {
    dispatch(stopEditing())
    setActiveTab('overlays')
    mapboxglMap.removeControl(drawRef.current)
    removeMapLayerFilters()

    //reload mapbox-gl source cache
    const sourceID = Object.keys(editingLayer.style.sources)[0]
    const sourceCache = mapboxglMap.style._sourceCaches[`other:${sourceID}`]

    if (sourceCache) {
      // From: https://github.com/mapbox/mapbox-gl-js/issues/2941#issuecomment-518631078
      // Remove the tiles for a particular source
      sourceCache.clearTiles()
      // Load the new tiles for the current viewport (map.transform -> viewport)
      sourceCache.update(mapboxglMap.transform)
      // Force a repaint, so that the map will be repainted without you having to touch the map
      mapboxglMap.triggerRepaint()
    }
    // force a full reload of the style
    dispatch(reloadStyleThunk({ mapboxMap: mapboxglMap }))
  }, [
    dispatch,
    editingLayer?.style.sources,
    mapboxglMap,
    removeMapLayerFilters
  ])

  const changeBaseMap = useCallback(
    async (mapName: string) => {
      console.log(`(changing basemap from map maker: ${mapName}`)
      const result = await dispatch(setBaseMapThunk(mapName)).unwrap()

      const styleResult = await dispatch(
        setBaseMapStyleThunk({ style: result.baseMapStyle })
      ).unwrap()
      mapboxglMap.setStyle(styleResult.glStyle)
    },
    [dispatch, mapboxglMap]
  )

  const toggleVis = useCallback(
    (layer_id: number) => {
      dispatch(toggleVisibility({ layer_id }))
      // get mapbox layers for this layer_id
      const mapboxLayerIDs = []
      for (const layer of mapLayers) {
        if (layer.layer_id === layer_id) {
          for (const mapboxLayer of layer.style.layers) {
            mapboxLayerIDs.push(mapboxLayer.id)
          }
        }
      }
      const glStyleClone = klona(glStyle)
      for (const layer of glStyleClone.layers as Array<mapboxgl.FillLayer>) {
        if (mapboxLayerIDs.includes(layer.id)) {
          if (!layer.layout) {
            // assume on by default
            layer.layout = { visibility: 'none' }
          } else {
            layer.layout.visibility =
              layer.layout.visibility === 'visible' ? 'none' : 'visible'
          }
        }
      }

      dispatch(
        setOverlayStyleThunk({
          overlayStyle: glStyleClone,
          optimizeLayers: true,
          mapboxMap: mapboxglMap
        })
      )
    },
    [dispatch, glStyle, mapLayers, mapboxglMap]
  )

  const updateLayers = useCallback(
    (layers: Layer[]) => {
      dispatch(setMapLayers({ mapLayers: layers }))
    },
    [dispatch]
  )

  const removeFromMapCallback = useCallback(
    (layer: Layer) => {
      dispatch(removeFromMap({ layer }))
    },
    [dispatch]
  )

  if (!Array.isArray(mapLayers)) return <></>

  return (
    <Row
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <div
        style={{
          height: '100%',
          width: '275px',
          float: 'left'
        }}
      >
        {layerDesignerLayer && (
          <Drawer
            title={t(layerDesignerLayer.name)}
            placement='left'
            width='350px'
            closable
            destroyOnClose
            bodyStyle={{
              height: 'calc(100vh - 55px)',
              padding: '0px'
            }}
            onClose={closeLayerDesigner}
            open
            mask={false}
          >
            <Row
              style={{
                height: 'calc(100% - 55px)',
                marginBottom: '10px'
              }}
            >
              <MapLayerDesigner
                layer={layerDesignerLayer}
                onStyleChange={onLayerStyleChange}
              />
            </Row>
            <Row justify='center' align='middle'>
              <Button type='primary' onClick={closeLayerDesigner}>
                {t('Close')}
              </Button>
            </Row>
          </Drawer>
        )}
        <style jsx global>
          {`
            .ant-tabs-content {
              height: 100%;
              width: 100%;
            }
            .ant-tabs-tabpane {
              height: 100%;
            }

            .ant-tabs > .ant-tabs-content > .ant-tabs-tabpane-inactive {
              display: none;
            }

            .ant-tabs-bar {
              padding-left: 3px;
              margin: 0;
            }
          `}
        </style>
        <Tabs
          defaultActiveKey='overlays'
          style={{
            height: 'calc(100% - 50px)'
          }}
          tabBarStyle={{
            marginBottom: 0,
            marginLeft: '16px'
          }}
          animated={false}
          activeKey={activeTab}
          onChange={(activeTab) => {
            setActiveTab(activeTab)
          }}
          items={[
            {
              key: 'basemap',
              label: t('Base Map'),
              children: (
                <BaseMapSelection
                  onChange={changeBaseMap}
                  darkMode={darkMode}
                />
              )
            },
            {
              key: 'overlays',
              label: t('Layers'),
              children: (
                <>
                  <Row
                    style={{
                      height: 'calc(100% - 50px)',
                      width: '100%'
                    }}
                  >
                    <LayerList
                      layers={mapLayers}
                      showVisibility={showVisibility}
                      showRemove
                      showDesign
                      showInfo
                      showEdit={!editingLayer}
                      toggleVisibility={toggleVis}
                      removeFromMap={removeFromMapCallback}
                      showLayerDesigner={showLayerDesigner}
                      updateLayers={updateLayers}
                      editLayer={editLayer}
                      openAddLayer={() => {
                        addLayers(addLayer, mapLayers)
                      }}
                    />
                  </Row>
                  <Row
                    style={{
                      height: '50px',
                      textAlign: 'center',
                      width: '100%'
                    }}
                  >
                    <Button
                      style={{
                        margin: 'auto'
                      }}
                      icon={<PlusOutlined />}
                      type='primary'
                      onClick={() => {
                        addLayers(addLayer, mapLayers)
                      }}
                    >
                      {t('Add a Layer')}
                    </Button>
                  </Row>
                </>
              )
            },
            editingLayer
              ? {
                  key: 'editing',
                  label: t('Editing'),
                  children: <EditLayerPanel />
                }
              : {
                  key: 'editing',
                  disabled: true,
                  label: '',
                  children: <></>
                }
          ]}
        />

        <hr
          style={{
            margin: 0
          }}
        />
        <Row
          justify='center'
          align='middle'
          style={{
            width: '100%',
            height: '50px',
            padding: '0px 10px'
          }}
        >
          <Col span={10}>
            <Row
              justify='space-around'
              align='middle'
              style={{
                width: '100%',
                height: '50px'
              }}
            >
              <Col span={8}>
                <MapSettingsPanel />
              </Col>
              <Col span={8}>
                <Tooltip
                  title={
                    map_id
                      ? t('Export MapHubs File')
                      : t('Download MapHubs File')
                  }
                  placement='top'
                >
                  <Button
                    download
                    href={`/api/mapexport/${map_id}/${slugify(
                      t(title || 'New Map')
                    )}.maphubs`}
                    icon={<DownloadOutlined />}
                    style={{
                      marginRight: '10px'
                    }}
                    disabled={!edit && mapLayers.length === 0}
                  />
                </Tooltip>
              </Col>
              <Col span={8}>
                <Tooltip title={t('Delete Map')} placement='top'>
                  <Button
                    danger
                    onClick={() => {
                      confirm({
                        title: t('Confirm Deletion'),
                        content: t('Please confirm deletion of ') + t(title),
                        okText: t('Delete'),
                        okType: 'danger',

                        onOk() {
                          props.onDelete(map_id)
                        }
                      })
                    }}
                    icon={<DeleteOutlined />}
                    style={{
                      marginRight: '10px'
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col
            span={14}
            style={{
              textAlign: 'right'
            }}
          >
            <SaveMapModal
              owned_by_group_id={owned_by_group_id}
              editingLayer={!!editingLayer}
              initialTitle={title || props.title}
              editing={edit}
              onSave={onSave}
              userGroups={groups}
              loggedIn={loggedIn}
            />
          </Col>
        </Row>
      </div>
      <div
        style={{
          height: '100%',
          width: 'calc(100% - 275px)'
        }}
      >
        {mapStyle && mapStyle.layers && mapStyle.sources && (
          <Row
            style={{
              height: '100%',
              width: '100%',
              margin: 0,
              position: 'relative'
            }}
          >
            <Map
              id='create-map-map'
              initialGLStyle={mapStyle}
              insetMap
              initialBaseMap={props.basemap}
              insetConfig={settings ? settings.insetConfig : undefined}
              onChangeBaseMap={(basemap) => {
                changeBaseMap(basemap)
              }}
              fitBounds={position?.bbox}
              mapConfig={mapConfig}
              onLoad={(map) => {
                // another way to get the map instance if we need it
              }}
              preserveDrawingBuffer
              hash
              locale={locale} //pass through props locale so Map component can set it in redux
              mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
              darkMode={darkMode}
              bunnyNetKey={bunnyNetKey}
            >
              {editingLayer && (
                <EditorToolButtons
                  stopEditingLayer={stopEditingLayer}
                  onFeatureUpdate={(
                    type: string,
                    feature: Record<string, any>
                  ) => {
                    if (drawRef.current) {
                      if (type === 'update' || type === 'create') {
                        drawRef.current.add(feature.geojson)
                      } else if (type === 'delete') {
                        drawRef.current.delete(feature.geojson.id)
                      }
                    }
                  }}
                />
              )}
            </Map>

            <MiniLegend
              style={{
                position: 'absolute',
                top: '5px',
                left: '5px',
                minWidth: '200px',
                width: '25%'
              }}
              layers={mapLayers}
              maxHeight='calc(100vh - 300px)'
              hideInactive
              showLayersButton={false}
            />
          </Row>
        )}
      </div>
    </Row>
  )
}

MapMaker.defaultProps = {
  popularLayers: [],
  showVisibility: true,
  mapLayers: [],
  showTitleEdit: true,
  settings: {},
  basemap: 'default',
  locale: 'en'
}
export default MapMaker
