import React from 'react'
export default function Clinic() {
  return (
    <symbol
      id='maphubs-icon-clinic'
      viewBox='0 0 16 20'
      preserveAspectRatio='xMidYMid meet'
    >
      <polygon points='15,6 10,6 10,1 6,1 6,6 1,6 1,10 6,10 6,15 10,15 10,10 15,10 ' />
    </symbol>
  )
}