import React from 'react'
import { Radio } from 'antd'

type Props = {
  labelOn: string
  labelOff: string
  value?: boolean
  onChange?: (value: boolean) => void
}

const SwitchWithLabels = ({ labelOff, labelOn, value, onChange }: Props) => {
  return (
    <Radio.Group
      value={value}
      onChange={(e) => {
        if (onChange) onChange(e.target.value)
      }}
    >
      <Radio.Button value={false}>{labelOff}</Radio.Button>
      <Radio.Button value={true}>{labelOn}</Radio.Button>
    </Radio.Group>
  )
}

export default SwitchWithLabels
