import React, { createContext, useState } from 'react'
import mapboxgl from 'mapbox-gl'

export type MapContextType = {
  mapboxglMap?: mapboxgl.Map
  setMapboxGLMap?: (mapboxglMap: mapboxgl.Map) => void
  toggleVisibility?: (
    layer_id: number | string,
    disableStyleUpdate?: boolean
  ) => void
  setToggleVisibility?: (
    f: (layer_id: number | string, disableStyleUpdate?: boolean) => void
  ) => void
}

export const MapContext = createContext<MapContextType>({})

// This context provider is passed to any component requiring the context
export const MapProvider = ({ children }: { children: unknown }) => {
  const [mapboxglMap, setMapboxGLMap] = useState<mapboxgl.Map | undefined>()
  const [toggleVisibility, setToggleVisibility] = useState<
    (layer_id: number | string) => void
  >(() => {})

  return (
    <MapContext.Provider
      value={{
        mapboxglMap,
        setMapboxGLMap,
        toggleVisibility,
        setToggleVisibility
      }}
    >
      {children}
    </MapContext.Provider>
  )
}
