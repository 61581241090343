import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import mapboxgl from 'mapbox-gl'

import { setOverlayStyleThunk } from './map/setOverlayStyleThunk'
import { setBaseMapStyleThunk } from './map/setBaseMapStyleThunk'

import { klona } from 'klona/json'
import { Feature } from 'geojson'

export interface MapState {
  enableMeasurementTools?: boolean
  measurementMessage?: string
  measurementFeatures?: Feature[]
  interactiveLayers: string[]
  interactionBufferSize: number
  glStyle?: mapboxgl.Style
  overlayMapStyle?: mapboxgl.Style
  allowLayersToMoveMap?: boolean
}

const initialState: MapState = {
  enableMeasurementTools: false,
  measurementMessage: '',
  interactiveLayers: [],
  interactionBufferSize: 10,
  glStyle: undefined,
  overlayMapStyle: undefined
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    initMap: (
      state,
      action: PayloadAction<{
        interactiveLayers: MapState['interactiveLayers']
        interactionBufferSize?: number
      }>
    ) => {
      for (const key of Object.keys(action.payload)) {
        state[key] = action.payload[key]
      }
    },

    setEnableMeasurementTools: (state, action: PayloadAction<boolean>) => {
      state.enableMeasurementTools = action.payload
    },

    setMeasurementMessage: (state, action: PayloadAction<string>) => {
      state.measurementMessage = action.payload
    },

    setMeasurementFeatures: (state, action: PayloadAction<Feature[]>) => {
      state.measurementFeatures = action.payload
    },

    setAllowLayersToMoveMap: (state, action: PayloadAction<boolean>) => {
      state.allowLayersToMoveMap = action.payload
    },

    setInteractiveLayers: (state, action: PayloadAction<string[]>) => {
      state.interactiveLayers = action.payload
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(
      setOverlayStyleThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          overlayMapStyle: mapboxgl.Style
          glStyle: mapboxgl.Style
        }>
      ) => {
        const { overlayMapStyle, glStyle } = action.payload

        state.overlayMapStyle = klona(overlayMapStyle)
        state.glStyle = klona(glStyle)
      }
    )
    builder.addCase(
      setBaseMapStyleThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          glStyle: mapboxgl.Style
        }>
      ) => {
        const { glStyle } = action.payload

        state.glStyle = klona(glStyle)
      }
    )
    return
  }
})

export const {
  initMap,
  setEnableMeasurementTools,
  setMeasurementMessage,
  setMeasurementFeatures,
  setAllowLayersToMoveMap,
  setInteractiveLayers
} = mapSlice.actions

export default mapSlice.reducer
