import React from 'react'
import { Dropdown } from 'antd'
import { Layer } from '@maphubs/mhtypes'
import useMapT from '../hooks/useMapT'
import LayerListItemStatic from './LayerListItemStatic'

// import icons individually for better dev build performance
import DownOutlined from '@ant-design/icons/DownOutlined'

type Props = {
  name: string
  layers: Array<Layer>
  toggleVisibility: (layer_id: number) => void
}

const LayerListDropDown = ({
  name,
  layers,
  toggleVisibility
}: Props): JSX.Element => {
  const { t } = useMapT()
  return (
    <div
      style={{
        height: '35px'
      }}
    >
      <Dropdown
        style={{
          padding: 0
        }}
        menu={{
          items: layers.map((layer) => {
            return {
              key: layer.layer_id,
              label: (
                <LayerListItemStatic
                  layer={layer}
                  toggleVisibility={toggleVisibility}
                />
              )
            }
          })
        }}
        trigger={['click']}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        <span
          className='ant-dropdown-link'
          style={{
            cursor: 'pointer',
            height: '35px',
            lineHeight: '35px'
          }}
        >
          {t(name)} <DownOutlined />
        </span>
      </Dropdown>
    </div>
  )
}
export default LayerListDropDown
