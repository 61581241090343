import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _findIndex from 'lodash.findindex'
import _remove from 'lodash.remove'
import _differenceBy from 'lodash.differenceby'
import { Styles as MapStyles } from '@maphubs/map'

import type { MapHubsField, Layer } from '@maphubs/mhtypes'
import { localeUtil } from '@maphubs/locales'
import type { mapboxgl } from '@maphubs/map'
import { klona } from 'klona/json'

export type LayerState = {
  // other status
  status?: string
  tileServiceInitialized?: boolean
  pendingChanges?: boolean
  // presets
  presets: MapHubsField[]
  presetIDSequence: number
} & Layer

const initialState: LayerState = {
  layer_id: -1,
  shortid: '',
  owned_by_group_id: null,
  last_updated: null,
  creation_time: null,
  name: localeUtil.getEmptyLocalizedString(),
  description: localeUtil.getEmptyLocalizedString(),
  published: true,
  data_type: '',
  source: localeUtil.getEmptyLocalizedString(),
  license: 'none',
  preview_position: {
    zoom: 1,
    lat: 0,
    lng: 0,
    bbox: [
      [-180, -90],
      [180, 90]
    ]
  },
  style: null,
  legend_html: null,
  is_external: false,
  external_layer_type: '',
  external_layer_config: {},
  complete: false,
  disable_export: false,
  // status flags
  tileServiceInitialized: false,
  pendingChanges: false,
  // presets
  presets: [],
  presetIDSequence: 1
}

const updatePresetsInStyle = (style, presets: MapHubsField[]) => {
  style = klona(style)

  if (style) {
    for (const key of Object.keys(style.sources)) {
      // our layers normally only have one source, but just in case...
      style = MapStyles.settings.setSourceSetting(
        style,
        key,
        'presets',
        presets
      )
    }
    return style
  } else {
    console.log('Missing style')
  }
}

const getSourceConfig = (state: LayerState) => {
  let sourceConfig: Layer['external_layer_config'] = {
    type: 'vector'
  }

  if (state.is_external) {
    sourceConfig = state.external_layer_config
  }

  return sourceConfig
}

const resetStyleGL = (state: LayerState) => {
  let style: mapboxgl.Style | undefined
  const layer_id = state.layer_id ? state.layer_id : -1
  const isExternal = state.is_external
  const shortid = state.shortid
  const elc = state.external_layer_config

  if (isExternal && state.external_layer_type === 'mapbox-map' && elc.url) {
    style = MapStyles.raster.rasterStyleTileJSON(
      layer_id,
      shortid,
      elc.url,
      100,
      'raster'
    )
  } else if (
    isExternal &&
    (elc.type === 'raster' || elc.type === 'ags-mapserver-tiles')
  ) {
    style = MapStyles.raster.defaultRasterStyle(
      layer_id,
      shortid,
      elc,
      elc.type
    )
  } else if (isExternal && elc.type === 'multiraster' && elc.layers) {
    style = MapStyles.raster.defaultMultiRasterStyle(
      layer_id,
      shortid,
      elc.layers,
      'raster',
      elc
    )
  } else if (isExternal && elc.type === 'geojson' && elc.data_type) {
    style = MapStyles.style.defaultStyle(
      layer_id,
      shortid,
      getSourceConfig(state),
      elc.data_type
    )
  } else {
    style = MapStyles.style.defaultStyle(
      layer_id,
      shortid,
      getSourceConfig(state),
      state.data_type
    )
  }

  // restore presets
  const presets = state.presets
  for (const sourceID of Object.keys(style.sources)) {
    const mapSource = style.sources[sourceID]

    if (!mapSource.metadata) {
      mapSource.metadata = {}
    }

    mapSource.metadata['maphubs:presets'] = presets
  }
  return style
}

const resetLegendHTML = (state: LayerState) => {
  let legend_html
  const { is_external, external_layer_config } = state
  const elc = external_layer_config

  if (
    is_external &&
    (elc.type === 'raster' ||
      elc.type === 'multiraster' ||
      elc.type === 'ags-mapserver-tiles')
  ) {
    legend_html = MapStyles.legend.rasterLegend()
  } else if (is_external && elc.type === 'mapbox-style') {
    legend_html = MapStyles.legend.rasterLegend()
  } else {
    legend_html = MapStyles.legend.defaultLegend(state)
  }

  return legend_html
}

const initLayer = (state: LayerState) => {
  // treat as immutable and clone

  if (!state.style) {
    state.style = MapStyles.style.defaultStyle(
      state.layer_id,
      state.shortid,
      getSourceConfig(state),
      state.data_type
    )
  }

  state.legend_html = !state.legend_html
    ? MapStyles.legend.defaultLegend(state)
    : resetLegendHTML(state)

  if (!state.preview_position) {
    state.preview_position = {
      zoom: 1,
      lat: 0,
      lng: 0,
      bbox: null
    }
  }
}

export const layerSlice = createSlice({
  name: 'layer',
  initialState,
  reducers: {
    loadLayer: (state, action: PayloadAction<Layer>) => {
      for (const key of Object.keys(action.payload)) {
        state[key] = action.payload[key]
      }

      let style = state.style

      if (!style) {
        style = resetStyleGL(state as LayerState)
        state.style = style
      }

      if (!state.legend_html) {
        state.legend_html = resetLegendHTML(state as LayerState)
      }

      if (style) {
        const firstSource = Object.keys(style.sources)[0]
        const presets = MapStyles.settings.getSourceSetting(
          style as mapboxgl.Style,
          firstSource,
          'presets'
        )

        if (presets && Array.isArray(presets)) {
          for (const preset of presets) {
            if (state.presetIDSequence) {
              preset.id = state.presetIDSequence++
            }
          }
          state.style = updatePresetsInStyle(style, presets)
          state.presets = presets
        }
      } else {
        console.log('Missing style')
      }
    },
    saveSettings: (
      state,
      action: PayloadAction<{
        name: LayerState['name']
        description: LayerState['description']
        group: LayerState['owned_by_group_id']
        source: LayerState['source']
        license: LayerState['license']
      }>
    ) => {
      const data = action.payload

      state.name = data.name
      state.description = data.description
      state.owned_by_group_id = data.group
      state.source = data.source
      state.license = data.license
    },
    saveAdminSettings: (
      state,
      action: PayloadAction<{
        group: LayerState['owned_by_group_id']
        disableExport: LayerState['disable_export']
      }>
    ) => {
      const data = action.payload
      state.owned_by_group_id = data.group
      state.disable_export = data.disableExport
    },
    saveExternalLayerConfig: (
      state,
      action: PayloadAction<Layer['external_layer_config']>
    ) => {
      state.external_layer_config = action.payload
    },
    saveDataSettings: (
      state,
      action: PayloadAction<{
        is_empty?: boolean
        empty_data_type?: string
        is_external: boolean
        external_layer_type: Layer['external_layer_type']
        external_layer_config?: Layer['external_layer_config']
      }>
    ) => {
      const data = action.payload
      if (data.is_empty) {
        // if empty, update the style to match the empty data type
        state.data_type = data.empty_data_type

        state.style = MapStyles.style.defaultStyle(
          state.layer_id,
          state.shortid,
          getSourceConfig(state),
          data.empty_data_type
        )
        state.legend_html = MapStyles.legend.defaultLegend(state)
      }

      state.is_external = data.is_external
      state.external_layer_type = data.external_layer_type
      if (data.external_layer_config) {
        state.external_layer_config = data.external_layer_config
      }
      state.is_empty = data.is_empty
    },

    setStyle: (
      state,
      action: PayloadAction<{
        style?: LayerState['style']
        labels?: LayerState['labels']
        legend_html?: string
        preview_position?: LayerState['preview_position']
      }>
    ) => {
      // treat as immutable and clone
      const data = action.payload
      state.style = data.style ? klona(data.style) : state.style
      state.labels = data.labels ? klona(data.labels) : state.labels
      state.legend_html = data.legend_html
        ? data.legend_html
        : state.legend_html
      state.preview_position = data.preview_position
        ? klona(data.preview_position)
        : state.preview_position
    },
    tileServiceInitialized: (state) => {
      state.tileServiceInitialized = true
    },
    setDataType: (
      state,
      action: PayloadAction<{
        data_type: Layer['data_type']
        presets: MapHubsField[]
      }>
    ) => {
      state.data_type = action.payload.data_type
      state.style = MapStyles.style.defaultStyle(
        state.layer_id,
        state.shortid,
        getSourceConfig(state),
        action.payload.data_type
      )
      state.legend_html = MapStyles.legend.defaultLegend(state)
      state.presets = action.payload.presets

      if (!state.preview_position) {
        state.preview_position = {
          zoom: 1,
          lat: 0,
          lng: 0,
          bbox: null
        }
      }
    },
    resetStyle: (state) => {
      state.style = resetStyleGL(state as LayerState)
      state.legend_html = resetLegendHTML(state as LayerState)
    },
    setComplete: (state) => {
      state.complete = true
    },

    addPreset: (state) => {
      console.log('adding new preset')
      const presets: Array<MapHubsField> = klona(state.presets)

      if (state.presetIDSequence) {
        presets.push({
          tag: '',
          label: { en: '' },
          type: 'text',
          isRequired: false,
          showOnMap: true,
          id: state.presetIDSequence++
        })
      }

      state.style = updatePresetsInStyle(state.style, presets)
      state.presets = presets
    },
    deletePreset: (state, action: PayloadAction<number>) => {
      const id = action.payload
      if (state.presets) {
        const presets: Array<MapHubsField> = klona(state.presets)

        console.log('delete preset:' + id)

        _remove(presets, {
          id
        })

        state.style = updatePresetsInStyle(state.style, presets)
        state.presets = presets
      }
    },

    updatePreset: (
      state,
      action: PayloadAction<{ id: number; preset: MapHubsField }>
    ) => {
      const { id, preset } = action.payload
      console.log('update preset:' + id)

      if (state.presets) {
        const presets: Array<MapHubsField> = klona(state.presets)

        const i = _findIndex(presets, {
          id
        })

        if (i >= 0) {
          presets[i] = preset

          state.style = updatePresetsInStyle(state.style, presets)
          state.presets = presets
        }
      } else {
        console.log("Can't find preset with id: " + id)
      }
    },

    updatePresets: (state, action: PayloadAction<MapHubsField[]>) => {
      let updatedPresets = klona(action.payload)
      state.style = updatePresetsInStyle(state.style, updatedPresets)
      state.presets = updatedPresets
    },

    mergeNewPresetTags: (state, action: PayloadAction<string[]>) => {
      const data = action.payload
      if (state.presets) {
        let presets: Array<MapHubsField> = klona(state.presets)

        let idSeq = presets.length - 1
        const importedPresets = data.map((tag: string) => {
          return {
            tag,
            label: tag,
            type: 'text',
            isRequired: false,
            showOnMap: true,
            mapTo: tag,
            id: idSeq++
          }
        })

        const newPresets = _differenceBy(importedPresets, presets, 'tag')

        presets = [...presets, ...newPresets]
        state.style = updatePresetsInStyle(state.style, presets)
        state.presets = presets
      }
    },

    loadDefaultPresets: (state, action: PayloadAction<MapHubsField[]>) => {
      // called when setting up a new empty layer

      const presets = action.payload
      if (state.presetIDSequence) {
        // update the preset ids
        for (const preset of presets) {
          preset.id = state.presetIDSequence++
        }

        initLayer(state as LayerState)

        state.style = updatePresetsInStyle(state.style, presets)
        state.presets = presets
      }
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    return
  }
})

export const {
  loadLayer,
  saveSettings,
  saveAdminSettings,
  saveExternalLayerConfig,
  saveDataSettings,
  mergeNewPresetTags,
  setStyle,
  tileServiceInitialized,
  setDataType,
  resetStyle,
  setComplete,
  addPreset,
  deletePreset,
  updatePreset,
  loadDefaultPresets,
  updatePresets
} = layerSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSettings = (state: { layer: LayerState }) => {
  return {
    layer_id: state.layer.layer_id,
    owned_by_group_id: state.layer.owned_by_group_id,
    status: state.layer.status,
    license: state.layer.license,
    name: state.layer.name,
    description: state.layer.description,
    source: state.layer.source
  }
}
export const selectMapStyle = (state: { layer: LayerState }) => {
  return {
    style: state.layer.style,
    labels: state.layer.labels,
    legend_html: state.layer.legend_html,
    preview_position: state.layer.preview_position,
    presets: state.layer.presets
  }
}

export const selectLayerId = (state: { layer: LayerState }) => {
  return state.layer.layer_id
}
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default layerSlice.reducer
