import React from 'react'
export default function Boat() {
  return (
    <symbol
      id='maphubs-icon-boat'
      viewBox='0 0 763.17 953.9625'
      preserveAspectRatio='xMidYMid meet'
    >
      <path d='M742.981,346.057c4.425,16.63,0.506,29.036-6.658,41.065c-51.952,87.241-124.926,147.343-223.962,173.905  c-59.403,15.932-119.879,19.647-181.122,17.261c-55.904-2.177-109.105-14.367-159.803-37.946  c-59.671-27.752-128.118-79.646-150.96-143.741c-4.411-12.378-2.205-20.409,7.962-28.155c4.992-3.803,10.86-6.739,16.73-9.063  c98.775-39.113,199.236-73.166,303.138-95.807c45.529-9.921,91.79-16.558,137.817-24.092c24.411-3.996,49.123-6.174,73.511-10.286  c9.837-1.659,18.139-0.437,26.259,4.782c2.378,1.528,5.092,2.555,7.392,4.18C643.564,273.706,695.162,307.437,742.981,346.057z   M552.123,419.154c-13.897-16.568-85.941-83.332-152.726-131.28c-42.549,7.451-86.178,17.136-131.318,32.596  c50.568,45.044,96.118,85.619,144.536,128.748C466.489,443.105,504.5,436.859,552.123,419.154z M454.333,278.333  c26.047,22.465,49.822,43.181,73.832,63.619c24.196,20.597,47.34,42.552,74.968,59.946c31.967-12.923,64.045-24.872,93.363-45.818  c-4.613-4.706-9.578-8.851-9.578-8.851c-27.808-20.825-55.642-41.617-83.6-62.24c-3.342-2.465-20.655-14.094-20.655-14.094  s-10.172-8.298-18.154-7.995c-29.219-3.019-52.783,3.712-81.853,9.294C474.492,273.762,466.231,275.671,454.333,278.333z   M74.483,385.758c42.932,24.186,85.454,39.175,129.459,49.335c76.526,17.671,122.266,26.699,143.338,18.719  c-42.087-37.582-91.347-80.825-133.393-118.371C167.263,349.764,122.15,365.482,74.483,385.758z' />
    </symbol>
  )
}