import React, { useEffect, useRef, useState } from 'react'
import mapboxgl from 'mapbox-gl'

function useInteraction(
  mapRef: React.RefObject<mapboxgl.Map>,
  options: {
    showFullScreen?: boolean
    navPosition: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'
    id?: string
    interactive?: boolean
  }
) {
  const { showFullScreen, navPosition, id, interactive } = options
  const navControlRef = useRef<mapboxgl.NavigationControl>()
  const fullScreenControlRef = useRef<mapboxgl.FullscreenControl>()
  const [interactionActive, setInteractionActive] = useState(interactive)

  const addInteraction = (map: mapboxgl.Map) => {
    const navControl = new mapboxgl.NavigationControl({
      showCompass: false
    })
    navControlRef.current = navControl
    map.addControl(navControl, navPosition)
    if (showFullScreen) {
      const fullScreenControl = new mapboxgl.FullscreenControl({
        container: document.querySelector(`#${id}-fullscreen-wrapper`)
      })
      fullScreenControlRef.current = fullScreenControl
      map.addControl(fullScreenControl)
    }
  }
  // handle interactive setting changed
  useEffect(() => {
    if (!interactionActive && interactive) {
      console.log(`(${id}) enabling interaction`)
      // interactive is enabled but started disabled
      const navControl = new mapboxgl.NavigationControl({
        showCompass: false
      })
      mapRef.current.addControl(navControl, navPosition)
      navControlRef.current = navControl
      if (showFullScreen) {
        const fullScreenControl = new mapboxgl.FullscreenControl({
          container: document.querySelector(`#${id}-fullscreen-wrapper`)
        })
        fullScreenControlRef.current = fullScreenControl
        mapRef.current.addControl(fullScreenControl, navPosition)
      }
      if (mapRef.current.dragPan) mapRef.current.dragPan.enable()
      if (mapRef.current.scrollZoom) mapRef.current.scrollZoom.enable()
      if (mapRef.current.doubleClickZoom)
        mapRef.current.doubleClickZoom.enable()
      if (mapRef.current.touchZoomRotate)
        mapRef.current.touchZoomRotate.enable()
      setInteractionActive(true)
    } else if (interactionActive && !interactive) {
      console.log(`(${id}) disabling interaction`)

      mapRef.current.removeControl(navControlRef.current)
      mapRef.current.removeControl(fullScreenControlRef.current)

      if (mapRef.current.dragPan) mapRef.current.dragPan.disable()
      if (mapRef.current.scrollZoom) mapRef.current.scrollZoom.disable()
      if (mapRef.current.doubleClickZoom)
        mapRef.current.doubleClickZoom.disable()
      if (mapRef.current.touchZoomRotate)
        mapRef.current.touchZoomRotate.disable()
      setInteractionActive(false)
    }
  }, [interactive, interactionActive, navPosition, showFullScreen, id, mapRef])

  return { interactionActive, addInteraction }
}

export default useInteraction
