import React from 'react'
export default function Mining() {
  return (
    <symbol
      id='maphubs-icon-mining'
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid meet'
    >
      <g>
        <g>
          <rect
            x='7.8597517'
            y='51.5525627'
            transform='matrix(0.7071068 -0.7071068 0.7071068 0.7071068 -30.552948 38.9071159)'
            width='47.6576347'
            height='9.5633345'
          />
          <polygon points='66.9146729,26.2322388 61.7905884,21.1080933 56.8917236,24.3687744 63.6540527,31.1310425   ' />
          <path d='M54.8717651,40.5368652c5.3587036,5.9961548,10.0871582,12.5598145,13.968811,19.5911255    c0.0353394,0.0640259,0.0706787,0.1281128,0.1055298,0.1924438c-0.1807861-0.3334351,0.5753784-2.3654785,0.6385498-2.7723999    c0.3754883-2.4164429,0.3642578-4.8967285,0.0158691-7.3153687c-1.4156494-9.8285522-8.6970215-17.5563965-15.8718262-23.887146    c-6.5492554-5.7787476-15.4349365-9.8988037-24.3834839-7.7381592c-0.2640381,0.0637207-1.4456787,0.5762329-1.6428223,0.4693604    C37.7568359,24.5268555,47.1654053,31.9138794,54.8717651,40.5368652z' />
          <path d='M52.8867188,55.6611328c-0.5546875,0.6147461-0.5068359,1.5634766,0.1074219,2.1186523l5.1669922,4.6669922    c0.2871094,0.2592773,0.6464844,0.3867188,1.0058594,0.3867188c0.4091797,0,0.8173828-0.1665039,1.1132812-0.4946289    c0.5546875-0.6147461,0.5068359-1.5634766-0.1074219-2.1186523l-5.1669922-4.6669922    C54.3925781,54.9990234,53.4433594,55.0454102,52.8867188,55.6611328z' />
          <path d='M76.7382812,60.2363281c0.1777344,0.0688477,0.3603516,0.1015625,0.5410156,0.1015625    c0.6015625,0,1.1689453-0.3647461,1.3994141-0.9589844l2.3876953-6.1708984    c0.2988281-0.7729492-0.0859375-1.6416016-0.8583984-1.9404297c-0.7675781-0.2973633-1.640625,0.0849609-1.9404297,0.8574219    l-2.3876953,6.1708984C75.5810547,59.0688477,75.9658203,59.9375,76.7382812,60.2363281z' />
          <path d='M55.9199219,68.3691406c-0.0576172-0.8271484-0.8017578-1.4619141-1.6015625-1.3901367l-7.4238281,0.5249023    c-0.8271484,0.0581055-1.4492188,0.7753906-1.390625,1.6020508C45.5595703,69.8959961,46.2177734,70.5,46.9980469,70.5    c0.0361328,0,0.0722656-0.0009766,0.1074219-0.0039062l7.4238281-0.5249023    C55.3564453,69.9130859,55.9785156,69.1958008,55.9199219,68.3691406z' />
          <polygon points='86.628418,70.5792847 72.9445801,78.3017578 70.4716797,88.9553833 90.25,88.9553833   ' />
          <polygon points='60.8666992,70.3288574 71.5859375,75.6235352 84.234375,68.4858398 68.4331055,64.375   ' />
          <polygon points='51.125,88.9553833 67.3922729,88.9553833 69.9031372,78.1381836 58.9124756,72.7090454   ' />
        </g>
      </g>
    </symbol>
  )
}