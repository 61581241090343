import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { LocalizedString, MapPosition } from '@maphubs/mhtypes'
import _find from 'lodash.find'
import defaultBaseMapOptions from '../../Map/BaseMaps/base-map-options.json'
import type mapboxgl from 'mapbox-gl'
import type { RootState } from '../store'
import { klona } from 'klona/json'

export type BaseMapOption = {
  value: string
  label: LocalizedString
  attribution: string
  style: Record<string, any>
  loadFromFile: string
  icon?: string
}
export type BaseMapState = {
  baseMap: string
  baseMapStyle?: mapboxgl.Style
  prevBaseMapStyle?: mapboxgl.Style
  attribution: string
  baseMapOptions: Array<BaseMapOption>
  mapboxAccessToken: string
  position?: MapPosition
}

const initialState: BaseMapState = {
  baseMap: 'default',
  attribution: '© Mapbox © OpenStreetMap',
  baseMapOptions: defaultBaseMapOptions as unknown as BaseMapOption[],
  mapboxAccessToken: ''
}

const getBaseMapStyle = async (
  state: BaseMapState,
  baseMap: string
): Promise<{
  baseMapStyle: BaseMapState['baseMapStyle']
  attribution?: string
}> => {
  const { mapboxAccessToken, baseMapOptions } = state

  const config = _find(baseMapOptions, {
    value: baseMap
  })

  console.log('config', config)

  if (config) {
    if (config.style) {
      const style = klona(config.style)

      if (typeof style !== 'string') {
        if (!style.glyphs) {
          style.glyphs = 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf'
        }

        if (!style.sprite) {
          style.sprite = ''
        }
      }

      return {
        baseMapStyle: style,
        attribution: config.attribution
      }
    } else if (config.url) {
      try {
        const response = await fetch(config.url)
        const result = await response.json()
        const style = result.body

        if (!style.glyphs) {
          style.glyphs = 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf'
        }

        if (!style.sprite) {
          style.sprite = ''
        }

        return {
          baseMapStyle: style,
          attribution: config.attribution
        }
      } catch (err) {
        console.error(err)
      }
    } else if (config.mapboxUrl) {
      // example: mapbox://styles/mapbox/streets-v8?optimize=true
      // converted to: //https://api.mapbox.com/styles/v1/mapbox/streets-v9?access_token=
      let url = config.mapboxUrl.replace(
        'mapbox://styles/',
        'https://api.mapbox.com/styles/v1/'
      )

      url = `${url}${
        config.mapboxUrl.endsWith('?optimize=true') ? '&' : '?'
      }access_token=${mapboxAccessToken}`

      try {
        const response = await fetch(url)
        const result = await response.json()
        return {
          baseMapStyle: result,
          attribution: config.attribution
        }
      } catch (err) {
        console.error(err)
      }
    } else {
      console.log(`map style not found for base map: ${baseMap}`)
    }
  } else {
    console.error(`unknown base map: ${baseMap} using default instead`)

    // load the  default basemap
    const defaultConfig = _find(defaultBaseMapOptions, {
      value: 'default'
    })

    let url = defaultConfig.mapboxUrl.replace(
      'mapbox://styles/',
      'https://api.mapbox.com/styles/v1/'
    )

    url = `${url}${
      defaultConfig.mapboxUrl.endsWith('?optimize=true') ? '&' : '?'
    }access_token=${mapboxAccessToken}`

    try {
      const response = await fetch(url)
      const result = await response.json()
      return {
        baseMapStyle: result
      }
    } catch (err) {
      console.error(err)
    }
  }
}

const setBaseMapThunk = createAsyncThunk(
  'baseMap/setBaseMap',
  async (
    baseMap: string,
    { getState }
  ): Promise<{
    baseMap: string
    baseMapStyle: BaseMapState['baseMapStyle']
    prevBaseMapStyle: BaseMapState['prevBaseMapStyle']
    attribution?: string
  }> => {
    const appState = getState() as RootState
    const state = appState.baseMap
    const { baseMapStyle, attribution } = await getBaseMapStyle(state, baseMap)
    return {
      baseMap,
      baseMapStyle,
      prevBaseMapStyle: state.baseMapStyle,
      attribution
    }
  }
)

export const baseMapSlice = createSlice({
  name: 'baseMap',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setBaseMapOptions: (state, action: PayloadAction<Array<BaseMapOption>>) => {
      state.baseMapOptions = action.payload
    },
    updateMapPosition: (state, action: PayloadAction<MapPosition>) => {
      state.position = action.payload
    },
    setAPIKeys: (
      state,
      action: PayloadAction<{ mapboxAccessToken: string }>
    ) => {
      state.mapboxAccessToken = action.payload.mapboxAccessToken
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(
      setBaseMapThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          baseMap: string
          baseMapStyle: mapboxgl.Style
          prevBaseMapStyle?: mapboxgl.Style
          attribution?: string
        }>
      ) => {
        const { baseMap, baseMapStyle, prevBaseMapStyle, attribution } =
          action.payload
        state.baseMap = baseMap
        state.baseMapStyle = baseMapStyle
        state.prevBaseMapStyle = prevBaseMapStyle
        if (attribution) state.attribution = attribution
      }
    )
    return
  }
})

export const { setAPIKeys, updateMapPosition, setBaseMapOptions } =
  baseMapSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBaseMapStyle = (
  state: RootState
): BaseMapState['baseMapStyle'] => state.baseMap.baseMapStyle

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export { setBaseMapThunk }

export default baseMapSlice.reducer
