import React from 'react'
import { Row } from 'antd'
import turf_area from '@turf/area'
import { IntlProvider, FormattedNumber } from 'react-intl'
import { LocalizedString } from '@maphubs/mhtypes'
import { Feature, MultiLineString, MultiPolygon, Point, Polygon } from 'geojson'

const comparisons = [
  {
    name: {
      en: 'Grand Canyons'
    },
    ha: 313_994.62
  },
  {
    name: {
      en: 'Rhode Islands'
    },
    ha: 313_994.62
  },
  {
    name: {
      en: 'Luxembourgs'
    },
    ha: 258_827.87
  },
  {
    name: {
      en: 'Washington, DCs'
    },
    ha: 17_699.98
  },
  {
    name: {
      en: 'Manhattans'
    },
    ha: 5910.35
  },
  {
    name: {
      en: 'Central Parks'
    },
    ha: 341.1
  },
  {
    name: {
      en: 'Louvre Museums'
    },
    ha: 6.06
  },
  {
    name: {
      en: 'Football (soccer) Pitches'
    },
    ha: 0.71
  },
  {
    name: {
      en: 'Tennis Courts'
    },
    ha: 0.02
  }
]

const findComparision = (areaHa: number) => {
  let result

  if (areaHa > comparisons[0].ha) {
    result = {
      name: comparisons[0].name,
      val: areaHa / comparisons[0].ha
    }
  } else {
    // eslint-disable-next-line unicorn/no-array-for-each
    comparisons.forEach((comparison, i) => {
      if (!result && areaHa > comparison.ha) {
        if (i === 0) {
          result = {
            name: comparisons[0].name,
            val: areaHa / comparisons[0].ha
          }
        } else if (i === comparisons.length - 1) {
          result = {
            name: comparisons[i - 1].name,
            val: areaHa / comparisons[i - 1].ha
          }
        } else {
          result = {
            name: comparison.name,
            val: areaHa / comparison.ha
          }
        }
      }
    })

    if (!result) {
      const last = comparisons.length - 1
      result = {
        name: comparisons[last].name,
        val: areaHa / comparisons[last].ha
      }
    }
  }

  return result
}

const FeatureArea = ({
  geojson,
  t,
  locale
}: {
  geojson?: Feature<Polygon | MultiPolygon | MultiLineString | Point>
  t: (v: string | LocalizedString) => string
  locale: string
}) => {
  const cleanedFeature = {
    type: 'Feature',
    geometry: geojson.geometry,
    properties: geojson.properties
  }

  if (cleanedFeature.geometry.type === 'Point') {
    return (
      <Row
        align='middle'
        justify='center'
        style={{ height: '100%', width: '100%', marginTop: '20px' }}
      >
        NA
      </Row>
    )
  }

  let featureAreaM2, featureAreaKM2, featureAreaHA

  try {
    featureAreaM2 = turf_area(geojson)
  } catch (err) {
    console.error(err.message)
  }

  if (featureAreaM2 && featureAreaM2 > 0) {
    featureAreaKM2 = featureAreaM2 * 0.000_001
    featureAreaHA = featureAreaM2 / 10_000
    let value, units

    if (featureAreaKM2 < 1) {
      value = featureAreaM2
      units = 'm²'
    } else {
      value = featureAreaKM2
      units = 'km²'
    }

    const comparison = findComparision(featureAreaHA)
    return (
      <Row justify='center' align='middle' style={{ height: '160px' }}>
        <Row justify='center'>
          <span>
            <IntlProvider locale={locale}>
              <FormattedNumber value={value} />
            </IntlProvider>
            &nbsp;{units}
          </span>
        </Row>
        <Row justify='center'>
          <span>
            <IntlProvider locale={locale}>
              <FormattedNumber value={featureAreaHA} />
            </IntlProvider>
            &nbsp;ha
          </span>
        </Row>
        <Row justify='center'>
          <span>
            (or &nbsp;
            <IntlProvider locale={locale}>
              <FormattedNumber value={comparison.val} />
            </IntlProvider>
            &nbsp;{t(comparison.name)})
          </span>
        </Row>
      </Row>
    )
  }
  return null
}

export default FeatureArea
