/* eslint-disable unicorn/prefer-export-from */
import locales from './locales'
import localeUtil from './locales/util'
import { LocalizedString } from '@maphubs/mhtypes'

const getLocaleString = (locale: string, text: string): string => {
  return locales.getLocaleString(locale, text)
}

const formModelToLocalizedString = (model, name): LocalizedString => {
  const result = localeUtil.getEmptyLocalizedString()
  for (const key of Object.keys(result)) {
    if (model[`${name}-${key}`]) {
      result[key] = model[`${name}-${key}`]
    }
  }
  return result
}

const getFirstNonEmptyString = (localizedString: LocalizedString): string => {
  let result = ''
  for (const key of Object.keys(localizedString)) {
    const val = localizedString[key]
    if (val) result = val
  }
  return result
}

const getLocaleStringObject = (
  locale: string,
  localizedString: LocalizedString
): string | null => {
  // recover if given an undefined localizedString
  if (!localizedString) {
    return
  }

  // recover if we somehow end up with a plain string
  if (typeof localizedString === 'string') {
    return localizedString
  }

  if (locale) {
    if (localizedString[locale]) {
      // found the requested locale
      return localizedString[locale]
    } else {
      return localizedString.en
        ? localizedString.en
        : getFirstNonEmptyString(localizedString)
    }
  } else {
    return localizedString.en
      ? localizedString.en
      : getFirstNonEmptyString(localizedString)
  }
}

const en = locales.en
const fr = locales.fr
const es = locales.es
const it = locales.it
const id = locales.id
const pt = locales.pt

export {
  getLocaleString,
  getLocaleStringObject,
  formModelToLocalizedString,
  getFirstNonEmptyString,
  localeUtil
}
