const getInteractiveLayers = (_glStyle: mapboxgl.Style) => {
  const interactiveLayers = []

  if (_glStyle?.layers) {
    const layers = _glStyle.layers as Array<
      mapboxgl.Layer & { metadata: Record<string, unknown> }
    >
    for (const layer of layers) {
      if (
        layer.metadata &&
        layer.metadata['maphubs:interactive'] &&
        (layer.id.startsWith('omh') || layer.id.startsWith('osm'))
      ) {
        interactiveLayers.push(layer.id)
      }
    }
  }
  return interactiveLayers
}

export default getInteractiveLayers
