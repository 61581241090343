import React from 'react'
export default function Fox() {
  return (
    <symbol
      id='maphubs-icon-fox'
      viewBox='0 0 100 125'
      preserveAspectRatio='xMidYMid meet'
    >
      <path d='M89.919,68.213l-6.457-11.138l-9.084-15.139L62.023,36.22l-14.45-0.26l-12.429,2.391l-6.026-3.604l-6.035-3.45l3-4.392  l-0.016-2.991h-1.532l-3.947,3.213l-3.318,4.232l-6.982,4.802l-0.094,2.086l-5.117,5.527l1.345,1.083l4.057-0.957l5.212-0.126  l4.265,2.716l5.665,7.98l7.641,4.519l1.829,11.557l-1.022,2.206l-2.26,0.269l-1.237,2.046l1.077,1.021h2.367l4.627-4.896  l-0.269-7.036l0.978-7.09l12.205-3.241l3.444,4.949l8.555,6.027l-0.162,2.099l-3.705,5.058l-1.782,0.108l-0.915,1.937l1.614,0.699  l4.628-0.322l5.219-10.491l-1.184-2.207l-2.906-2.853l2.529-8.34l0.431-3.658l0.646,8.123l3.39,9.257l15.443,9.953l8.125-0.03  L89.919,68.213z M26.732,53.37l-6.089-7.676l-4.574-2.743l-7.424,0.001l7.586-2.906l7.021,2.609l5.327,5.381l3.605,2.332  l0.821,6.205L26.732,53.37z M51.286,53.121l-11.81,2.462l1.001-4.427l11.211,1.456L51.286,53.121z M79.506,67.481l3.903-1.26  l1.563-3.091l3.889,5.854l3.695,4.195l-5.643-0.484L79.506,67.481z' />
    </symbol>
  )
}