import React from 'react'
export default function Eye() {
  return (
    <symbol
      id='maphubs-icon-eye'
      viewBox='0 0 100 70.66625'
      preserveAspectRatio='xMidYMid meet'
    >
      <path
        d='M99.995,28.08c-0.002-0.042-0.006-0.082-0.009-0.123c-0.002-0.021-0.003-0.042-0.005-0.062
c-0.003-0.03-0.008-0.06-0.012-0.089c-0.004-0.035-0.008-0.07-0.014-0.104c-0.001-0.004-0.002-0.009-0.002-0.013
c-0.123-0.773-0.487-1.498-1.045-2.056c-3.19-3.781-6.985-7.146-10.844-10.219C79.753,8.792,70.248,3.252,59.756,1.022
C53.692-0.266,47.59-0.321,41.494,0.769c-5.545,0.992-10.889,3.054-15.891,5.61c-7.829,4-14.979,9.515-21.209,15.698
c-1.153,1.144-2.3,2.312-3.348,3.555c-1.396,1.654-1.396,3.613,0,5.267c3.19,3.781,6.985,7.145,10.844,10.219
c8.312,6.622,17.817,12.161,28.309,14.391c6.064,1.289,12.167,1.344,18.263,0.254c5.544-0.992,10.888-3.054,15.891-5.61
c7.829-4.001,14.979-9.515,21.209-15.698c1.153-1.145,2.3-2.312,3.348-3.555c0.558-0.559,0.922-1.283,1.045-2.057
c0-0.004,0.001-0.009,0.002-0.013c0.006-0.035,0.01-0.069,0.014-0.104c0.004-0.029,0.009-0.059,0.012-0.089
c0.002-0.021,0.003-0.041,0.005-0.062c0.003-0.04,0.007-0.08,0.009-0.122c0.003-0.062,0.005-0.124,0.005-0.187
S99.998,28.142,99.995,28.08z M50,47.507c-10.627,0-19.241-8.614-19.241-19.241C30.759,17.64,39.373,9.025,50,9.025
s19.241,8.614,19.241,19.241C69.241,38.893,60.627,47.507,50,47.507z'
      />
      <circle cx='50' cy='28.266' r='6.369' />
    </symbol>
  )
}