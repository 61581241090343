import React from 'react'
export default function Tractor() {
  return (
    <symbol
      id='maphubs-icon-tractor'
      viewBox='0 0 100 125'
      preserveAspectRatio='xMidYMid meet'
    >
      <g>
        <g>
          <path d='M59.451,24.196c0.992-0.205,2.009-0.155,3.014-0.157c5.159,0.002,10.322,0.002,15.482,0    c1.146,0.008,2.375,0.157,3.288,0.917c1.015,0.782,1.69,2.014,1.644,3.312c-0.002,5.163,0.008,10.326-0.005,15.488    c-0.212,2.15,1.45,3.802,2.122,5.724c-0.844,1.755-1.718,3.492-2.605,5.226c-2.342-0.76-4.84-1.25-7.303-0.928    c-4.633,0.325-9.049,2.768-11.88,6.439c-2.272,2.941-3.604,6.646-3.545,10.38c-9.366,0.036-18.734,0.008-28.1,0.013    c-1.375-3.768-4.539-6.867-8.425-7.953c-2.587-0.81-5.36-0.553-7.926,0.189c-0.024-4.363-0.003-8.729-0.013-13.094    c-0.026-1.568,0.739-3.186,2.145-3.951c1.353-0.816,2.983-0.527,4.481-0.577c4.156-0.044,8.322,0.091,12.473-0.066    c-0.052-5.619-0.01-11.235-0.023-16.854c1.41-0.01,2.821-0.013,4.231,0.003c0.008,5.637-0.008,11.275,0.005,16.913    c3.511,0.013,7.021,0.005,10.533,0.002c2.538-6.138,4.928-12.339,7.436-18.49C56.99,25.475,58.125,24.49,59.451,24.196z     M61.491,28.349c-0.794,0.089-1.416,0.708-1.678,1.435c-1.681,4.187-3.359,8.375-5.026,12.567    c-0.542,1.303,0.493,2.908,1.924,2.865c5.854,0.021,11.709,0.003,17.561,0.008c1.141,0.1,2.299-0.847,2.268-2.024    c0.014-4.279,0.014-8.559,0-12.838c0.047-1.206-1.127-2.14-2.281-2.059C70.004,28.328,65.742,28.237,61.491,28.349z' />
        </g>
        <g>
          <path d='M74.371,55.965c3.309-0.488,6.789,0.152,9.67,1.863c3.75,2.156,6.43,6.062,7.119,10.328    c0.646,3.611-0.191,7.435-2.182,10.502c-2.203,3.422-5.865,5.83-9.866,6.541c-2.947,0.392-6.049,0.178-8.745-1.164    c-4.245-1.924-7.477-5.985-8.316-10.58c-0.709-3.492-0.084-7.239,1.726-10.312C65.971,59.303,69.995,56.594,74.371,55.965z     M75.417,62.232c-3.659,0.434-6.77,3.538-7.226,7.196c-0.512,3.33,1.214,6.838,4.143,8.489c3.445,2.074,8.32,1.201,10.787-1.992    c2.499-2.96,2.489-7.672,0.016-10.646C81.338,62.996,78.286,61.82,75.417,62.232z' />
        </g>
        <g>
          <path d='M18.255,64.34c2.446-0.27,4.981,0.24,7.045,1.607c2.802,1.762,4.625,4.93,4.821,8.226    c0.184,2.911-0.889,5.886-2.929,7.976c-1.99,2.064-4.848,3.346-7.737,3.209c-2.86,0.076-5.664-1.256-7.604-3.324    c-2.344-2.455-3.327-6.086-2.612-9.395C10.084,68.324,13.891,64.832,18.255,64.34z M18.534,69.855    c-2.231,0.451-4.009,2.541-4.024,4.827c-0.123,2.437,1.783,4.78,4.195,5.142c0.921,0.065,1.899,0.107,2.764-0.281    c1.993-0.807,3.383-2.946,3.144-5.109C24.51,71.479,21.398,69.18,18.534,69.855z' />
        </g>
      </g>
    </symbol>
  )
}