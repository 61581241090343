import React from 'react'
import { Feature, MultiLineString, MultiPolygon, Point, Polygon } from 'geojson'
import { Row, Button } from 'antd'
import tokml from '@maphubs/tokml'
import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import type mapboxgl from 'mapbox-gl'
import { LocalizedString } from '@maphubs/mhtypes'

const downloadKML = (
  geojson: Feature<Polygon | MultiPolygon | MultiLineString | Point>
) => {
  const cleanedFeature = {
    type: 'Feature',
    geometry: geojson.geometry,
    properties: geojson.properties
  }
  const kml = tokml(cleanedFeature, {
    name: 'name',
    description: 'description',
    simplestyle: true
  })
  const element = document.createElement('a')
  const file = new Blob([kml], { type: 'text/xml' })
  element.href = URL.createObjectURL(file)
  element.download = 'feature.kml'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

const downloadGeoJSON = (
  geojson: Feature<Polygon | MultiPolygon | MultiLineString | Point>
) => {
  const cleanedFeature = {
    type: 'Feature',
    geometry: geojson.geometry,
    properties: geojson.properties
  }
  const featureCollection = {
    type: 'FeatureCollection',
    features: [cleanedFeature]
  }
  const element = document.createElement('a')
  const file = new Blob([JSON.stringify(featureCollection)], {
    type: 'application/json'
  })
  element.href = URL.createObjectURL(file)
  element.download = 'feature.geojson'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

const FeatureExport = ({
  geojson,
  t
}: {
  geojson?: Feature<Polygon | MultiPolygon | MultiLineString | Point> & {
    layer: mapboxgl.Layer
  }
  t: (v: string | LocalizedString) => string
}) => {
  const disableExport =
    geojson?.layer?.metadata['maphubs:disableExport'] ?? false

  if (disableExport) {
    return (
      <Row
        align='middle'
        justify='center'
        style={{ height: '100%', width: '100%', textAlign: 'center' }}
      >
        <p>{t('Download disabled for this layer')}</p>
      </Row>
    )
  }
  return (
    <Row
      align='middle'
      justify='center'
      style={{ height: '100%', width: '100%' }}
      gutter={[0, 20]}
    >
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        onClick={() => {
          downloadKML(geojson)
        }}
      >
        KML
      </Button>
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        onClick={() => {
          downloadGeoJSON(geojson)
        }}
      >
        GeoJSON
      </Button>
    </Row>
  )
}

export default FeatureExport
