import React, { useState } from 'react'
import { Row, Col, Button, Slider, InputNumber, Tabs, Tooltip } from 'antd'
import AdvancedLayerSettings from './AdvancedLayerSettings'
import mapboxgl from 'mapbox-gl'
import useT from '../hooks/useMapT'
import { Layer } from '@maphubs/mhtypes'
import { CodeEditor } from '@maphubs/ui'

import EyeOutlined from '@ant-design/icons/EyeOutlined'
import CodeOutlined from '@ant-design/icons/CodeOutlined'

type Props = {
  onChange: (value: number) => void
  value: number
  onStyleChange: (style: mapboxgl.Style) => void
  onLegendChange: (legendCode: string) => void
  onColorChange: (style: mapboxgl.Style, legend: string) => void
  style: mapboxgl.Style
  legendCode: string
  layer: Layer
  showAdvanced: boolean
}

const OpacityTab = ({
  value,
  onChange
}: {
  value: number
  onChange: (number) => void
}) => {
  return (
    <Row
      justify='center'
      align='middle'
      style={{
        minWidth: '300px',
        padding: '10px'
      }}
    >
      <Col span={12}>
        <Slider min={1} max={100} onChange={onChange} value={value} />
      </Col>
      <Col span={4}>
        <InputNumber
          min={1}
          max={100}
          style={{
            marginLeft: 16
          }}
          value={value}
          onChange={onChange}
        />
      </Col>
    </Row>
  )
}

const OpacityChooser = ({
  value,
  showAdvanced,
  style,
  legendCode,
  layer,
  onChange,
  onStyleChange,
  onLegendChange,
  onColorChange
}: Props): JSX.Element => {
  const { t } = useT()
  const [showStyleEditor, setShowStyleEditor] = useState(false)
  const [showLegendEditor, setShowLegendEditor] = useState(false)

  return (
    <>
      <style jsx global>
        {`
          .ant-tabs-content {
            height: 100%;
            width: 100%;
          }
          .ant-tabs-tabpane {
            height: 100%;
          }

          .ant-tabs-left-bar .ant-tabs-tab {
            padding: 8px 12px !important;
          }

          .ant-tabs > .ant-tabs-content > .ant-tabs-tabpane-inactive {
            display: none;
          }
          .ant-tabs .ant-tabs-left-content {
            padding-left: 0;
          }
        `}
      </style>
      <Tabs
        defaultActiveKey='opacity'
        tabPosition='left'
        animated={false}
        style={{
          height: '100%',
          width: '100%'
        }}
        items={[
          {
            key: 'opacity',
            label: (
              <Tooltip title={t('Opacity')} placement='right'>
                <span>
                  <EyeOutlined />
                </span>
              </Tooltip>
            ),
            children: <OpacityTab value={value} onChange={onChange} />
          },
          {
            key: 'advanced',
            label: showAdvanced ? (
              <Tooltip title={t('Advanced')} placement='right'>
                <span>
                  <CodeOutlined />
                </span>
              </Tooltip>
            ) : (
              ''
            ),
            children: showAdvanced ? (
              <>
                <Row justify='center' align='middle'>
                  <AdvancedLayerSettings
                    layer={layer}
                    style={style}
                    onChange={onColorChange}
                  />
                </Row>
                <Row
                  justify='center'
                  align='middle'
                  style={{
                    marginBottom: '20px'
                  }}
                >
                  <Col sm={24} md={12}>
                    <Button
                      onClick={() => {
                        setShowStyleEditor(true)
                      }}
                      type='primary'
                    >
                      {t('Style')}
                    </Button>
                  </Col>
                  <Col sm={24} md={12}>
                    <Button
                      onClick={() => {
                        setShowLegendEditor(true)
                      }}
                      type='primary'
                    >
                      {t('Legend')}
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              ''
            )
          }
        ]}
      />
      <CodeEditor
        visible={showStyleEditor}
        id='raster-style-editor'
        mode='json'
        initialCode={JSON.stringify(style, undefined, 2)}
        title={t('Editing Layer Style')}
        onSave={(style: string): void => {
          setShowStyleEditor(false)
          onStyleChange(JSON.parse(style))
        }}
        onCancel={() => {
          setShowStyleEditor(false)
        }}
        t={t}
      />
      <CodeEditor
        visible={showLegendEditor}
        id='raster-legend-editor'
        mode='html'
        initialCode={legendCode}
        title={t('Edit Layer Legend')}
        onSave={(code) => {
          onLegendChange(code)
          setShowLegendEditor(false)
        }}
        onCancel={() => {
          setShowLegendEditor(false)
        }}
        t={t}
      />
    </>
  )
}
export default OpacityChooser
