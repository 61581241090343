import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback
} from 'react'
import { Switch, Button } from 'antd'
import useMapT from '../../hooks/useMapT'
import { useSelector, useDispatch } from '../../redux/hooks'
import { MapContext } from '../../MapContext'
import _area from '@turf/area'
import turf_length from '@turf/length'
import { message } from 'antd'
import MapboxDraw from '@mapbox/mapbox-gl-draw'

import {
  setEnableMeasurementTools,
  setMeasurementMessage,
  setMeasurementFeatures
} from '../../redux/reducers/mapSlice'
import { Feature } from 'geojson'

const MeasurementToolPanel = (): JSX.Element => {
  const drawRef = useRef<MapboxDraw>()
  const { t } = useMapT()
  const dispatch = useDispatch()

  const { mapboxglMap } = useContext(MapContext)
  const enableMeasurementTools = useSelector(
    (state) => state.map.enableMeasurementTools
  )

  const interactiveLayers = useSelector((state) => state.map.interactiveLayers)
  const interactionBufferSize = useSelector(
    (state) => state.map.interactionBufferSize
  )
  const editing = useSelector((state) => state.dataEditor.editing)

  const prevEnabled = useRef<boolean>()
  useEffect(() => {
    if (prevEnabled.current && !enableMeasurementTools) {
      // measurement tools were toggled off
      try {
        if (mapboxglMap) mapboxglMap.removeControl(drawRef.current)
      } catch (err) {
        console.error(err)
      }
      dispatch(setMeasurementMessage(''))
      prevEnabled.current = false
    } else {
      prevEnabled.current = enableMeasurementTools
    }
  }, [enableMeasurementTools, prevEnabled, mapboxglMap, dispatch])

  const toggleMeasurementTools = (enable: boolean) => {
    if (enable && !enableMeasurementTools) {
      // start
      startMeasurementTool()
    } else if (enableMeasurementTools && !enable) {
      // stop, toggle will be detected by useEffect above
      dispatch(setEnableMeasurementTools(false))
    }
  }

  const measureFeatureClick = () => {
    const disableClick = function () {
      mapboxglMap.off('click', onMeasureFeatureClick)
    }

    const onMeasureFeatureClick = function (e) {
      e.originalEvent.stopPropagation()
      const features = mapboxglMap.queryRenderedFeatures(
        [
          [
            e.point.x - interactionBufferSize / 2,
            e.point.y - interactionBufferSize / 2
          ],
          [
            e.point.x + interactionBufferSize / 2,
            e.point.y + interactionBufferSize / 2
          ]
        ],
        {
          layers: interactiveLayers
        }
      )

      if (features && features.length > 0) {
        const feature = features[0]
        dispatch(setEnableMeasurementTools(true))

        updateMeasurement([feature])

        disableClick()
      }
    }

    mapboxglMap.on('click', onMeasureFeatureClick)
  }

  const updateMeasurement = useCallback(
    (features: Feature[]) => {
      if (features.length > 0) {
        const lines = {
          type: 'FeatureCollection',
          features: []
        }
        const polygons = {
          type: 'FeatureCollection',
          features: []
        }
        for (const feature of features) {
          if (feature.geometry.type === 'Polygon') {
            polygons.features.push(feature)
          } else if (feature.geometry.type === 'LineString') {
            lines.features.push(feature)
          }
        }

        if (polygons.features.length > 0) {
          const area = _area(polygons)

          // restrict to area to 2 decimal points
          const areaM2 = Math.round(area * 100) / 100
          const areaKM2 = area * 0.000_001
          const areaHA = areaM2 / 10_000
          let areaMessage = t('Total area: ')

          // areaMessage =
          //   areaM2 < 1000
          //     ? areaMessage + areaM2.toLocaleString() + 'm2 '
          //     : areaMessage + areaKM2.toLocaleString() + 'km2 '

          areaMessage = areaMessage + areaHA.toLocaleString() + ' ha'
          dispatch(setMeasurementMessage(areaMessage))
        } else if (lines.features.length > 0) {
          let distanceKm = 0
          for (const linestring of lines.features) {
            distanceKm += turf_length(linestring, {
              units: 'kilometers'
            })
          }
          const distanceMiles = distanceKm * 0.621_371
          const distanceMessage =
            'Total distance: ' +
            distanceKm.toLocaleString() +
            'km ' +
            distanceMiles.toLocaleString() +
            'mi'
          dispatch(setMeasurementMessage(distanceMessage))
        }
        dispatch(setMeasurementFeatures(features))
      }
    },
    [dispatch, t]
  )

  const startMeasurementTool = useCallback(() => {
    if (editing) {
      message.warning(
        t('Please stop editing before enabling the measurement tool'),
        3
      )
      return
    }

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        line_string: true,
        trash: true
      },
      styles: [
        // ACTIVE (being drawn)
        // line stroke
        {
          id: 'gl-draw-polygon-fill-inactive',
          type: 'fill',
          filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
          ],
          paint: {
            'fill-color': '#ff0000',
            'fill-outline-color': '#ff0000',
            'fill-opacity': 0
          }
        },
        {
          id: 'gl-draw-polygon-fill-active',
          type: 'fill',
          filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
          paint: {
            'fill-color': '#D20C0C',
            'fill-outline-color': '#D20C0C',
            'fill-opacity': 0.1
          }
        },
        {
          id: 'gl-draw-polygon-midpoint',
          type: 'circle',
          filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
          paint: {
            'circle-radius': 3,
            'circle-color': '#D20C0C'
          }
        },
        {
          id: 'gl-draw-polygon-stroke-inactive',
          type: 'line',
          filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': '#D20C0C',
            'line-width': 3
          }
        },
        {
          id: 'gl-draw-polygon-stroke-active',
          type: 'line',
          filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': '#D20C0C',
            'line-dasharray': [0.2, 2],
            'line-width': 2
          }
        },
        {
          id: 'gl-draw-line-inactive',
          type: 'line',
          filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'LineString'],
            ['!=', 'mode', 'static']
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': '#D20C0C',
            'line-width': 2
          }
        },
        {
          id: 'gl-draw-line-active',
          type: 'line',
          filter: [
            'all',
            ['==', '$type', 'LineString'],
            ['==', 'active', 'true']
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': '#D20C0C',
            'line-dasharray': [0.2, 2],
            'line-width': 2
          }
        },
        {
          id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
          ],
          paint: {
            'circle-radius': 5,
            'circle-color': '#fff'
          }
        },
        {
          id: 'gl-draw-polygon-and-line-vertex-inactive',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
          ],
          paint: {
            'circle-radius': 3,
            'circle-color': '#D20C0C'
          }
        },
        {
          id: 'gl-draw-point-point-stroke-inactive',
          type: 'circle',
          filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static']
          ],
          paint: {
            'circle-radius': 5,
            'circle-opacity': 1,
            'circle-color': '#fff'
          }
        },
        {
          id: 'gl-draw-point-inactive',
          type: 'circle',
          filter: [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static']
          ],
          paint: {
            'circle-radius': 3,
            'circle-color': '#D20C0C'
          }
        },
        {
          id: 'gl-draw-point-stroke-active',
          type: 'circle',
          filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', 'active', 'true'],
            ['!=', 'meta', 'midpoint']
          ],
          paint: {
            'circle-radius': 7,
            'circle-color': '#fff'
          }
        },
        {
          id: 'gl-draw-point-active',
          type: 'circle',
          filter: [
            'all',
            ['==', '$type', 'Point'],
            ['!=', 'meta', 'midpoint'],
            ['==', 'active', 'true']
          ],
          paint: {
            'circle-radius': 5,
            'circle-color': '#D20C0C'
          }
        },
        {
          id: 'gl-draw-polygon-fill-static',
          type: 'fill',
          filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
          paint: {
            'fill-color': '#404040',
            'fill-outline-color': '#404040',
            'fill-opacity': 0.1
          }
        },
        {
          id: 'gl-draw-polygon-stroke-static',
          type: 'line',
          filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': '#404040',
            'line-width': 2
          }
        },
        {
          id: 'gl-draw-line-static',
          type: 'line',
          filter: [
            'all',
            ['==', 'mode', 'static'],
            ['==', '$type', 'LineString']
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': '#404040',
            'line-width': 2
          }
        },
        {
          id: 'gl-draw-point-static',
          type: 'circle',
          filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
          paint: {
            'circle-radius': 5,
            'circle-color': '#404040'
          }
        }
      ]
    })
    drawRef.current = draw
    mapboxglMap.addControl(draw, 'top-right')
    mapboxglMap.on('draw.create', (e) => {
      if (drawRef.current) {
        const data = drawRef.current.getAll()
        updateMeasurement(data.features)
      }
    })
    mapboxglMap.on('draw.update', (e) => {
      if (drawRef.current) {
        const data = drawRef.current.getAll()
        updateMeasurement(data.features)
      }
    })
    mapboxglMap.on('draw.delete', () => {
      dispatch(setMeasurementMessage(t('Use the drawing tools below')))
    })
    dispatch(setMeasurementMessage(t('Use the drawing tools below')))
    dispatch(setEnableMeasurementTools(true))
    dispatch(setMeasurementFeatures([]))
  }, [dispatch, editing, mapboxglMap, t, updateMeasurement])

  return (
    <div
      style={{
        textAlign: 'center'
      }}
    >
      <b>{t('Show Measurement Tools')}</b>
      <div>
        <Switch
          checked={enableMeasurementTools}
          onChange={(enableMeasurementTools: boolean) => {
            toggleMeasurementTools(enableMeasurementTools)
          }}
        />
      </div>
      <div
        style={{
          marginTop: '20px'
        }}
      >
        <Button type='primary' onClick={measureFeatureClick}>
          {t('Select a Feature')}
        </Button>
      </div>
    </div>
  )
}
export default MeasurementToolPanel
