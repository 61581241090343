import React from 'react'
import { Row, Typography, Descriptions } from 'antd'
import { IntlProvider, FormattedNumber } from 'react-intl'
import turf_centroid from '@turf/centroid'
import { OpenLocationCode } from 'open-location-code'
import WGS84 from 'wgs84-util'
import { LocalizedString } from '@maphubs/mhtypes'
import { Feature, MultiLineString, MultiPolygon, Point, Polygon } from 'geojson'

const { Title } = Typography

const openLocationCode = new OpenLocationCode()
type Props = {
  geojson?: Feature<Polygon | MultiPolygon | MultiLineString | Point>
  t: (v: string | LocalizedString) => string
  locale: string
}
const FeatureLocation = ({ geojson, t, locale }: Props): JSX.Element => {
  if (!geojson) {
    return (
      <Row
        style={{
          marginBottom: '20px'
        }}
      >
        <Title level={4}>{t('Data Not Available')}</Title>
      </Row>
    )
  }

  const centroid = turf_centroid(geojson)

  const utm = WGS84.LLtoUTM(centroid.geometry)

  const lon = centroid.geometry.coordinates[0]
  const lat = centroid.geometry.coordinates[1]
  const plusCode = openLocationCode.encode(lat, lon, 11)
  return (
    <Row
      style={{
        marginBottom: '0px'
      }}
    >
      <Descriptions size='small' column={1}>
        <Descriptions.Item label={t('Latitude')}>
          <span style={{ fontSize: '12px' }}>
            <IntlProvider locale={locale}>
              <FormattedNumber value={lat} minimumFractionDigits={6} />
            </IntlProvider>
          </span>
        </Descriptions.Item>
        <Descriptions.Item label={t('Longitude')}>
          <span style={{ fontSize: '12px' }}>
            <IntlProvider locale={locale}>
              <FormattedNumber value={lon} minimumFractionDigits={6} />
            </IntlProvider>
          </span>
        </Descriptions.Item>
        <Descriptions.Item label={t('UTM')}>
          <span style={{ fontSize: '12px' }}>
            &nbsp;
            {utm.properties.zoneNumber}
            {utm.properties.zoneLetter}&nbsp;
            <IntlProvider locale={locale}>
              <FormattedNumber value={utm.geometry.coordinates[0]} />
            </IntlProvider>
            m E&nbsp;
            <IntlProvider locale={locale}>
              <FormattedNumber value={utm.geometry.coordinates[1]} />
            </IntlProvider>
            m N
          </span>
        </Descriptions.Item>
        <Descriptions.Item label={t('Plus Code')}>
          <span style={{ fontSize: '12px' }}>{plusCode}</span>
        </Descriptions.Item>
      </Descriptions>
    </Row>
  )
}
export default FeatureLocation
