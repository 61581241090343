import React from 'react'
export default function Hummingbird() {
  return (
    <symbol
      id='maphubs-icon-hummingbird'
      viewBox='0 0 100 81.8825'
      preserveAspectRatio='xMidYMid meet'
    >
      <path d='M92.427,17.571C70.58,27.783,75.895,29.818,59.203,33.86c0.621,1.767,0.639,3.313,1.607,5.894  c14.625-3.563,10.521-7.906,39.627-20.034C99.189,18.031,95.527,16.775,92.427,17.571z' />
      <path d='M92.081,15.546c2.444-1.143,5.196-2.363,8.356-3.68c-2.645-3.579-9.394-3.813-16.598-2.852  c-9.357,1.249-19.486,4.516-22.395,5.096c-5.143,1.028-11.405,3.646-15.24,3.085c-1.35-0.197-2.16-1.087-2.717-2.358  c-1.024-2.34-1.175-5.992-2.144-9.143c-1.496-4.862-6.405-6.312-10.753-5.47c-2.792,0.541-3.927,2.104-8.79,2.571  C13.469,1.678,0.438,1.488,0.438,1.488c4.769,1.963,16.036,3.599,20.336,4.628c4.301,1.029,5.103,7.855,5.891,14.072  c0.91,7.183,2.107,15.252,7.25,22.266c5.143,7.013,11.284,7.763,16.032,7.646c2.608-0.065,8.54,3.894,12.155,6.461  c4.133,2.935,12.25,10.66,13.371,8.603c1.123-2.058-1.775-3.927-10.099-15.147c-4.072-5.49-5.877-8.91-6.808-11.383  c-0.969-2.578-0.986-4.127-1.607-5.893C74.367,29.355,70.234,25.759,92.081,15.546z' />
    </symbol>
  )
}