import React, { useEffect, useRef } from 'react'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import { useDispatch, useSelector } from '../../redux/hooks'
import { changeLocale } from '../../redux/reducers/localeSlice'
import { klona } from 'klona/json'

const supported_langs = new Set([
  'ar',
  'de',
  'en',
  'es',
  'fr',
  'it',
  'ja',
  'ko',
  'pt',
  'ru',
  'vi',
  'zh-Hans',
  'zh-Hant'
])

function useLanguage(
  mapRef: React.RefObject<mapboxgl.Map>,
  locale: string,
  options: { id?: string }
) {
  const dispatch = useDispatch()
  const baseMap = useSelector((state) => state.baseMap.baseMap)
  const glStyle = useSelector((state) => state.map.glStyle)

  const languageControlRef = useRef()

  const addLanguageControl = (map: mapboxgl.Map) => {
    try {
      let mapLocale = locale
      if (!supported_langs.has(locale)) {
        mapLocale = 'mul'
      }
      const languageControl = new MapboxLanguage({ defaultLanguage: mapLocale })
      map.addControl(languageControl)
      languageControlRef.current = languageControl
    } catch (err) {
      console.error('failed to add langauge control')
      console.error(err)
    }
  }

  // handle locale change
  const prevLocale = useRef<string>()
  useEffect(() => {
    if (prevLocale.current !== locale) {
      prevLocale.current = locale
      dispatch(changeLocale(locale))

      let mapLocale = locale
      if (!supported_langs.has(locale)) {
        mapLocale = 'mul'
      }
      console.log(
        `(${options.id}) changing map language to: ${locale} (${mapLocale})`
      )
      // TODO: change inset map locale

      try {
        if (
          (baseMap === 'default' ||
            baseMap === 'light' ||
            baseMap === 'dark' ||
            baseMap === 'streets' ||
            baseMap === 'satellite-streets' ||
            baseMap === 'topo') &&
          languageControlRef.current
        ) {
          // TODO: fix change map language, move to an async thunk

          const glStyleUpdate = languageControlRef.current.setLanguage(
            klona(glStyle),
            mapLocale
          )
          mapRef.current.setStyle(klona(glStyleUpdate))
        } else {
          console.log(
            `(${options.id}) not changing map language, baseMap is: ${baseMap}`
          )
        }
      } catch (err) {
        console.log(err)
      }
    }
  }, [locale, glStyle, baseMap, dispatch, options.id, mapRef])

  return { addLanguageControl }
}

export default useLanguage
