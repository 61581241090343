import React, { useState, useCallback } from 'react'
import {
  Row,
  Col,
  Button,
  Tabs,
  Tooltip,
  ConfigProvider,
  App,
  theme
} from 'antd'

// import icons individually for better dev build performance
import BgColorsOutlined from '@ant-design/icons/BgColorsOutlined'
import FontColorsOutlined from '@ant-design/icons/FontColorsOutlined'
import CodeOutlined from '@ant-design/icons/CodeOutlined'
import PushpinOutlined from '@ant-design/icons/PushpinOutlined'

import LabelSettings from './LabelSettings'
import MarkerSettings, { MarkerOptions } from './MarkerSettings'
import AdvancedLayerSettings from './AdvancedLayerSettings'
import MapStyles from '../Map/Styles'

import { Layer, Labels, LocalizedString } from '@maphubs/mhtypes'
import type mapboxgl from 'mapbox-gl'
import { CodeEditor } from '@maphubs/ui'
import ColorTabs from './ColorTabs'

type Props = {
  onColorChange: (style: mapboxgl.Style, legend: string) => void
  onStyleChange: (style: mapboxgl.Style) => void
  onLabelsChange: (style: mapboxgl.Style, values: Labels) => void
  onMarkersChange: (style: mapboxgl.Style, options: MarkerOptions) => void
  onLegendChange: (legend: string) => void
  initialStyle: mapboxgl.Style
  labels: Labels
  legend: string
  layer: Layer
  themePrimaryColor?: string
  t: (val: string | LocalizedString) => string
}

const getColorFromStyle = (style: mapboxgl.Style): string => {
  let color = 'rgba(255,0,0,0.65)'
  const prevColor = MapStyles.settings.get(style, 'color')
  if (prevColor) {
    color = prevColor
  }
  return color
}

const setColorInStyle = (
  style: mapboxgl.Style,
  color: string
): mapboxgl.Style => {
  style = MapStyles.settings.set(style, 'color', color)
  return style
}

const LayerDesigner = ({
  initialStyle,
  layer,
  labels,
  legend,
  onColorChange,
  onStyleChange,
  onLabelsChange,
  onMarkersChange,
  onLegendChange,
  themePrimaryColor,
  t
}: Props): JSX.Element => {
  const [color, setColor] = useState(getColorFromStyle(initialStyle))
  const [showStyleEditor, setShowStyleEditor] = useState(false)
  const [showLegendEditor, setShowLegendEditor] = useState(false)

  const colorChange = useCallback(
    (color: string): void => {
      const oldStyle = setColorInStyle(initialStyle, color)
      const { style, isOutlineOnly } = MapStyles.color.updateStyleColor(
        oldStyle,
        color
      )

      const legend = isOutlineOnly
        ? MapStyles.legend.outlineLegendWithColor(layer, color)
        : MapStyles.legend.legendWithColor(layer, color)

      setColor(color)
      onColorChange(style, legend)
    },
    [initialStyle, layer, onColorChange]
  )

  const onCodeStyleChange = (style: string): void => {
    const styleUpdate = JSON.parse(style) as mapboxgl.Style
    onStyleChange(styleUpdate)
    setShowStyleEditor(false)
  }

  const onAdvancedSettingsChange = (
    style: mapboxgl.Style,
    legend: string
  ): void => {
    onColorChange(style, legend)
  }

  const iconStyle = {
    fontSize: '28px'
  }

  const items = [
    {
      key: 'color',
      label: (
        <Tooltip title={t('Colors')} placement='right'>
          <span>
            <BgColorsOutlined style={iconStyle} />
          </span>
        </Tooltip>
      ),
      children: <ColorTabs color={color} colorChange={colorChange} t={t} />
    },
    {
      key: 'labels',
      label: (
        <Tooltip title={t('Labels')} placement='right'>
          <span>
            <FontColorsOutlined style={iconStyle} />
          </span>
        </Tooltip>
      ),
      children: (
        <LabelSettings
          onChange={onLabelsChange}
          initialStyle={initialStyle}
          labels={labels}
          layer={layer}
          t={t}
        />
      )
    }
  ]

  if (layer.data_type === 'point') {
    items.push({
      key: 'markers',
      label: (
        <Tooltip title={t('Markers')} placement='right'>
          <span>
            <PushpinOutlined style={iconStyle} />
          </span>
        </Tooltip>
      ),
      children: (
        <MarkerSettings
          onChange={onMarkersChange}
          style={initialStyle}
          color={color}
          layer={layer}
          t={t}
        />
      )
    })
  }

  items.push({
    key: 'advanced',
    label: (
      <Tooltip title={t('Advanced')} placement='right'>
        <span>
          <CodeOutlined style={iconStyle} />
        </span>
      </Tooltip>
    ),
    children: (
      <>
        <AdvancedLayerSettings
          layer={layer}
          style={initialStyle}
          onChange={onAdvancedSettingsChange}
          t={t}
        />
        <Row
          justify='center'
          align='middle'
          style={{
            marginBottom: '20px',
            textAlign: 'center'
          }}
        >
          <Col sm={24} md={12}>
            <Button
              type='primary'
              onClick={() => {
                setShowStyleEditor(true)
              }}
            >
              {t('Style')}
            </Button>
          </Col>
          <Col sm={24} md={12}>
            <Button
              type='primary'
              onClick={() => {
                setShowLegendEditor(true)
              }}
            >
              {t('Legend')}
            </Button>
          </Col>
        </Row>
      </>
    )
  })

  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.compactAlgorithm],
        token: {
          colorPrimary: themePrimaryColor || '#323333'
        }
      }}
    >
      <App
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            border: '1px solid #ddd'
          }}
        >
          <style jsx global>
            {`
              .ant-tabs-content {
                height: 100%;
                width: 100%;
              }
              .ant-tabs-tabpane {
                height: 100%;
              }

              .ant-tabs-left .ant-tabs-tab {
                padding-left: 10px !important;
              }

              .ant-tabs-left .ant-tabs-nav-wrap {
                padding: 0 !important;
              }

              .ant-tabs > .ant-tabs-content > .ant-tabs-tabpane-inactive {
                display: none;
              }
            `}
          </style>
          <Tabs
            defaultActiveKey='color'
            tabPosition='left'
            animated={false}
            style={{
              height: '100%'
            }}
            tabBarStyle={{ width: '50px', paddingTop: '10px' }}
            tabBarGutter={24}
            items={items}
          />

          <CodeEditor
            visible={showLegendEditor}
            id='layer-legend-editor'
            mode='html'
            initialCode={legend}
            title={t('Edit Layer Legend')}
            onSave={(legend: string) => {
              onLegendChange(legend)
              setShowLegendEditor(false)
            }}
            onCancel={() => {
              setShowLegendEditor(false)
            }}
            t={t}
          />
          <CodeEditor
            visible={showStyleEditor}
            id='layer-style-editor'
            mode='json'
            initialCode={JSON.stringify(initialStyle, undefined, 2)}
            title={t('Editing Layer Style')}
            onSave={onCodeStyleChange}
            onCancel={() => {
              setShowStyleEditor(false)
            }}
            t={t}
          />
        </div>
      </App>
    </ConfigProvider>
  )
}
export default LayerDesigner
