import * as React from 'react'
import { notification, ConfigProvider } from 'antd'
import SearchBar from './SearchBar/SearchBar'

type Props = {
  label: string
  suggestionUrl?: string
  onSearch: (...args: Array<any>) => any
  onReset: (...args: Array<any>) => any
  locale?: string
  themePrimaryColor?: string
}
const SearchBox = ({
  suggestionUrl,
  label,
  onSearch,
  onReset,
  locale,
  themePrimaryColor
}: Props): JSX.Element => {
  const onChange = async (
    input: string,
    resolve: (...args: Array<any>) => any
  ) => {
    if (suggestionUrl) {
      try {
        const res = await fetch(
          `${suggestionUrl}?locale=${locale || 'en'}&q=${input}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        ).then((res) => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw new Error(res.statusText)
          }
        })

        if (res.suggestions) {
          resolve(res.suggestions)
        }
      } catch (err) {
        console.error(err)
        notification.error({
          message: 'Error',
          description: err.message || err.toString() || err,
          duration: 0
        })
      }
    }
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: themePrimaryColor || '#323333'
        }
      }}
    >
      <SearchBar
        placeholder={label}
        onChange={onChange}
        onSubmit={(input: string) => {
          if (!input) return
          onSearch(input)
        }}
        onReset={onReset}
      />
    </ConfigProvider>
  )
}
SearchBox.defaultProps = {
  label: 'Search',
  style: {},
  id: 'search',

  onSearch() {
    return
  },

  onError() {
    return
  },

  onReset() {
    return
  }
}
export default SearchBox
