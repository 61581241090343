import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { Layer } from '@maphubs/mhtypes'
import type { RootState } from '../store'
import { Feature } from 'geojson'
import _assignIn from 'lodash.assignin'
import _forEachRight from 'lodash.foreachright'
import { klona } from 'klona/json'
export interface Edit {
  status: 'create' | 'original' | 'modify' | 'delete'
  geojson: Feature & { id: string }
}

export interface DataEditorState {
  editing?: boolean
  editingLayer?: Layer
  originals: Edit[]
  // store the orginal GeoJSON to support undo
  edits: Edit[]
  redo: Edit[]
  // if we undo edits, add them here so we can redo them
  selectedEditFeature?: Edit // selected feature
  clickedFeature?: Feature // feature passed from mapbox-gl click handler
}

const initialState: DataEditorState = {
  editing: false,
  originals: [],
  // store the orginal GeoJSON to support undo
  edits: [],
  redo: [] // if we undo edits, add them here so we can redo them
}

const getLastEditForID = (
  state: DataEditorState,
  id: string,
  edits: Edit[]
): Edit | null => {
  const matchingEdits = []

  _forEachRight(edits, (edit) => {
    if (edit.geojson.id === id) {
      matchingEdits.push(edit)
    }
  })

  if (matchingEdits.length > 0) {
    return klona(matchingEdits[0])
  } else {
    let original
    for (const orig of state.originals) {
      if (orig.geojson.id === id) {
        original = orig
      }
    }

    if (original) {
      return klona(original)
    }

    return null
  }
}

const selectFeatureThunk = createAsyncThunk(
  'dataEditor/selectFeature',
  async (
    args: { mhid: string; feature?: Feature },

    { getState }
  ): Promise<{
    originals?: DataEditorState['originals']
    selectedEditFeature: DataEditorState['selectedEditFeature']
  }> => {
    const appState = getState() as RootState
    const state = appState.dataEditor

    // check if this feature is in the created or modified lists
    const selected = getLastEditForID(
      state as DataEditorState,
      args.mhid,
      state.edits
    )

    if (selected) {
      return {
        selectedEditFeature: selected
      }
    } else {
      const id = args.mhid.split(':')[1]
      const layer_id: number =
        state.editingLayer && state.editingLayer.layer_id
          ? state.editingLayer.layer_id
          : 0

      // otherwise get the geojson from the server

      let feature
      if (args.feature) {
        feature = args.feature
      } else {
        // otherwise get it from the API
        const featureCollection = await fetch(
          `/api/feature/json/${layer_id.toString()}/${id}/data.geojson`
        ).then((res) => {
          if (res.status !== 200) throw new Error(res.statusText)
          return res.json()
        })

        feature = featureCollection.features[0]
      }
      const selected = {
        status: 'original' as Edit['status'],
        geojson: feature
      }
      selected.geojson.id = args.mhid
      const original = klona(selected) // needs to be a clone

      return {
        originals: [...state.originals, original],
        selectedEditFeature: selected
      }
    }
  }
)

const getUniqueFeatureIds = (state: DataEditorState): string[] => {
  const uniqueIds = []
  for (const edit of state.edits) {
    const id = edit.geojson.id

    if (id && !uniqueIds.includes(id)) {
      uniqueIds.push(id)
    }
  }
  return uniqueIds
}

const getAllEditsForFeatureId = (
  state: DataEditorState,
  id: string
): Edit[] => {
  const featureEdits = []
  for (const edit of state.edits) {
    if (edit.geojson.id === id) {
      featureEdits.push(edit)
    }
  }
  return featureEdits
}

/**
 * Save all edits to the server and reset current edits
 */
const saveEdits = createAsyncThunk(
  'dataEditor/saveEdits',
  async (_: unknown, { getState }): Promise<any> => {
    const appState = getState() as RootState
    const state = appState.dataEditor
    console.log('saving edits')
    //console.log(this.state.edits)
    const { editingLayer } = state

    const featureIds = getUniqueFeatureIds(state)
    const editsToSave = []
    for (const id of featureIds) {
      const featureEdits = getAllEditsForFeatureId(state, id)

      const lastFeatureEdit = klona(featureEdits[featureEdits.length - 1])

      if (featureEdits.length > 1 && featureEdits[0].status === 'create') {
        // first edit is a create, so mark edit as create
        lastFeatureEdit.status = 'create'
      }

      editsToSave.push(lastFeatureEdit)
    }
    const layer_id: number = editingLayer?.layer_id || 0

    if (editsToSave.length > 0) {
      // send edits to server
      try {
        const result = await fetch('/api/edits/save', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            layer_id,
            edits: editsToSave
          })
        })
        const body = await result.json()
        if (body.success) {
          return true
        } else {
          throw new Error(body.error)
        }
      } catch (err) {
        console.error(err)
        throw new Error(err)
      }
    } else {
      throw new Error('No pending edits found')
    }
  }
)

export const dataEditorSlice = createSlice({
  name: 'dataEditor',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    reset: (state) => {
      state.editing = false
      state.originals = []
      state.edits = []
      state.redo = []
    },

    startEditing: (
      state,
      action: PayloadAction<{ layer: DataEditorState['editingLayer'] }>
    ) => {
      state.editing = true
      state.editingLayer = action.payload.layer
    },

    stopEditing: (state, action: PayloadAction<{ layer: Layer }>) => {
      if (state.edits.length > 0) {
        console.log('stopping with unsaved edits, edits have been deleted')
      }

      ;(state.editing = false),
        (state.originals = []),
        (state.edits = []),
        (state.redo = []),
        (state.editingLayer = undefined)
    },

    /**
     * receive updates from the drawing tool
     */
    updateFeatures: (state, action: PayloadAction<Feature[]>) => {
      const { edits, selectedEditFeature } = state

      const editsClone = klona(edits) as Edit[]
      let selectedEditFeatureUpdate = selectedEditFeature
      for (const feature of action.payload) {
        console.log('Updating feature: ' + feature.id)
        const edit = {
          status: 'modify' as Edit['status'],
          geojson: klona(feature)
        } as Edit

        if (
          selectedEditFeature &&
          feature.id === selectedEditFeature.geojson.id
        ) {
          // if popping an edit to the selected feature, updated it
          selectedEditFeatureUpdate = edit
        }

        // edit history gets a different clone from the selection state
        const editCopy = klona(edit)
        editsClone.push(editCopy)
      }

      ;(state.edits = editsClone),
        (state.selectedEditFeature = selectedEditFeatureUpdate),
        (state.redo = []) // redo resets if user makes an edit
    },

    resetEdits: (state) => {
      state.edits = []
      state.redo = []
    },

    undoEdit: (
      state,
      action: PayloadAction<{
        onFeatureUpdate: (type: string, edit: Edit) => void
      }>
    ) => {
      const edits = klona(state.edits)
      const redo = klona(state.redo)
      let selectedEditFeature = klona(state.selectedEditFeature)

      if (edits.length > 0) {
        const lastEdit = edits.pop()
        const lastEditCopy = klona(lastEdit)
        redo.push(lastEditCopy)
        const currEdit = getLastEditForID(
          state as DataEditorState,
          lastEdit.geojson.id,
          edits
        )

        if (
          currEdit &&
          selectedEditFeature &&
          lastEdit.geojson.id === selectedEditFeature.geojson.id
        ) {
          // if popping an edit to the selected feature, updated it
          selectedEditFeature = currEdit
        }

        if (lastEdit.status === 'create') {
          // tell mapboxGL to delete the feature
          action.payload.onFeatureUpdate('delete', lastEdit)
        } else {
          // tell mapboxGL to update
          action.payload.onFeatureUpdate('update', currEdit)
        }
        state.edits = edits
        ;(state.redo = redo), (state.selectedEditFeature = selectedEditFeature)
      }
    },

    redoEdit: (
      state,
      action: PayloadAction<{
        onFeatureUpdate: (type: string, edit: Edit) => void
      }>
    ) => {
      const edits = klona(state.edits)
      const redo = klona(state.redo)
      let selectedEditFeature = klona(state.selectedEditFeature)

      if (redo.length > 0) {
        const prevEdit = redo.pop()
        const prevEditCopy = klona(prevEdit)
        const prevEditCopy2 = klona(prevEdit)
        edits.push(prevEditCopy)

        if (
          selectedEditFeature &&
          prevEdit.geojson.id === selectedEditFeature.geojson.id
        ) {
          // if popping an edit to the selected feature, updated it
          selectedEditFeature = prevEditCopy2
        }

        // tell mapboxGL to update
        action.payload.onFeatureUpdate('update', prevEdit)

        state.edits = edits
        state.redo = redo
        state.selectedEditFeature = selectedEditFeature
      }
    },

    updateSelectedFeatureTags: (
      state,
      action: PayloadAction<{
        data: Record<string, unknown>
      }>
    ) => {
      const edits = klona(state.edits)

      if (state.selectedEditFeature) {
        console.log('updatings tags for selected feature')
        console.log(state.selectedEditFeature)
        // console.log(data)
        const selectedEditFeature = klona(state.selectedEditFeature)

        // check if selected feature has been edited yet
        const editRecord = {
          status: 'modify',
          geojson: klona(selectedEditFeature.geojson)
        } as Edit

        // update the edit record
        _assignIn(editRecord.geojson.properties, action.payload.data)

        const editRecordCopy = klona(editRecord)
        edits.push(editRecordCopy)
        console.log('adding new edit record')
        console.log(editRecordCopy)

        state.edits = edits
        state.redo = []
        state.selectedEditFeature = editRecord
      } else {
        console.error('no feature selected')
      }
    },

    /**
     * Called when mapbox-gl-draw is used to create new feature
     *
     */
    createFeature: (state, action: PayloadAction<Feature>) => {
      const edits = klona(state.edits)
      const created = {
        status: 'create' as Edit['status'],
        geojson: klona(action.payload) as Feature & { id: string }
      }
      edits.push(created)
      ;(state.edits = edits), (state.selectedEditFeature = created)
    },

    deleteFeature: (state, action: PayloadAction<Feature>) => {
      const edits = klona(state.edits)
      const edit = {
        status: 'delete',
        geojson: klona(action.payload)
      } as Edit
      edits.push(edit)
      state.redo = []
      state.edits = edits
    },
    setClickedFeature: (
      state,
      action: PayloadAction<{
        feature: Feature
      }>
    ) => {
      state.clickedFeature = action.payload.feature
    },
    clearSelectedFeature: (state) => {
      state.selectedEditFeature = undefined
      state.clickedFeature = undefined
    }
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(
      selectFeatureThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          originals?: DataEditorState['originals']
          selectedEditFeature: DataEditorState['selectedEditFeature']
        }>
      ) => {
        const { originals, selectedEditFeature } = action.payload
        if (originals) {
          state.originals = originals
        }
        state.selectedEditFeature = selectedEditFeature
      }
    )
    builder.addCase(saveEdits.fulfilled, (state) => {
      ;(state.originals = []),
        (state.edits = []),
        (state.redo = []),
        (state.selectedEditFeature = undefined)
    })
  }
})

export const {
  reset,
  startEditing,
  stopEditing,
  undoEdit,
  redoEdit,
  resetEdits,
  updateSelectedFeatureTags,
  createFeature,
  deleteFeature,
  updateFeatures,
  setClickedFeature,
  clearSelectedFeature
} = dataEditorSlice.actions

// export the thunks
export { selectFeatureThunk, saveEdits }

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectLocale = (state: AppState): string => state.locale.value

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default dataEditorSlice.reducer
