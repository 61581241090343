import React from 'react'
import { Row, Typography } from 'antd'
import { QRCodeSVG } from 'qrcode.react'
import turf_centroid from '@turf/centroid'
import { LocalizedString } from '@maphubs/mhtypes'
import { Feature, MultiLineString, MultiPolygon, Point, Polygon } from 'geojson'

const { Title } = Typography

type Props = {
  geojson?: Feature<Polygon | MultiPolygon | MultiLineString | Point>
  t: (v: string | LocalizedString) => string
  locale: string
}
const FeatureQRCode = ({ geojson, t }: Props): JSX.Element => {
  if (!geojson) {
    return (
      <Row
        style={{
          marginTop: '20px'
        }}
      >
        <Title level={4}>{t('Data Not Available')}</Title>
      </Row>
    )
  }

  const centroid = turf_centroid(geojson)

  const lon = centroid.geometry.coordinates[0]
  const lat = centroid.geometry.coordinates[1]

  return (
    <Row
      align='middle'
      justify='center'
      style={{ height: '100%', width: '100%' }}
    >
      <QRCodeSVG
        bgColor='#FFFFFF'
        fgColor='#000000'
        level='L'
        style={{
          width: 128
        }}
        value={`geo:${lat},${lon}`}
      />
    </Row>
  )
}
export default FeatureQRCode
