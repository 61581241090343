import React, { useState } from 'react'
import { Row, Form, Select, Switch } from 'antd'
import _assignIn from 'lodash.assignin'
import MapStyles from '../Map/Styles'
import { Layer, LocalizedString } from '@maphubs/mhtypes'
import { klona } from 'klona/json'
import mapboxgl from 'mapbox-gl'
type Props = {
  onChange: (style: mapboxgl.Style, options: MarkerOptions) => void
  layer: Layer
  style: mapboxgl.Style
  color: string
  t: (val: string | LocalizedString) => string
}

export type MarkerOptions = {
  shape: 'MAP_PIN' | 'SQUARE_PIN' | 'SQUARE_ROUNDED' | 'SQUARE' | 'CIRCLE'
  size: string
  width: number
  height: number
  shapeFill: string
  shapeFillOpacity: number
  shapeStroke: string
  shapeStrokeWidth: number
  icon: string
  iconFill: string
  iconFillOpacity: number
  iconStroke: string
  iconStrokeWidth: number
  inverted: boolean
  enabled: boolean
}

type LayerWithMeta = mapboxgl.Layer & { metadata: Record<string, unknown> }

const MarkerSettings = ({
  style,
  color,
  onChange,
  layer,
  t
}: Props): JSX.Element => {
  const initialOptions: MarkerOptions = {
    shape: 'MAP_PIN',
    size: '32',
    width: 32,
    height: 32,
    shapeFill: 'red',
    shapeFillOpacity: 1,
    shapeStroke: '#323333',
    shapeStrokeWidth: 1,
    icon: 'none',
    iconFill: 'white',
    iconFillOpacity: 1,
    iconStroke: '#323333',
    iconStrokeWidth: 0,
    inverted: false,
    enabled: false
  }

  // get state from style
  if (style.layers && Array.isArray(style.layers) && style.layers.length > 0) {
    for (const glLayer of style.layers as LayerWithMeta[]) {
      if (
        glLayer.id.startsWith('omh-data-point') &&
        glLayer.metadata &&
        glLayer.metadata['maphubs:markers']
      ) {
        _assignIn(initialOptions, glLayer.metadata['maphubs:markers'])

        if (glLayer.metadata['maphubs:markers'].invert) {
          initialOptions.iconStroke = color
          initialOptions.iconFill = color
        } else {
          initialOptions.shapeFill = color
        }
      }
    }
  }
  const [options, setOptions] = useState<MarkerOptions>(initialOptions)

  const onFormChange = (model: {
    size: string
    width: string
    height: string
    inverted: boolean
    enabled: boolean
  }): void => {
    let width: number
    let height: number
    // eslint-disable-next-line unicorn/explicit-length-check
    if (model.size) {
      width = Number.parseInt(model.size, 10)
      height = Number.parseInt(model.size, 10)
    }

    const optionsClone = Object.assign(klona(options), model)
    optionsClone.width = width
    optionsClone.height = height

    // invert colors
    if (model.inverted) {
      optionsClone.shapeStroke = color
      optionsClone.iconFill = color
      optionsClone.shapeFill = 'white'
      optionsClone.shapeFillOpacity = 0.75
      optionsClone.shapeStrokeWidth = 2
    } else {
      optionsClone.shapeFill = color
      optionsClone.iconFill = 'white'
      optionsClone.shapeStroke = '#323333'
      optionsClone.shapeFillOpacity = 1
      optionsClone.shapeStrokeWidth = 1
    }

    const styleUpdate = optionsClone.enabled
      ? MapStyles.markers.enableMarkers(style, options, layer)
      : MapStyles.markers.disableMarkers(style)

    setOptions(optionsClone)
    onChange(styleUpdate, options)
  }

  const shapeOptions = [
    {
      value: 'MAP_PIN',
      label: t('Map Pin')
    },
    {
      value: 'SQUARE_PIN',
      label: t('Square Pin')
    },
    {
      value: 'SQUARE_ROUNDED',
      label: t('Rounded Square')
    },
    {
      value: 'SQUARE',
      label: t('Square')
    },
    {
      value: 'CIRCLE',
      label: t('Circle')
    }
  ]
  const sizeOptions = [
    {
      value: '16',
      label: '16'
    },
    {
      value: '24',
      label: '24'
    },
    {
      value: '32',
      label: '32'
    },
    {
      value: '40',
      label: '40'
    },
    {
      value: '48',
      label: '48'
    },
    {
      value: '56',
      label: '56'
    },
    {
      value: '64',
      label: '64'
    },
    {
      value: '96',
      label: '96'
    }
  ]
  const iconOptions = [
    {
      value: 'none',
      label: t('None')
    },
    {
      value: 'maphubs-icon-boat',
      label: t('Boat')
    },
    {
      value: 'maphubs-icon-campfire',
      label: t('Campfire')
    },
    {
      value: 'maphubs-icon-cargo-ship',
      label: t('Cargo Ship')
    },
    {
      value: 'maphubs-icon-chainsaw',
      label: t('Chainsaw')
    },
    {
      value: 'maphubs-icon-chipmunk',
      label: t('Chipmunk')
    },
    {
      value: 'maphubs-icon-clearcutting',
      label: t('Clearcutting')
    },
    {
      value: 'maphubs-icon-clipboard',
      label: t('Clipboard')
    },
    {
      value: 'maphubs-icon-clinic',
      label: t('Clinic')
    },
    {
      value: 'maphubs-icon-dam',
      label: t('Dam')
    },
    {
      value: 'maphubs-icon-dolphin',
      label: t('Dolphin')
    },
    {
      value: 'maphubs-icon-elephant',
      label: t('Elephant')
    },
    {
      value: 'maphubs-icon-eye',
      label: t('Eye')
    },
    {
      value: 'maphubs-icon-factory',
      label: t('Factory')
    },
    {
      value: 'maphubs-icon-farmer',
      label: t('Farmer')
    },
    {
      value: 'maphubs-icon-farmer-family',
      label: t('Farmer Family')
    },
    {
      value: 'maphubs-icon-farmer-wife',
      label: t('Farmer Wife')
    },
    {
      value: 'maphubs-icon-fox',
      label: t('Fox')
    },
    {
      value: 'maphubs-icon-gorilla',
      label: t('Gorilla')
    },
    {
      value: 'maphubs-icon-hand-one',
      label: t('Hand')
    },
    {
      value: 'maphubs-icon-hummingbird',
      label: t('HummingBird')
    },
    {
      value: 'maphubs-icon-log-pile',
      label: t('Log Pile')
    },
    {
      value: 'maphubs-icon-magnifier',
      label: t('Magnifier')
    },
    {
      value: 'maphubs-icon-mining',
      label: t('Mining')
    },
    {
      value: 'maphubs-icon-money',
      label: t('Money')
    },
    {
      value: 'maphubs-icon-oil',
      label: t('Oil')
    },
    {
      value: 'maphubs-icon-palm-oil',
      label: t('Palm Oil')
    },
    {
      value: 'maphubs-icon-play',
      label: t('Play')
    },
    {
      value: 'maphubs-icon-sawblade',
      label: t('Sawblade')
    },
    {
      value: 'maphubs-icon-star',
      label: t('Star')
    },
    {
      value: 'maphubs-icon-tractor',
      label: t('Tractor')
    },
    {
      value: 'maphubs-icon-truck',
      label: t('Truck')
    },
    {
      value: 'maphubs-icon-tug-boat',
      label: t('Tug Boat')
    },
    {
      value: 'maphubs-icon-turtle',
      label: t('Turtle')
    },
    {
      value: 'maphubs-icon-turtle2',
      label: t('Turtle 2')
    },
    {
      value: 'maphubs-icon-video',
      label: t('Video')
    },
    {
      value: 'maphubs-icon-village',
      label: t('Village')
    },
    {
      value: 'maphubs-icon-whale',
      label: t('Whale')
    },
    {
      value: 'maphubs-icon-wifi',
      label: t('WiFi')
    },
    {
      value: 'maphubs-icon-wolf',
      label: t('Wolf')
    }
  ]
  return (
    <Row
      style={{
        marginBottom: '20px'
      }}
    >
      <Form
        onValuesChange={onFormChange}
        initialValues={options}
        layout='vertical'
      >
        <Form.Item
          name='enabled'
          label={t('Enable Markers')}
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name='shape'
          rules={[{ required: true }]}
          label={t('Marker Shape')}
          tooltip={t('Shape of the map marker')}
        >
          <Select options={shapeOptions} style={{ width: '200px' }} />
        </Form.Item>
        <Form.Item
          name='size'
          rules={[{ required: true }]}
          label={t('Marker Size')}
          tooltip={t('Size of the map marker')}
        >
          <Select options={sizeOptions} style={{ width: '200px' }} />
        </Form.Item>
        <Form.Item
          name='icon'
          rules={[{ required: true }]}
          label={t('Marker Icon')}
          tooltip={t('Marker icon overlay')}
        >
          <Select options={iconOptions} style={{ width: '200px' }} />
        </Form.Item>
        <Form.Item
          name='inverted'
          label={t('Invert Colors')}
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      </Form>
    </Row>
  )
}
export default MarkerSettings
