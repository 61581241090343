import React from 'react'
export default function Village() {
  return (
    <symbol
      id='maphubs-icon-village'
      viewBox='0 0 100 125'
      preserveAspectRatio='xMidYMid meet'
    >
      <path d='M72.579,48.714L51.546,27.682c-0.825-0.825-2.286-0.825-3.111,0L27.247,48.871c-0.629,0.629-0.817,1.575-0.477,2.397  c0.34,0.822,1.143,1.358,2.033,1.358h3.637v28.678c0,1.214,0.985,2.2,2.2,2.2h30.703c1.216,0,2.2-0.986,2.2-2.2V52.626h3.637  c0.008,0.001,0.014,0,0.018,0c1.215,0,2.2-0.985,2.2-2.2C73.397,49.734,73.078,49.117,72.579,48.714z' />
      <path d='M97.055,43.588L75.867,22.399c-0.826-0.825-2.287-0.825-3.111,0L62,33.155l12.967,12.967  c1.243,1.088,1.951,2.644,1.951,4.304c0,3.11-2.495,5.648-5.588,5.719l-0.029,0.002h-0.112h-0.116h-0.009v22.075h18.599  c1.215,0,2.199-0.985,2.199-2.2V47.344h3.638c0.89,0,1.692-0.535,2.032-1.357C97.872,45.165,97.684,44.217,97.055,43.588z' />
      <path d='M2.945,43.588l21.188-21.189c0.825-0.825,2.286-0.825,3.111,0L38,33.155L25.033,46.122  c-1.243,1.088-1.951,2.644-1.951,4.304c0,3.11,2.495,5.648,5.588,5.719l0.029,0.002h0.112h0.116h0.009v22.075H10.338  c-1.215,0-2.2-0.985-2.2-2.2V47.344H4.501c-0.89,0-1.692-0.535-2.033-1.357C2.128,45.165,2.316,44.217,2.945,43.588z' />
    </symbol>
  )
}