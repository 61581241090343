import type { MapboxGeoJSONFeature } from 'mapbox-gl'
import MapStyles from '../Map/Styles'

const addPresetsToFeature = (
  feature: MapboxGeoJSONFeature,
  glStyle: mapboxgl.Style
): MapboxGeoJSONFeature => {
  if (feature.layer && feature.layer.source) {
    let presets = MapStyles.settings.getSourceSetting(
      glStyle,
      feature.layer.source as string,
      'presets'
    )

    if (!presets) {
      console.log(`presets not found in source ${feature.layer.source}`)
      const source = glStyle.sources[feature.layer.source as string]
      let data

      if (source) {
        const geoJSONSource = source as mapboxgl.GeoJSONSourceOptions
        data = geoJSONSource.data
      }

      if (data) {
        if (data.metadata) {
          presets = data.metadata['maphubs:presets']

          if (presets) {
            console.log(`presets FOUND! for source ${feature.layer.source}`)
          } else {
            console.log(
              `presets not found in data.metadata for source ${feature.layer.source}`
            )
          }
        } else {
          console.log(
            `data.metadata not found in source ${feature.layer.source}`
          )
        }
      } else {
        console.log(`data not found in source ${feature.layer.source}`)
      }
    }

    if (!feature.properties.maphubs_metadata) {
      feature.properties.maphubs_metadata = {}
    }

    if (typeof feature.properties.maphubs_metadata === 'string') {
      feature.properties.maphubs_metadata = JSON.parse(
        feature.properties.maphubs_metadata
      )
    }

    if (!feature.properties.maphubs_metadata.presets) {
      feature.properties.maphubs_metadata.presets = presets
    }
  }
  return feature
}

export default addPresetsToFeature
