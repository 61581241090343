const fetcher = (
  val: string | [string, string]
): Promise<Record<string, unknown>> => {
  let query
  let id
  if (typeof val === 'string') {
    query = val
  } else {
    query = val[0]
    id = val[1]
  }

  if (query.includes('{id}') && !id) {
    return Promise.resolve({})
  }

  return fetch('/api/graphql', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      query: query.replaceAll('{id}', id)
    })
  })
    .then(async (res) => {
      const body = await res.json()

      if (res.status === 400) {
        const error = body.errors
          ? new Error(body.errors[0].message)
          : new Error('API Request Failed')
        throw error
      }

      if (res.status === 401) {
        const error = new Error('Unauthorized')
        throw error
      }

      return body
    })
    .then((json) => json.data)
}

export default fetcher
