import React from 'react'
export default function Whale() {
  return (
    <symbol
      id='maphubs-icon-whale'
      viewBox='0 0 100 125'
      preserveAspectRatio='xMidYMid meet'
    >
      <path d='M89.445,16.084c-0.977,0.161-8.383,0.357-11.742,0.357h-5.841h-6.28c-4.234,0.05-8.605,0.849-9.93,1.395  c0,0-1.66,0.605-2.878,1.804c-0.73,0.719-2.212,3.278-2.774,4.454c-0.558-1.171-2.045-3.734-2.775-4.454  c-1.217-1.199-2.878-1.804-2.878-1.804c-1.324-0.546-5.695-1.345-9.93-1.395h-6.28h-5.841c-3.359,0-10.765-0.196-11.742-0.357  c-5.553-0.912-5.565-1.352-5.553-0.912s4.044,11.565,15.981,14.602c3.982,1.013,10.734,3.032,17.172,4.485  c5.084,1.146,5.505,4.733,5.633,6.621C44.402,49.971,34.403,82.617,31.46,95h37.079c-2.942-12.383-12.941-45.029-12.326-54.12  c0.127-1.888,0.549-5.476,5.633-6.621c6.438-1.453,13.189-3.472,17.172-4.485c11.938-3.037,15.969-14.163,15.981-14.602  S94.999,15.172,89.445,16.084z' />
    </symbol>
  )
}